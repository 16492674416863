import React, {
    FC,
    FocusEvent,
    KeyboardEvent,
    useEffect,
    useRef,
    useState
} from 'react';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';
import TextInput from 'components/common/Inputs/TextInput';

import { autocompleteFunctions } from './autocompleteFunctions';
import AutocompleteSuggestions from './AutocompleteSuggestions';
import AutocompleteTextModeWrapper from './AutocompleteTextModeWrapper';

const Container = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;

    &.no-typing input {
        caret-color: transparent;
    }

    &.no-typing input:focus {
        cursor: pointer;
    }

    &.header-input-container {
        margin-top: 20px;
        align-items: flex-start;
    }
`;

const Action = styled.div`
    position: absolute;
    right: 10px;
    background: var(--color-white);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: none;
    cursor: pointer;

    &.header-input {
        right: -25px;
    }
`;

const ErrorWrapper = styled.p`
    --font-size-body: 11px;
    
    color: var(--color-error);
    padding: 0 10px;
    grid-column: 1 / -1;
`;

export type AlertType = {
    alertSms: boolean,
    alertEmail: boolean,
    pushAlert: boolean,
}

type AutocompleteInputProperties = {
    value?: null | string | number,
    onChange: (value?: string | number) => void,
    onBlur?: (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => void,
    disabled?: boolean,
    placeholder?: string,
    type?: string,
    options: { label: string, value: string | number, alerts?: AlertType, trash?: boolean }[]
    icon?: IconEnum,
    optionsIcon?: IconEnum,
    noAction?: boolean,
    enableCustomValue?: boolean,
    oneLine?: boolean,
    goTop?: boolean,
    prefix?: string,
    suffix?: string,
    headerInput?: boolean,
    noTyping?: boolean,
    numeric?: boolean,
    isInputMode?: boolean,
    side?: 'left' | 'right',
    inputType?: 'money',
    maxInputLength?: number,
    allowComma?: boolean,
    isError?: boolean,
    handleDeleteFilter?: (filterId: string | number) => void,
    required?: boolean,
    errorMessage?: string,
    triggerValidation?: boolean,
    inputValidation?: (value?: string | number | null) => boolean,
    suggestionToLeft?: boolean,
    allowNegative?: boolean,
}

// TODO: INVEST-253 Podzielić ten komponent na kilka mniejszych
const AutocompleteInput: FC<AutocompleteInputProperties> = ({
    oneLine,
    enableCustomValue,
    type,
    noAction,
    icon,
    options,
    placeholder,
    disabled,
    value,
    onChange,
    onBlur,
    goTop,
    headerInput = false,
    prefix = '',
    suffix,
    noTyping = false,
    numeric = false,
    isInputMode = true,
    side,
    inputType,
    maxInputLength,
    allowComma,
    isError,
    required,
    errorMessage,
    triggerValidation,
    inputValidation,
    allowNegative,
    optionsIcon,
    suggestionToLeft,
    handleDeleteFilter
}) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const autocompleteSuggestionsRef = useRef<HTMLDivElement>(null);
    const textModeRef = useRef<HTMLDivElement>(null);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [preferredOptionIndex, setPreferredOptionIndex] = useState<number | null>(null);
    const [isErrorInput, setIsErrorInput] = React.useState(false);

    const loweredValue = value !== null && value !== undefined ? String(value)?.toLowerCase() : '';
    const filteredOptions = loweredValue.length > 0 && enableCustomValue && !numeric ? options.filter((option) => option.label.toLowerCase().includes(loweredValue)) : options;
    const actualValueOption = options.find((x) => String(x.value).toLowerCase() === String(value).toLowerCase() && !numeric);

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => autocompleteFunctions.handleKeyPress(e, filteredOptions, preferredOptionIndex, onChange, inputRef, setPreferredOptionIndex,enableCustomValue, maxInputLength);
    const { handleChange } = autocompleteFunctions;

    const handleSelectSuggestion = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string | number) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(value);
        setShowSuggestions(false);

        if (inputValidation) {
            setIsErrorInput(inputValidation(value));
        } else {
            setIsErrorInput(false);
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => {
        if (onBlur) onBlur(e);

        if (inputValidation && !showSuggestions) {
            setIsErrorInput(inputValidation(actualValueOption ? actualValueOption.label : value));
        } else {
            setIsErrorInput(false);
        }
    };

    useEffect(() => {
        if (triggerValidation && inputValidation) {
            setIsErrorInput(inputValidation(value));
        }
    }, [triggerValidation]);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (autocompleteSuggestionsRef.current && !autocompleteSuggestionsRef.current.contains(e.target as Node) && (inputRef.current && !inputRef.current.contains(e.target as Node) || textModeRef.current && !textModeRef.current.contains(e.target as Node))) {
                setShowSuggestions(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [autocompleteSuggestionsRef, inputRef, value]);

    const handleClick = () => {
        if (showSuggestions) {
            setTimeout(() => setShowSuggestions(false), 200);

            return;
        }

        setShowSuggestions(true);
        setPreferredOptionIndex(filteredOptions.findIndex((x) => String(x.value).toLowerCase() === String(value).toLowerCase()) ?? null);
    };

    return (
        <>
            <Container className={clsx({ 'no-typing': noTyping, 'header-input-container': headerInput })}>
                {icon && <Icon icon={icon}/>}
                {prefix ? <p>{prefix}</p> : null}
                {!numeric ? isInputMode ? <TextInput
                    ref={inputRef}
                    disabled={disabled}
                    type={type}
                    placeholder={placeholder}
                    value={actualValueOption ? actualValueOption.label : value}
                    onChange={(e) => {
                        handleChange(e.target.value, e!, onChange, inputType);
                    }}
                    onClick={handleClick}
                    onBlur={handleBlur}
                    onKeyDown={(e) => handleKeyPress(e)}
                    suff={suffix}
                    isError={isError || isErrorInput}
                    required={required}
                    errorMessage={errorMessage}
                    triggerValidation={triggerValidation}
                /> :
                    <AutocompleteTextModeWrapper
                        value={value}
                        handleClick={handleClick}
                        onBlur={handleBlur}
                        headerInput={headerInput}
                        textModeRef={textModeRef}
                        actualValueOption={actualValueOption}
                        showSuggestions={showSuggestions}/> : null}
                {numeric ?
                    <NumericFormat onClick={handleClick} onBlur={handleBlur}
                        onKeyDown={(e) => handleKeyPress(e)}
                        onChange={(e) => {
                            handleChange(e.target.value, e!, onChange, inputType);
                        }}
                        getInputRef={inputRef}
                        suff={suffix}
                        placeholder={placeholder} disabled={disabled}
                        value={actualValueOption ? actualValueOption.label : value}
                        thousandSeparator={inputType === 'money' ? ' ' : ''}
                        isError={isError}
                        decimalSeparator={allowComma ? ',' : '.'} decimalScale={allowComma ? 2 : 0}
                        customInput={TextInput}
                        errorMessage={errorMessage}
                        triggerValidation={triggerValidation}
                        allowNegative={allowNegative}
                        required={required}/> : null}
                {!noAction && isInputMode && (
                    <Action className={clsx('action', { 'header-input': headerInput })}>
                        {showSuggestions ? <Icon icon={IconEnum.ARROW_UP}/> :
                            <Icon icon={IconEnum.ARROW_DOWN}/>}
                    </Action>
                )}
                {<AutocompleteSuggestions
                    showSuggestions={showSuggestions}
                    oneLine={oneLine}
                    goTop={goTop}
                    side={side}
                    enableCustomValue={enableCustomValue}
                    numeric={numeric}
                    preferredOptionIndex={preferredOptionIndex}
                    optionsIcon={optionsIcon}
                    suggestionToLeft={suggestionToLeft}
                    options={filteredOptions}
                    value={value}
                    handleSelectSuggestion={handleSelectSuggestion}
                    handleDeleteFilter={handleDeleteFilter}
                    autocompleteSuggestionsRef={autocompleteSuggestionsRef}/> }

            </Container>
            {(isError || isErrorInput) && errorMessage ? <ErrorWrapper>{errorMessage}</ErrorWrapper> : null}
        </>
    );
};

export default AutocompleteInput;
