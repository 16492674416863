import dayjs from 'dayjs';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { ReportType, SaleModule, TimeRange } from 'utils/types/AnalysisModels';
import { AnalysisSaleFilterReturnData, AnalysisSaleFiltersStateType } from 'utils/types/AnalysisSale';

import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setAnalysisSaleStateValues = (state: AnalysisSaleFilterReturnData): AnalysisSaleFiltersStateType => {
    const dateFromWithDefault = state.offerAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        ...state,
        id: state.id === 0 ? undefined : state.id,
        city: state.city ?? [],
        name: state.name ?? '',
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        wantedPhoneNumbers: state.wantedPhoneNumbers ?? [],
        unwantedPhoneNumbers: state.unwantedPhoneNumbers ?? [],
        dateRangeFilter: !!state.offerAddedTo,
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.offerAddedTo && state.offerAddedTo.includes('T') ? state.offerAddedTo.split('T')[0] : state.offerAddedTo || '',
        },
        numberOfRooms: { // houses, flats
            from: state.numberOfRoomsFrom ?? null,
            to: state.numberOfRoomsTo ?? null,
        },
        typeOfMarket: state.typeOfMarket ?? [],
        timeRange: state.timeRange ?? [],
        reportType: state.reportType ?? [],
        floor: {
            from: state.floorFrom ?? '',
            to: state.floorTo ?? '',
        },
        floors: { // flats
            from: state.floorsFrom ?? '',
            to: state.floorsTo ?? '',
        },
        area: {
            from: state.areaFrom ?? '',
            to: state.areaTo ?? '',
        },
        builtYear: {
            from: state.builtYearFrom ?? '',
            to: state.builtYearTo ?? '',
        },
        pricePerSquareMeter: {
            from: state.pricePerSquareMeterFrom ?? '',
            to: state.pricePerSquareMeterTo ?? '',
        },
        buildingType: state.buildingType ?? [],
        price: {
            from: state.priceFrom ?? '',
            to: state.priceTo ?? '',
        },
        savedFilter: state.savedFilter ?? [],
        offerFrom: state.offerFrom ?? [],
        daysAmount: state.daysAmount ?? 3,
    };
};

export const getAnalysisSaleValueFromState = ( state: AnalysisSaleFiltersStateType ): AnalysisSaleFilterReturnData => {
    const dataToReturn: AnalysisSaleFilterReturnData = {
        ...state,
        name: state.name ? state.name : '',
        city: state.city ? state.city : [],
        offerAddedTo: state.dateRangeFilter && state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : undefined,
        offerAddedFrom: state.dateRangeFilter ? dateFormat(dateToFunc(state.offerAdded.from)) : undefined,
        areaFrom: stringToNumber(state.area.from),
        areaTo: stringToNumber(state.area.to),
        priceFrom: stringToNumber(state.price.from),
        priceTo: stringToNumber(state.price.to),
        pricePerSquareMeterFrom: stringToNumber(state.pricePerSquareMeter.from),
        pricePerSquareMeterTo: stringToNumber(state.pricePerSquareMeter.to),
        numberOfRoomsFrom: stringToNumber(state.numberOfRooms.from),
        numberOfRoomsTo: stringToNumber(state.numberOfRooms.to),
        builtYearFrom: stringToNumber(state.builtYear.from),
        builtYearTo: stringToNumber(state.builtYear.to),
        buildingType:
            state.buildingType?.length > 0
                ? state.buildingType
                : [],
        // @ts-expect-error TODO: INVEST-254
        floorFrom: stringToNumber(state.floor.from),
        // @ts-expect-error TODO: INVEST-254
        floorTo: stringToNumber(state.floor.to),
        floorsFrom: stringToNumber(state.floors.from),
        floorsTo: stringToNumber(state.floors.to),
        typeOfMarket: state.typeOfMarket?.length > 0 ? state.typeOfMarket : [],
        savedFilter: undefined,
        daysAmount: state.dateRangeFilter ? undefined : state.daysAmount,
        timeRange: state.timeRange,
        reportType: state.reportType
    };

    return dataToReturn;
};

const analysisSaleFilterInitialState = setAnalysisSaleStateValues({
    id: 0,
    name: '',
    numberOfRoomsFrom: undefined,
    numberOfRoomsTo: undefined,
    floorFrom: undefined,
    floorTo: undefined,
    floorsFrom: undefined,
    floorsTo: undefined,
    unwantedKeywords: [],
    wantedKeywords: [],
    unwantedPhoneNumbers: [],
    wantedPhoneNumbers: [],
    city: [],
    locations: [],
    offerFrom: [],
    savedLocations: {},
    searchLocationResult: [],
    areaFrom: undefined,
    areaTo: undefined,
    builtYearFrom: undefined,
    builtYearTo: undefined,
    typeOfMarket: [],
    buildingType: [],
    priceFrom: undefined,
    priceTo: undefined,
    pricePerSquareMeterFrom: undefined,
    pricePerSquareMeterTo: undefined,
    savedFilter: [],
    module: SaleModule.SALE_FLAT,
    includeZeroArea: true,
    groupDuplicate: true,
    timeRange: TimeRange.Tydzien,
    reportType: [ReportType.PrzedzialCzasu, ReportType.PrzedzialPowierzchni],
    boundaries: '15,35,50,70,100,#',
});

export const initialState = {
    analysisSaleFilterInitialState: () => {
        return analysisSaleFilterInitialState;
    }
};
