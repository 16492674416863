import React, { FC, useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { stringToBoolean } from 'utils/formatters/stringToBoolean';
import { useWindowWidth } from 'utils/useWindowWidth';

import InfoBadge from 'components/common/Card/common/InfoBadge';
import { User } from 'components/common/Card/common/V2CardBodyDefault/Top/Top';
import { NotInteractiveElement } from 'components/common/Card/common/V2InteractiveIconsWrapper/NotInteractiveIcons';
import V2InteractiveIconsWrapper, {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { InfoBoxContainer } from 'components/common/Card/V2BaseCard';
import V2Tabs, { TabType, V2TabsProperties } from 'components/common/Tabs/V2Tabs';

const BoxContainer = styled.div`
    background-color: var(--color-white);
    border-radius: var(--box-border-radius);
    position: relative;
    margin-bottom: 15px;
    height: 270px;
    width: 100%;
    background: var(--color-background);

    h1 {
        padding: 10px 15px;
    }
`;

const InfoBoxContainerOverride = styled(InfoBoxContainer)`
    position: unset;
    padding: 0;
`;

const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    padding: 15px 65px 0 25px;
    width: 100%;
    left: 0;
    display: grid;
    grid-template-columns: 30% 70%;

    .user-offer-details-modal {
        position: absolute;
        top: 15px;
        right: 65px;
    }

    .icons-modal {
        align-self: flex-start;
    }
`;

const HeaderImg = styled.img`
    height: 270px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
`;

export type CardDetailsProperties = {
    onToggleContainerDetails: (e: React.MouseEvent) => void;
    preferredTab?: TabType;
    modificationDate?: string;
    date?: string;
    additionalInfoBadge?: string;
    isNotInteractive?: boolean;
    notInteractiveElements?: NotInteractiveElement[];
    interactiveElements?: InteractiveElements[];
    userDetails?: User;
    thumbnailPath: string;
    tabsContent: V2TabsProperties;
    isMyOffersPage?: boolean;
    isUsersOffersPage?: boolean;
}

const CardDetails: FC<CardDetailsProperties> = ({
    preferredTab,
    onToggleContainerDetails,
    modificationDate,
    date,
    additionalInfoBadge,
    isNotInteractive,
    notInteractiveElements,
    interactiveElements,
    userDetails,
    thumbnailPath,
    tabsContent,
    isMyOffersPage,
    isUsersOffersPage
}) => {
    const width = useWindowWidth();
    const [offerDate, setOfferDate] = useState(modificationDate || date);
    const [isSuccess, setIsSuccess] = useState(!modificationDate);
    const [tabIndex, setTabIndex] = useState(TabType.DETAILS);

    const handleBadgeChange = (e: Event) => {
        if (modificationDate && width <= 1200) {
            e.stopPropagation();
            setOfferDate((prevDate) => prevDate === modificationDate ? date : modificationDate);
            setIsSuccess((prev) => !prev);
        }
    };

    const onMouseLeave = useCallback(() => {
        if (modificationDate && width > 991) {
            setTimeout(() => {
                setOfferDate(modificationDate);
                setIsSuccess(false);
            }, 150);
        }
    }, [width, modificationDate]);

    const onMouseEnter = useCallback(() => {
        if (modificationDate && width > 991) {
            setTimeout(() => {
                setOfferDate(date);
                setIsSuccess(true);
            }, 150);
        }
    }, [modificationDate, width]);

    useEffect(() => {
        switch (preferredTab) {
        case TabType.NOTES:
            setTabIndex(TabType.NOTES);
            break;
        case TabType.OPINIONS:
            setTabIndex(TabType.OPINIONS);
            break;
        default:
            setTabIndex(TabType.DETAILS);
        }
    }, [preferredTab]);

    return (
        <>
            <BoxContainer>
                <HeaderImg src={thumbnailPath}/>
                <ImgOverlay>
                    <InfoBoxContainerOverride>
                        {offerDate ? <InfoBadge onClick={width <= 1200 ? handleBadgeChange : undefined}
                            onMouseLeave={onMouseLeave}
                            onMouseEnter={onMouseEnter}
                            isSuccess={isSuccess} isWarning={!isSuccess}
                            showInfoIcon={stringToBoolean(modificationDate)}>
                            {dayjs(offerDate).format('DD.MM.YYYY, HH:mm')}
                        </InfoBadge> : null}
                        {additionalInfoBadge && <InfoBadge isWhite>
                            {additionalInfoBadge}
                        </InfoBadge>}
                    </InfoBoxContainerOverride>
                    {width >= 991 ? <V2InteractiveIconsWrapper
                        className={'icons-modal'}
                        isModalOpen
                        interactiveElements={interactiveElements}
                        notInteractiveElements={notInteractiveElements}
                        isNotInteractive={isNotInteractive}
                        userDetails={userDetails}
                        isMyOffersPage={isMyOffersPage}
                        isUsersOffersPage={isUsersOffersPage}
                    /> : null}
                </ImgOverlay>
            </BoxContainer>
            <V2Tabs {...tabsContent} toggleContainerDetails={onToggleContainerDetails} tabIndex={tabIndex} />
        </>
    );
};

export default CardDetails;
