import React, { FC } from 'react';
import { MultipleFilterType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';

import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';

import TextInput from './TextInput';

type RangeDateInputProperties = {
    date: MultipleFilterType,
    handleChange: (name: string, value: ObjectAndSingleValueChangeType) => void,
    dateRangeFilter: boolean,
    fieldName: string,
    isSingleDate?: boolean,
    inputTitle?: string,
}

const RangeDateInput: FC<RangeDateInputProperties> = ({
    date,
    handleChange,
    dateRangeFilter,
    fieldName,
    isSingleDate = true,
    inputTitle = 'Przedział dat',
}) => {
    return (
        <>
            <InputContainer title={inputTitle} icon={IconEnum.CALENDAR}>
                <TextInput
                    value={date.from ?? ''}
                    onChange={({ target: { value: valueIn } }) => handleChange(fieldName, {
                        from: valueIn,
                        to: date.to
                    })}
                    placeholder={'Od'}
                    type={'date'}
                    max={date.to as string ? undefined: ''}
                />
            </InputContainer>
            <InputContainer title={''}
                actionText={isSingleDate ? 'Pojedyncza data' : undefined}
                additionalText={isSingleDate ? undefined : 'Przedział dat'}
                onActionClick={isSingleDate ? () => handleChange('dateRangeFilter', !dateRangeFilter) : undefined}>
                <TextInput
                    value={date.to ?? ''}
                    onChange={({ target: { value: valueIn } }) => handleChange(fieldName, {
                        from: date.from,
                        to: valueIn
                    })}
                    placeholder={'Do'}
                    type={'date'}
                    min={date.from as string ? undefined : ''}
                />
            </InputContainer>
        </>
    );
};

export default RangeDateInput;
