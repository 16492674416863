import React, { FC, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';

import { Title } from 'components/common/Card/common/CardBodyDefault';
import Icon, { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

const AddressWrap = styled.p`
    padding: 10px 15px;
    color: var(--color-primary);
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    padding: 10px 15px;
    gap: 20px;

    @media (max-width: 1100px) {
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-color: var(--color-alt-second-10) var(--color-background);
        scroll-behavior: smooth;
        white-space: nowrap;
        max-width: 100%;
        padding-bottom: 30px;

        ::-webkit-scrollbar-thumb {
            background-color: var(--color-primary);
            border: none;
        }

        ::-webkit-scrollbar {
            height: 4px;
        }
    }

`;

const DetailedInfoContainer = styled.div`
    display: flex;
    align-items: center;

    span {
        margin-right: 10px;
    }
`;

const Value = styled.p`
    font-weight: 500;
    color: var(--color-primary);
    margin-bottom: 0;
`;

const DetailedInfo = styled.div`
    --font-weight: 400;

    color: var(--color-primary);

    span {
        --font-weight: 300;
    }
`;

const DetailsDescription = styled.p<{ $media?: boolean; }>`
    --font-weight: 400;

    display: flex;
    gap: 5px;
    padding: ${(props) => props.media ? '0 15px' : '10px 15px'};
    color: var(--color-primary);

    img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
    }
`;

const SwipeRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(270deg, var(--color-white) 65.18%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    z-index: 1;

    span {
        height: 100%;
        width: 100%;
    }
`;

const SwipeLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 100%;
    background: linear-gradient(90deg, var(--color-white) 65.18%, rgba(255, 255, 255, 0) 100%);
    border-radius: 10px;
    z-index: 1;

    span {
        height: 100%;
        width: 100%;
    }
`;

const File = styled.button`
    background: var(--color-primary);
    color: var(--color-white);
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
        background: var(--color-primary-70);
    }
`;

const FilesWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

type DetailsElements = {
    icon: IconEnum;
    text: string;
    value: string;
}

export type V2DetailsTabsProps = {
    description?: string;
    title?: string;
    address?: string;
    detailsElements: DetailsElements[];
    documents?: string[];
    meta?: {
        value?: string;
        key: string;
    }[]
    handleDocumentClick?: (document: string) => void;
}

const V2DetailsTab: FC<V2DetailsTabsProps> = ({
    description,
    title,
    address,
    detailsElements,
    documents,
    handleDocumentClick,
    meta
}) => {
    const gridRef = useRef<HTMLDivElement>(null);
    const width = useWindowWidth();
    const memoizedDetailsElements = useMemo(() => detailsElements, [detailsElements]);
    const memoizedKeys = useMemo(() => {
        return meta && meta.length > 0 ? (<BoxContainer>
            <Title>Słowa kluczowe:</Title>
            {meta.map((metaItem) => {
                return <span key={metaItem.key}><b>{metaItem.key}</b> - <p>{metaItem.value}</p></span>;
            })}
        </BoxContainer>) : null;
    }, [meta]);

    const swipeToRight = () => {
        const scrollPosition = gridRef.current!.scrollLeft;
        const scrollWidth = gridRef.current!.scrollWidth;
        const clientWidth = gridRef.current!.clientWidth;

        if (scrollPosition + clientWidth < scrollWidth && window.innerWidth < 991) {
            gridRef.current!.scrollLeft += 100;
        }
    };

    const swipeToLeft = () => {
        const scrollPosition = gridRef.current!.scrollLeft;
        const clientWidth = gridRef.current!.clientWidth;

        if (scrollPosition + clientWidth > 0 && window.innerWidth < 991) {
            gridRef.current!.scrollLeft -= 100;
        }
    };

    return (
        <>
            <BoxContainer>
                <Title>{title ?? '-'}</Title>
                <AddressWrap>{address ?? '-'}</AddressWrap>
            </BoxContainer>
            <BoxContainer>
                {width < 991 && <SwipeLeft onClick={swipeToLeft}>
                    <Icon icon={IconEnum.ANGLE_LEFT}/>
                </SwipeLeft>}
                <Grid ref={gridRef}>
                    {memoizedDetailsElements.map((element, index) => {
                        return (
                            <DetailedInfoContainer key={index}>
                                <Icon icon={element.icon}/>
                                <DetailedInfo>
                                    <span dangerouslySetInnerHTML={{ __html: element.text }}/>
                                    <Value dangerouslySetInnerHTML={{ __html: element.value }}/>
                                </DetailedInfo>
                            </DetailedInfoContainer>
                        );
                    })}
                </Grid>
                {width < 991 && <SwipeRight onClick={swipeToRight}>
                    <Icon icon={IconEnum.ANGLE_RIGHT}/>
                </SwipeRight>}
            </BoxContainer>
            <BoxContainer>
                <Title>Szczegóły ogłoszenia:</Title>
                {description &&
                    <DetailsDescription dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br />') }}/>}
            </BoxContainer>
            {memoizedKeys}
            {documents && documents.length > 0 ? <BoxContainer>
                <Title>Dokumenty:</Title>
                <FilesWrapper>
                    {documents.map((document, index) => {
                        return (
                            <DetailsDescription key={index}>
                                <File onClick={() => handleDocumentClick?.(document)}>{document}</File>
                            </DetailsDescription>
                        );
                    })}
                </FilesWrapper>
            </BoxContainer> : null}
        </>
    );
};

export default V2DetailsTab;
