import { NotesType } from 'store/User/State';
import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { RentModuleType } from 'utils/types/Rent';
import { MyOpinion, SaleOpinionResult } from 'utils/types/SaleModels';

export type State = {
    isLoading: boolean,
    list: OfferData[],
    rentList: RentOffersResponse,
    oldNotes: {
        content: string;
        date: string;
        noteId: number;
    }[],
    sortType: string;
    filtersState: FiltersStateType;
    pageType: ModulesType;
    filteredCity: string;
    notes?: NotesType[],
    scoreAndOpinions?: SaleOpinionResult,
    isTendeOrAuctionListEmpty?: boolean;
}

export type ReactionType = {
    likeStatus: boolean,
    unlikeStatus: boolean,
    viewedStatus: boolean,
    outdatedStatus: boolean,
    arrangedStatus: boolean,
    phoneStatus: boolean,
    hideStatus: boolean
    openedStatus: boolean
}

export type RentOfferResponseContent = {
    advertisementId: number;
    rentId?: number;
    photo: string;
    photos: string[];
    location: string;
    dateAdded: string;
    lastUpdated: string;
    title: string;
    module: RentModuleType;
    numberOfNotes: number;
    score: number | null,
    scorePrecision: number | null;
    priceBeforeModification: number | null;
    price: number;
    rentPrice: number;
    depositPrice: number | null;
    pricePerSquareMeter: number | null;
    area: number | null;
    phoneNumber: number | null;
    numberOfDuplicates: number | null;
    numberOfModifications: number | null;
    numberOfRaises: number | null;
    portal: string;
    duplicateWithLowestPrice: boolean;
    duplicateWithPrivateOffer: boolean;
    reaction: ReactionType,
    note: {
        noteId: number | null,
        content: string,
        date: string
    },
    numberOfRooms: number | null;
    floor: string;
    floors: string;
    builtYear: number | null;
    buildingType: string;
    offerFrom: string;
    typeOfMarket: string;
    detailedContent?: DetailedContent;
    link: string;
    modifications: ModificationsContent[],
    duplicates: RentOfferResponseContent[],
    metaValue: MetaValues[],
    numberOfCommunityComments: number;
    numberOfCommunityOpinions: number;
    communityScore: number;
    myOpinion: MyOpinion | null;
}

interface DetailedContent extends RentOfferResponseContent {
    description: string;
}

type MetaValues = {
    key: string;
    value: string;
}

type ModificationDetail = {
    field: string;
    newValue: string;
    oldValue: string;
}

type ModificationsContent = {
    date: string;
    modificationDetail: ModificationDetail[];
}

export type RentOffersResponse = {
    empty: boolean,
    content: RentOfferResponseContent[],
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    numberOfElements: number,
    totalPages: number,
    totalElements: number,
    last: boolean,
    size: number,
    afterSearch: boolean,
}

export const initialState: State = {
    isLoading: false,
    list: [],
    rentList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 1,
            pageSize: 0,
        },
        numberOfElements: 0,
        size: 25,
        totalPages: 0,
        totalElements: 0,
        last: false,
        afterSearch: false,
    },
    oldNotes: [],
    sortType: 'po dacie malejąco',
    filtersState: {} as FiltersStateType,
    pageType: 'advertisement',
    filteredCity: '',
};
