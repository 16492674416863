import React, { FC, useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
// import { UserActions } from 'store/User/Actions';
// import { useUser } from 'store/User/Context';
import { MessageSenders, UserMessages } from 'store/User/State';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
// import { PropertyType } from 'utils/enums/propertyType';
import { getPropertyByTypeRent, getPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData, UserReactions } from 'utils/types/OfferData';
import { RentModuleType } from 'utils/types/Rent';
import { useWindowWidth } from 'utils/useWindowWidth';

import Messages from 'components/common/Chat/Messages';
import Icon, { IconEnum } from 'components/common/Icon';
import Slider from 'components/common/Slider/Slider';

// import TrashWithModal from 'components/common/TrashWithModal';
import InteractiveIcons from './InteractiveIcons';
import InteractiveIconsPopup from './InteractiveIconsPopup';

type InteractiveIconsWrapperProperties = {
    type: ModulesType;
    className?: string;
    bottomTooltip?: boolean;
    data: OfferData;
    onNoteAdd: () => void;
    isUsersDetails?: boolean;
}

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-self: center;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;

    @media (min-width: 991px) {
        justify-content: flex-end;
    }

    &.my-offer {
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .interactive-phone {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        color: var(--color-primary);

        a {
            color: var(--color-primary);

            &:hover {
                color: white;
            }
        }

        span {
            margin-right: 10px;
        }
    }
`;

const MyOfferInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 20px;
    background: var(--color-white);
    padding: 0 5px;
`;

const MyOfferInfo = styled.div`
    display: flex;
    padding: 10px;
    gap: 10px;
`;

// const ActionWrapper = styled.div`
//   background-color: var(--color-alt-second);
//   border-radius: 20px;
//   min-width: 40px;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 10px 15px;
//   gap: 8px;
//
//   &:hover {
//     transition: background-color ease-in .2s;
//     background-color: var(--color-alt);
//     cursor: pointer;
//
//     p {
//       color: white;
//     }
//
//     img{
//       filter:${props => props.darkMode ? 'none' : 'brightness(0) invert(1)'};
//     }
//   }
// `;

// const Options = styled.div`
//     position: absolute;
//     right: 0;
//     top: -130px;
//     background: var(--color-white);
//     border-radius: var(--box-border-radius);
//     box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
//     padding: 15px;
//     display: flex;
//     flex-direction: column;
//     gap: 10px;
//     margin-left: auto;
// `;

const MessagesIconWrapper = styled.div`
    width: fit-content;
    ${(props) => props.isUsersDetails ? 'margin-top: 220px; margin-left: -150px;' : 'margin-left: auto;'};
`;

const InteractiveIconsWrapper: FC<InteractiveIconsWrapperProperties> = ({
    type,
    className,
    data,
    onNoteAdd,
    bottomTooltip = false,
    isUsersDetails
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [activeMessage, setActiveMessage] = useState(data.note && typeof data.note.noteId === 'number');
    // const [isPhoneShown, setIsPhoneShown] = useState(false);
    const [isMyOfferButtonsShown, setIsMyOfferButtonsShown] = useState(false);
    // Narazie nie robimy 'zmień na ukryte'
    // const [isOfferHidden, setIsOfferHidden] = useState(false);
    const { dispatch } = useOffers();
    // const { user, dispatch: dispatchUser } = useUser();
    const width = useWindowWidth();
    const wrapperRef = useRef<HTMLDivElement>(null);
    // @ts-expect-error TODO: Will be fixed after component refactor
    const moduleType = type === 'rent' ? getPropertyByTypeRent(data.module) : getPropertyByTypeUser(data.adType);
    const images = data.photos && data.photos.length > 0 ? data.photos.map((photo) => ({ url: photo.includes('http') ? photo : `${process.env.REACT_APP_API_URL_2}image/find?module=${moduleType}&fileName=${photo}&photoSize=NORMAL_PHOTO` })) : undefined;
    const [isModalSlider, setIsModalSlider] = useState(false);
    // const navigate = useNavigate();
    const [showMessages, setShowMessages] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [userMessages, setUserMessages] = useState(data.messageDetail ? data.messageDetail.messageDetails : []);
    // @ts-expect-error TODO: Will be fixed after component refactor
    const phone = data.phone || data.phoneNumber;

    // TODO: Create hook and replace
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            if (wrapperRef.current && !(wrapperRef.current).contains(event.target as Node)) {
                setShowPopup(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [wrapperRef]);

    const handleToggleLike = () => {
        handleChangeReaction('likeStatus', !data.reaction.likeStatus);
    };

    const onMessageClick = () => {
        if (!activeMessage) {
            setActiveMessage((prev) => !prev);
        }
        onNoteAdd();
    };

    const handleMessages = () => {
        setShowMessages((prev) => !prev);
    };

    const handleSendMessages = (message: string) => {
        const messageSender = data.messageDetail?.adOwner ? MessageSenders.AD_OWNER : MessageSenders.INTERESTED;
        const messageId = data.messageDetail?.messageDetails ? data.messageDetail.messageDetails[0].messageId : undefined;
        userApiRequests.sendMessage(data.module, messageSender, message, data.advertisementId, messageId?.toString()).then((response) => {
            // @ts-expect-error axios response type for now
            setUserMessages(response);
        });
    };

    const handleChangeReaction = (name: keyof UserReactions, value: boolean) => {
        const userReaction = Object.assign({}, data.reaction);

        if (name === 'likeStatus' && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && value) {
            userReaction.likeStatus = false;
        }
        (userReaction[name] as boolean) = value;
        const city = data.city || data.reaction?.city || data.note?.city || '';

        if (type === 'users' || type === 'my_offer' || type === 'profile') {
            // UserActions.reactionUpdate(dispatchUser, data.id || data.advertisementId!, city, userReaction, propertyType);
        } else {
            if (type === 'rent') {
                OffersActions.reactionUpdate(dispatch, data.id || data.advertisementId!, city, type, userReaction, data.module as RentModuleType);
            } else if (type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                OffersActions.reactionUpdate(dispatch, data.id || data.advertisementId!, city, type, userReaction, type as RentModuleType);
            } else {
                OffersActions.reactionUpdate(dispatch, data.id || data.advertisementId, city, type, userReaction);
            }
        }
    };

    // const handlePhoneClick = () => {
    //     if (!isPhoneShown)
    //         setIsPhoneShown((prev) => !prev);
    // };

    // Narazie nie robimy 'zmień na ukryte'
    // const handleVisibiltyChange = () => {
    //     setIsOfferHidden((prev) => !prev);
    // };

    const toggleIsModalSlider = () => {
        setIsModalSlider((prev) => !prev);
    };

    // const handleDeleteOffer = async () => {
    //     // @ts-expect-error not important
    //     await UserActions.deleteMyOffer(dispatchUser, data.advertisementId, data.adType);
    // };
    //
    // const handleEditOffer = () => {
    //     UserActions.loadMyOfferToEdit(dispatchUser, data.module as PropertyType, data.advertisementId);
    //     navigate('/edit-offer');
    // };

    return (
        <>
            {type === 'users' || type === 'profile' ?
                <>
                    {showMessages && <Messages username={data.userName!} referenceElement={referenceElement}
                        onClose={handleMessages} title={data.title}
                        photo={data.photos && data.photos.length > 0 ? `${process.env.REACT_APP_API_URL_2}image/find?fileName=${data.photos[0]}&photoSize=NORMAL_PHOTO&module=USER_OFFER_FLAT` : '/defaultImg.png'}
                        price={data.price as unknown as string}
                        onSendMessage={handleSendMessages}
                        messages={userMessages as unknown as UserMessages[]}
                        messageOwnerType={MessageSenders.INTERESTED}
                        isPopup
                    />}
                    {/*TODO: need to clarify data*/}
                    {!data.messageDetail?.adOwner ?
                        <MessagesIconWrapper isUsersDetails={isUsersDetails} ref={setReferenceElement}>
                            <InteractiveIcons icon={IconEnum.EMAIL} isModalOpen={isUsersDetails} isMessageBtn
                                onClick={handleMessages}
                                text={<p>Wyślij wiadomość</p>}
                            />
                        </MessagesIconWrapper> : null}
                </> : null}
            <Wrapper ref={wrapperRef}
                className={clsx(className, { 'my-offer': type === 'my_offer' && !isUsersDetails })}>
                {type === 'my_offer' ?
                    <>
                        <MyOfferInfoWrapper>
                            <MyOfferInfo>
                                <Icon icon={IconEnum.EYE}/>
                                {data.viewedStatus}
                            </MyOfferInfo>
                            <MyOfferInfo>
                                <Icon icon={IconEnum.LIKE}/>
                                {data.likeStatus}
                            </MyOfferInfo>
                            <MyOfferInfo>
                                <Icon icon={IconEnum.DISLIKE}/>
                                {data.unlikeStatus}
                            </MyOfferInfo>
                            <MyOfferInfo>
                                <Icon icon={IconEnum.BLOCKED}/>
                                {data.outdatedStatus}
                            </MyOfferInfo>
                            <MyOfferInfo>
                                <Icon icon={IconEnum.PHONE}/>
                                {data.phoneStatus}
                            </MyOfferInfo>
                            <MyOfferInfo>
                                <Icon icon={IconEnum.PEOPLES_3}/>
                                {data.arrangedStatus}
                            </MyOfferInfo>
                        </MyOfferInfoWrapper>
                        <>
                            {/*Narazie nie robimy 'zmień na ukryte'*/}
                            {/*{isOfferHidden ?*/}
                            {/*    <InteractiveIcons tooltipText="Zmień na widoczne" icon={'eye_hidden'}*/}
                            {/*        bottomTooltip={bottomTooltip}*/}
                            {/*        onClick={handleVisibiltyChange}/> :*/}
                            {/*     <InteractiveIcons tooltipText="Zmień na ukryte" icon={'eye'}*/}
                            {/*         bottomTooltip={bottomTooltip}*/}
                            {/*         onClick={handleVisibiltyChange}/>}*/}
                            {/*{isMyOfferButtonsShown && !isUsersDetails ? <Options>*/}
                            {/*    <ActionWrapper onClick={handleEditOffer}>*/}
                            {/*        <Icon icon={'edit'}/>*/}
                            {/*        <p>Edytuj ogłoszenie</p>*/}
                            {/*    </ActionWrapper>*/}
                            {/*    <ActionWrapper darkMode={user.isDarkMode}>*/}
                            {/*        <TrashWithModal iconText={'Zakończ ogłoszenie'} title={'Czy na pewno chcesz usunąć ogłoszenie?'} onChange={handleDeleteOffer} filter={data.title}/>*/}
                            {/*    </ActionWrapper>*/}
                            {/*</Options> : null}*/}
                        </>
                        <InteractiveIcons bottomTooltip={bottomTooltip} tooltipText="Więcej"
                            icon={IconEnum.SQUARES} onClick={() => setIsMyOfferButtonsShown(!isMyOfferButtonsShown)}/>
                    </>
                    :
                    <>
                        <InteractiveIcons tooltipText="Wyświetlono" icon={IconEnum.EYE} bottomTooltip={bottomTooltip}
                            active={data.reaction.viewedStatus}
                            disabled={data.reaction?.viewedStatus === undefined}
                            onClick={() => handleChangeReaction('viewedStatus', !data.reaction.viewedStatus)}/>
                        <InteractiveIcons
                            tooltipText="Lubię to" icon={IconEnum.LIKE} bottomTooltip={bottomTooltip}
                            active={data.reaction.likeStatus}
                            onClick={handleToggleLike}/>
                        <InteractiveIcons tooltipText="Nie lubię tego" icon={IconEnum.DISLIKE}
                            bottomTooltip={bottomTooltip}
                            active={data.reaction?.unlikeStatus}
                            disabled={data.reaction?.unlikeStatus === undefined}
                            onClick={() => handleChangeReaction('unlikeStatus', !data.reaction.unlikeStatus)}/>
                        <InteractiveIcons
                            tooltipText="Notatki" onClick={onMessageClick} icon={IconEnum.MESSAGE}
                            bottomTooltip={bottomTooltip}
                            active={(data.numberOfNotes && data.numberOfNotes > 0) as boolean && activeMessage}
                            counter={data.numberOfNotes ? data.numberOfNotes || 1 : undefined}/>
                        <InteractiveIcons
                            tooltipText="Ogłoszenie nieaktualne" icon={IconEnum.BLOCKED} bottomTooltip={bottomTooltip}
                            active={data.reaction?.hideStatus}
                            disabled={data.reaction?.hideStatus === undefined}
                            onClick={() => handleChangeReaction('hideStatus', !data.reaction.hideStatus)}/>
                        <InteractiveIcons
                            tooltipText="Przeprowadzona rozmowa" icon={IconEnum.PHONE} bottomTooltip={bottomTooltip}
                            active={data.reaction?.phoneStatus}
                            disabled={data.reaction?.phoneStatus === undefined}
                            onClick={() => handleChangeReaction('phoneStatus', !data.reaction.phoneStatus)}/>
                        <InteractiveIcons
                            tooltipText="Umówione spotkanie" icon={IconEnum.PEOPLES_3} bottomTooltip={bottomTooltip}
                            active={data.reaction?.arrangedStatus}
                            disabled={data.reaction?.arrangedStatus === undefined}
                            onClick={() => handleChangeReaction('arrangedStatus', !data.reaction.arrangedStatus)}/>
                    </>}
                {phone && type !== 'users' && type !== 'profile' && <InteractiveIcons icon={IconEnum.PHONE} isLink isModalOpen={isUsersDetails} isPhoneBtn={false}
                    text={<a href={`tel:${phone}`}>{phone}</a>}/>}
                {/*{phone && type !== 'my_offer' &&*/}
                {/*    (isPhoneShown || type === 'rent' ?*/}
                {/*        (*/}
                {/*            <InteractiveIcons icon={'phone'} isLink isUsersDetails={isUsersDetails} isPhoneBtn={type === 'users' || type === 'profile'}*/}
                {/*                text={<a href={`tel:${phone}`}>{phone}</a>}/>*/}
                {/*        ) : (*/}
                {/*            <InteractiveIcons icon={'phone'} isLink isUsersDetails={isUsersDetails}*/}
                {/*                onClick={handlePhoneClick} isPhoneBtn={type === 'users' || type === 'profile'}*/}
                {/*                text={<p>{'Pokaż numer'}</p>}/>*/}
                {/*        ))}*/}
                {type === 'my_offer' ? null : type === 'users' || type === 'profile' ?
                    <InteractiveIcons bottomTooltip={bottomTooltip} tooltipText="Więcej"
                        icon={IconEnum.SQUARES}/> :
                    <InteractiveIcons bottomTooltip={bottomTooltip} tooltipText="Przejdź do ogłoszenia" icon={IconEnum.LINK}
                        isLink
                        noText
                        text={<a target="_blank" rel="noopener noreferrer" href={data.link || data.portal.link}/>}/>}
                {type === 'cooperative' && width < 991 &&
                    <InteractiveIcons bottomTooltip={bottomTooltip} tooltipText="Przejdź do ogłoszenia" icon={IconEnum.LINK}
                        isLink text={<a target="_blank" rel="noopener noreferrer"
                            href={data.portal.link}>{data.portal.name}</a>}/>}
                {type === 'auction' &&
                    <InteractiveIcons icon={IconEnum.SQUARES} onClick={() => setShowPopup((prev) => !prev)}/>}
                {showPopup ? <InteractiveIconsPopup type={type} data={data}/> : null}
                {isUsersDetails && images &&
                    <InteractiveIcons onClick={toggleIsModalSlider} icon={IconEnum.PHOTOS} isModalOpen={isUsersDetails} isLink
                        isPhotoBtn
                        text={<p>Zobacz zdjęcia</p>}/>}
                {isUsersDetails && images && isModalSlider ?
                    <Slider images={images} title={data.title} modalOpen modalMode toggleIsModalSlider={toggleIsModalSlider} /> : null}
            </Wrapper>
        </>
    );
};

export default InteractiveIconsWrapper;
