import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const Container = styled.button`
    background: var(--color-success);
    border-radius: 40px;
    height: 50px;
    border: none;
    padding: ${(props) => props.actionIconVisible ? '10px 10px 10px 20px' : '0'};
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none !important;
    cursor: pointer;

    img {
        filter: invert(59%) sepia(81%) saturate(4329%) hue-rotate(97deg) brightness(94%) contrast(91%) !important;
    }

    &.disabled,
    &.btn[disabled],
    &:disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }

    &:hover {
        background: #0B8E21;
    }

    &.important {
        background: var(--color-warning);

        img {
            filter: invert(50%) sepia(87%) saturate(698%) hue-rotate(351deg) brightness(98%) contrast(90%) !important;
        }

        &:hover {
            --color-warning: #DA6900;
        }
    }
    
    &.secondary {
        background: var(--color-alt);

        &:hover {
            background: var(--color-alt);
        }
    }

    &.default {
        background: var(--color-alt-second);
        
        div {
            color: var(--color-primary);
        }

        &:hover {
            background: var(--color-alt-second);
        }
    }
`;

const Wrapper = styled.div`
    display: flex;
    color: white;
    text-align: left;
    font-style: normal;
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 20px;
    text-decoration: none !important;

    .button-icon {
        margin-right: 10px;
        filter: brightness(0) invert(1) !important;
    }
`;

const Action = styled.div`
    background: white;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    margin-left: 15px;

    img {
        width: 15px;
        height: 15px;
    }
`;

type ButtonProperties = {
    onClick?: (e: React.MouseEvent) => void;
    children?: ReactNode;
    actionIcon?: IconEnum,
    disabled?: boolean;
    className?: string;
    important?: boolean;
    icon?: IconEnum;
    actionIconVisible?: boolean;
    secondary?: boolean;
    defaultVariant?: boolean;
}

const Button: FC<ButtonProperties> = ({
    disabled,
    actionIcon,
    children,
    onClick,
    className,
    important,
    icon,
    actionIconVisible = true,
    secondary,
    defaultVariant,
}) => {
    return (
        <Container actionIconVisible={actionIconVisible} onClick={onClick} className={clsx(className, {
            disabled: disabled,
            important: important,
            secondary: secondary,
            default: defaultVariant,
        })}>
            <Wrapper>
                {icon && <Icon classNames={'button-icon'} icon={icon}/>}
                {children}
            </Wrapper>
            {actionIconVisible ? <Action>
                <Icon classNames={'icon-in-button'} icon={actionIcon ?? IconEnum.ARROW_RIGHT}/>
            </Action> : null}
        </Container>
    );
};

export default Button;
