import { axiosApiInstance2 } from 'Store';
import { RentModuleType } from 'utils/types/Rent';
import { OpinionUpdateInformation, SaleOpinionResult } from 'utils/types/SaleModels';

import { activeNotification } from 'components/functions/activeNotification';

const addOpinion = (advertisementId: number, content: string, numberOfStars: number, module: RentModuleType): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance2({
        method: 'post',
        url: `/rent-offer/opinion/create?module=${module}`,
        data: {
            advertisementId,
            content,
            numberOfStars,
        },
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została dodana', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się dodać opinii', 'danger');
        });
};

const updateOpinion = (opinionId: number, content: string, numberOfStars: number, module: RentModuleType): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance2({
        method: 'put',
        url: `/rent-offer/opinion/update?module=${module}`,
        data: {
            opinionId,
            content,
            numberOfStars,
        },
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została za aktualizowana', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się aktualizować opinii', 'danger');
        });
};

const deleteOpinion = (opinionId: number, module: RentModuleType): Promise<OpinionUpdateInformation> => {
    return axiosApiInstance2({
        method: 'delete',
        url: `/rent-offer/opinion/delete?module=${module}&opinionId=${opinionId}`,
    })
        .then((response) => {
            activeNotification('Udało się!', 'Opinia została usunięta', 'success');

            return response.data;
        })
        .catch(() => {
            activeNotification('Problem z dodaniem opinii', 'Niestety nie udało się usunąć opinii', 'danger');
        });
};

const getOpinions = (advertisementId: number, module: RentModuleType): Promise<SaleOpinionResult> => {
    return axiosApiInstance2({
        method: 'get',
        url: `/rent-offer/opinion/find?module=${module}&rentId=${advertisementId}`,
    })
        .then((resp) => {
            return resp.data;
        })
        .catch(() => {
            activeNotification('Problem z pobraniem opinii', 'Niestety nie udało się pobrać opinii', 'danger');
        });
};

export const rentOffersApiRequests = {
    addOpinion,
    updateOpinion,
    deleteOpinion,
    getOpinions
};
