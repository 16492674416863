import React, { FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const ModalBackground = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${(props) => props.darkMode ? `rgba(7, 7, 7, ${props.fullOpacity ? '1' : '0.9'})` : `rgba(242, 245, 249, ${props.fullOpacity ? '1' : '0.9'})`};
`;

const ModalWrapper = styled.div`
    z-index: 100;
    position: absolute;
    top: 0;
    display: flex;
    width: 100%;
    height: ${(props) => props.centeredContent ? '100%' : 'auto'};
    ${(props) => props.centeredContent ? 'justify-content: center' : null};
    ${(props) => props.strictContentHeight ? 'align-items: center' : null};
`;

const ModalContentContainer = styled.div<ModalContentProperties>`
    position: relative;
    width: 100%;
    max-width: 1000px;
    max-height: 95vh;
    border-radius: var(--box-border-radius);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 20px;
    z-index: 1;
    ${(props) => props.strictContentHeight ? 'height: min-content' : null};
`;

const ModalCloseButton = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    background: ${(props) => props.isDarkMode ? 'var(--color-primary)' : 'var(--color-white)'};
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: transform 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
        transition: transform 0.2s ease-in-out;
        transform: rotateZ(90deg) scale(1.1);
    }

    z-index: 2;
`;

const Content = styled.div`
    position: relative;
    width: 100%;
    height: ${(props) => props.centeredContent ? '100%' : 'auto'};
    ${(props) => props.centeredContent ? null : 'overflow-y: scroll'};
    ${(props) => props.centeredContent ? 'display: flex' : null};
    ${(props) => props.centeredContent ? 'flex-direction: column' : null};
    ${(props) => props.centeredContent ? 'justify-content: center' : null};
    background-color: ${(props) => props.backgroundColor ? props.backgroundColor : 'var(--color-alt-second)'};
`;

interface ModalContentProperties {
    centeredContent?: boolean;
}

interface ModalProps {
    isOpen: boolean;
    onClose: (e: React.MouseEvent | KeyboardEvent) => void;
    children: ReactNode;
    centeredContent?: boolean;
    fullOpacity?: boolean;
    strictContentHeight?: boolean;
    backgroundColor?: string;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children, centeredContent, fullOpacity, strictContentHeight, backgroundColor }) => {
    const { user } = useUser();
    const [showModal, setShowModal] = useState(isOpen);

    useEffect(() => {
        setShowModal(isOpen);
    }, [isOpen]);

    if (!showModal) {
        return null;
    }

    const modalContent = (
        <ModalWrapper centeredContent={centeredContent} strictContentHeight={strictContentHeight}>
            <ModalBackground fullOpacity={fullOpacity} darkMode={user.isDarkMode} onClick={!fullOpacity ? onClose : undefined} />
            <ModalContentContainer strictContentHeight={strictContentHeight}>
                <ModalCloseButton isDarkMode={user.isDarkMode} onClick={onClose}>
                    <Icon icon={IconEnum.CROSS}/>
                </ModalCloseButton>
                <Content centeredContent={centeredContent} backgroundColor={backgroundColor}>
                    {children}
                </Content>
            </ModalContentContainer>
        </ModalWrapper>
    );

    return ReactDOM.createPortal(
        modalContent,
        document.body
    );
};

export default Modal;
