import React, { FC, useState } from 'react';
import { Cell, Pie, PieChart, Tooltip } from 'recharts';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 35px;
    position: relative;

    &:hover {
        background: ${(props) => props.darkMode ? 'linear-gradient(180deg, rgba(242, 245, 249, 0.00) 0%, rgba(242, 245, 249, 0.1) 62.50%)' : 'linear-gradient(180deg, rgba(2, 85, 170, 0) 0%, rgba(2, 85, 170, 0.1) 62.5%)'};
        border-radius: 0 0 6px 6px;
    }
`;

type PieWrapperProperties = {
    pieData: {
        name: string;
        value: number;
        percentage: string;
    }[];
    handleActivePie: (name: string) => void;
}

type TooltipPayload = {
    payload?: {
        name: string;
    }[];
}

const PieWrapper: FC<PieWrapperProperties> = ({ pieData, handleActivePie }) => {
    const [activePie, setActivePie] = useState('');
    const { user } = useUser();
    const pieRatio = 1 / pieData.length;
    const rgbColor = user.isDarkMode ? '242, 245, 249' : '2, 85, 170';

    const CustomTooltip = ({ payload }: TooltipPayload) => {
        if (payload) {
            handleActivePie && handleActivePie(payload[0]?.name);
            setActivePie(payload[0]?.name);
        }

        return null;
    };

    return (
        <Wrapper darkMode={user.isDarkMode}>
            <PieChart width={245} height={245}>
                <Tooltip content={<CustomTooltip/>}
                    position={{ y: 120, x: 290 }}
                    allowEscapeViewBox={{ x: true, y: true }}/>
                <Pie blendStroke data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%"
                    outerRadius={120} stroke="none" startAngle={0}
                    endAngle={-360}>
                    {pieData.map((entry, index) => {
                        const opacity = 1 - (index * pieRatio);

                        return (
                            <Cell key={`cell-${index}`} fill={`rgba(${rgbColor}, ${opacity})`}
                                strokeWidth={activePie === entry.name ? '3' : '0'}/>
                        );
                    })}
                </Pie>
            </PieChart>
        </Wrapper>
    );
};

export default PieWrapper;
