import React, { FC } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { UserFiltersProvider } from 'utils/context/UserFiltersContext';
import { ModulesType } from 'utils/types/ModulesType';

import Indicator from 'components/atom/Indicator';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import HeaderSection from 'components/HeaderSection';
import FiltersUsers from 'components/users/FiltersUsers';
import UsersOfferList from 'components/users/UsersOfferList';

const Container = styled.div`
    padding: 15px 20px 0;
`;

type UsersFilterRendererProperties = {
    type: ModulesType
}

const UserAdvertsPage: FC<UsersFilterRendererProperties> = ({ type }) => {
    const { user } = useUser();

    return (
        <div>
            <UserFiltersProvider>
                <HeaderSection type={'users'} />
                <FiltersContentWrapper className={clsx('offers-list', { active: true })}>
                    <Container id="filters">
                        <FiltersUsers />
                    </Container>
                    <UsersOfferList showList={!user.usersOfferList.empty && user.usersOfferList.content && user.usersOfferList.content?.length > 0}
                        type={type} showNoItemsText={user.usersOfferList && user.usersOfferList.empty} />
                    {user.isLoading && <Indicator/>}
                </FiltersContentWrapper>
            </UserFiltersProvider>
        </div>
    );
};

export default UserAdvertsPage;
