import React, { FC, ReactNode } from 'react';

type TabPanelProperties = {
  children: ReactNode;
  isActive: boolean;
}

const TabPanel: FC<TabPanelProperties> = ({ children, isActive }) => {
    return (
        <>
            {isActive ? children : null}
        </>);
};

export default TabPanel;