import React, { FC, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from 'store/User/Context';
import { MessageSenders, UserMessages } from 'store/User/State';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { AllPropertyTypes, getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { usersPropertyTypeOptions } from 'utils/options';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { useWindowWidth } from 'utils/useWindowWidth';

import Messages from 'components/common/Chat/Messages';
import Icon, { IconEnum } from 'components/common/Icon';
import RatingStars from 'components/common/RatingStars/RatingStars';

import InfoElement from './InfoElement/InfoElement';
import InteractiveIconsWrapper from './InteractiveIconsWrapper';

interface CardBodyProperties {
    data: OfferData;
    type: ModulesType;
    onNoteAdd: () => void;
}

const Wrapper = styled.div`
    display: grid;
    grid-template-areas:
          "left right" 
          "left right" 
          "left right"
          "bottom bottom";
    gap: 15px;
`;

const InformationContainer = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 15px;

    @media (min-width: 991px) {
        width: 70%;
    }
`;

const LeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: ${(props) => props.isUpperLabel ? '3 / left' : '2 / left'};
`;

const BottomActions = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    grid-area: bottom;
`;

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    grid-area: ${(props) => props.isUpperLabel ? '3 / right' : '2 / right'};
    text-align: right;
`;

const Link = styled.a`
    display: flex;
    gap: 5px;
    width: min-content;
    text-decoration: none;
    color: var(--color-primary);
`;

const ModificationsContainer = styled.div`
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
    margin-top: 10px;

    @media (min-width: 1100px) {
        flex-direction: column;
        align-items: end;
        margin-top: auto;
        margin-bottom: unset;
    }
`;

const PreTitle = styled.div`
    line-height: 20px;
    grid-area: 1;
`;

const Modifications = styled.div`
    display: flex;
    gap: 5px;
    color: var(--color-primary);
    font-weight: 400;

    b {
        font-weight: 500;
    }
`;

const PriceValue = styled.h1`
    font-weight: 600;
    font-size: 20px;
    line-height: inherit;
    color: var(--color-alt);
`;

const OldPriceValue = styled.h4`
    text-decoration-line: line-through;
    font-weight: 500;
    margin: 0;
    letter-spacing: 1.4px;
    line-height: 20px;
    color: var(--color-primary);
`;

export const Title = styled.h1`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-break: anywhere;
`;

const Top = styled.div`
    position: relative;
    display: grid;
    grid-area: ${(props) => props.isUpperLabel ? '2 / 1 / 2 / right' : '1 / 1 / 1 / right'};
    grid-template-columns: 1fr auto;
    gap: 15px;
`;

const User = styled.div`
    display: flex;
    height: fit-content;
    flex-wrap: nowrap;
    margin-left: auto;
    gap: 5px;
    grid-column: 3;
`;

const Options = styled.div`
    position: absolute;
    right: 0;
    top: 40px;
    background: var(--color-white);
    border-radius: var(--box-border-radius);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: auto;
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
    padding: 20px;
    border-radius: 50px;
    color: var(--color-primary);
    gap: 8px;
    background-color: var(--color-alt-second);
    min-width: 40px;
    height: 50px;
  
  &:hover {
    transition: background-color ease-in .2s;
    background-color: var(--color-alt);
    cursor: pointer;

    p {
      color: white;
    }
    
    img {
      filter: ${(props) => props.darkMode ? 'unset' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
    }
  }
`;

const LabelsContainer = styled.div`
    grid-area: 1 / 1;
    display: flex;
    gap: 10px;
`;

const Label = styled.span`
    --font-size-body: var(--font-size-body-3);
    
    padding: 5px 10px;
    background: ${(props) => props.darkerColor ? '#EFC9B9' : '#FAE0C1'};
    border-radius: 5px;
    height: min-content;
`;

const CardBodyDefault: FC<CardBodyProperties> = ({
    data,
    type,
    onNoteAdd,
}) => {
    const width = useWindowWidth();
    const leftColumn = useRef<HTMLDivElement>();
    const title = useRef<HTMLHeadingElement>();
    const usersPropertyType = usersPropertyTypeOptions.find(({ value }) => value === data.adType);
    const [showMessages, setShowMessages] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [userMessages, setUserMessages] = useState(data.messageDetail ? data.messageDetail.messageDetails : []);
    const [isUserNameOptionsShown, setIsUserNameOptionsShown] = React.useState(false);
    const { user } = useUser();
    const navigate = useNavigate();
    const v2PropertyType = getNormalizedPropertyType(data.module as AllPropertyTypes);
    const v2ActivePropertyType = usersPropertyTypeOptions.find(({ value }) => getNormalizedPropertyType(value) === v2PropertyType);

    const preventDefaultHandle = (e: Event) => {
        e.stopPropagation();
    };

    const handleUserNameOptions = (e: Event) => {
        e.stopPropagation();
        setIsUserNameOptionsShown((prev)=>!prev);
    };

    const goToProfile = (e: Event) => {
        e.stopPropagation();
        navigate(`/profile/${data.profileId}`);
    };

    const handleMessages = (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
        setShowMessages((prev) => !prev);
    };

    const handleSendMessages = (message: string) => {
        const messageSender = data.messageDetail?.adOwner ? MessageSenders.AD_OWNER : MessageSenders.INTERESTED;
        const messageId = data.messageDetail?.messageDetails ? data.messageDetail.messageDetails[0].messageId : 0;
        // @ts-expect-error TODO: will be changed after refactor
        userApiRequests.sendMessage(data.module, messageSender, message, data.advertisementId as unknown as number, messageId).then((response) => {
            // @ts-expect-error axios response type for now
            setUserMessages(response);
        });
    };

    return (
        <Wrapper>
            <LabelsContainer>
                {/*@ts-expect-error TODO: will be changed after refactor*/}
                {data.duplicateWithLowestPrice ? <Label darkerColor>Duplikat z niższą ceną</Label> : null}
                {/*@ts-expect-error TODO: will be changed after refactor*/}
                {data.duplicateWithPrivateOffer ? <Label>Duplikat od os. prywatnej</Label> : null}
            </LabelsContainer>
            {/*@ts-expect-error TODO: will be changed after refactor*/}
            <Top isUpperLabel={data.duplicateWithLowestPrice || data.duplicateWithPrivateOffer}>
                {(data.location ?? data.city) && <PreTitle>{data.location ?? data.city}</PreTitle>}
                {(type === 'users' || type === 'my_offer' || type === 'profile' ? <User onClick={(e: Event) => handleUserNameOptions(e)}><Icon icon={IconEnum.USER} />{data.userName}</User> : null)}
                {/*@ts-expect-error TODO: will be changed after refactor*/}
                {(type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ? <User><RatingStars score={data.score} /></User> : null)}
                {isUserNameOptionsShown && type === 'users' && data.messageDetail && !data.messageDetail.adOwner ?
                    <Options ref={setReferenceElement}>
                        <ButtonContainer darkMode={user.isDarkMode} onClick={(e:Event) => goToProfile(e)}>
                            <Icon icon={IconEnum.USER}/>
                            <p>Inne oferty użytkownika</p>
                        </ButtonContainer>
                        <ButtonContainer darkMode={user.isDarkMode} onClick={handleMessages}>
                            <Icon icon={IconEnum.EMAIL}/>
                            <p>Wyślij wiadomość</p>
                        </ButtonContainer>
                    </Options> : null}
                {showMessages && <Messages username={data.userName!} referenceElement={referenceElement}
                    // @ts-expect-error TODO: will be removed in future
                    onClose={handleMessages} title={data.title}
                    photo={data.photos && data.photos.length > 0 ? `${process.env.REACT_APP_API_URL_2}image/find?fileName=${data.photos[0]}&photoSize=NORMAL_PHOTO&module=USER_OFFER_FLAT` : '/defaultImg.png'}
                    price={data.price as unknown as string}
                    onSendMessage={handleSendMessages}
                    messages={userMessages as unknown as UserMessages[]}
                    messageOwnerType={MessageSenders.INTERESTED}
                    isPopup
                />}
            </Top>
            {/*@ts-expect-error TODO: will be changed after refactor*/}
            <LeftColumn ref={leftColumn} isUpperLabel={data.duplicateWithLowestPrice || data.duplicateWithPrivateOffer}>
                <Title ref={title}>{data.title}</Title>
                {width > 490 && !(type === 'auction' || type === 'tender') && (
                    <InformationContainer>
                        {type === 'rent' ?
                            <InfoElement icon={IconEnum.BUILDING} isVisible={!!v2ActivePropertyType?.label}>
                                <b>{v2ActivePropertyType?.label}</b>
                            </InfoElement> : null}
                        {type === 'users' || type === 'my_offer' || type === 'profile' ?
                            <InfoElement icon={IconEnum.BUILDING} isVisible={!!usersPropertyType?.label}>
                                <b>{usersPropertyType?.label}</b>
                            </InfoElement>
                            :
                            <InfoElement icon={IconEnum.BUILDING} isVisible={!!data.type}>
                                <b>{data.type}</b>
                            </InfoElement>}
                        <InfoElement icon={IconEnum.WORK} isVisible={!!data.typeOfMarket}>
                            Rynek <b>{data.typeOfMarket}</b>
                        </InfoElement>
                        <InfoElement icon={IconEnum.SQUARE} isVisible={!!data.area}>
                            <b>{data.area}</b> m<sup>2</sup>
                        </InfoElement>
                        <InfoElement icon={IconEnum.BED} isVisible={!!data.numberOfRooms}>
                            <b>{data.numberOfRooms}</b> pokoje
                        </InfoElement>
                        <InfoElement icon={IconEnum.FLOOR_LEVEL} isVisible={!!data.floor}>
                            <b>{data.floor}</b> piętro
                        </InfoElement>
                        <InfoElement icon={IconEnum.TWO_TYPE} isVisible={!!data.buildingType}>
                            <b>{data.buildingType}</b>
                        </InfoElement>
                        <InfoElement icon={IconEnum.CALENDAR} isVisible={!!data.builtYear}>
                            <b>{data.builtYear}</b>
                        </InfoElement>
                    </InformationContainer>
                )}
            </LeftColumn>
            {/*@ts-expect-error TODO: will be changed after refactor*/}
            <PriceContainer isUpperLabel={data.duplicateWithLowestPrice || data.duplicateWithPrivateOffer}>
                {(type === 'advertisement') && data.price && data.price.toString().replaceAll(' ', '') !== data.originalPrice?.replaceAll(' ', '') ?
                    <OldPriceValue>{`${data.originalPrice} zł`} </OldPriceValue> : null}
                {/*@ts-expect-error TODO: will be changed after refactor*/}
                {(type === 'rent') && data.priceBeforeModification && data.price ?
                    // @ts-expect-error TODO: will be changed after refactor
                    <OldPriceValue>{`${data.priceBeforeModification} zł`} </OldPriceValue> : null}
                {/*@ts-expect-error TODO: will be changed after refactor*/}
                {(type === 'advertisement' || type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') && <PriceValue>{(data.price || data.originalPrice || data.priceBeforeModification) ?? '-'} zł</PriceValue>}
                {((type === 'my_offer' || type === 'users' || type === 'profile') && data.rentPrice) && <PriceValue>{data.rentPrice ?? '-'} zł/msc</PriceValue>}
                {((type === 'auction' || type === 'tender' || type === 'my_offer' || type === 'users' || type === 'profile') && data.price) &&
                    <PriceValue>{`${data.price ? `${data.price} zł` : '-'}`}</PriceValue>}

                {data.pricePerSquareMeter && type !== 'RENT_FLAT' && type !== 'RENT_PLOT' && type !== 'RENT_HOUSE' && type !== 'RENT_OTHER' &&
                    <Modifications><b>{formatFilterPrice(data.pricePerSquareMeter.toString(), 2)}</b> zł /
                        m<sup>2</sup></Modifications>}
                {(type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' || type === 'my_offer') && data.depositPrice &&
                    <Modifications><b>Kaucja:</b> {data.depositPrice ? `${data.depositPrice} zł` : '-'}</Modifications>}
                {(type === 'auction' || type === 'tender') &&
                    <Modifications>{data.category?.replace(',', '')}</Modifications>}

                {type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ?
                    <Link href={`${data.link}`} target="_blank" rel="noopener noreferrer"
                        onClick={preventDefaultHandle}>
                        <Icon icon={IconEnum.LINK}/>{data.portal}
                    </Link> : type !== 'my_offer' && type !== 'users' && type !== 'profile' ?
                        <Link href={data.portal.link} target="_blank" rel="noopener noreferrer"
                            onClick={preventDefaultHandle}>
                            <Icon icon={IconEnum.LINK}/>{data.portal.name}
                        </Link> : null}
                {!(type === 'auction' || type === 'tender' || type === 'my_offer' || type === 'users' || type === 'profile' || width < 991) && (
                    <ModificationsContainer>
                        {/*@ts-expect-error TODO: will be changed after refactor*/}
                        <Modifications>Duplikaty: <b>{type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ? data.numberOfDuplicates ? data.numberOfDuplicates : 0 : data.duplications?.length ?? 0}</b></Modifications>
                        {/*@ts-expect-error TODO: will be changed after refactor*/}
                        <Modifications>Modyfikacje: <b>{type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ? data.numberOfModifications ? data.numberOfModifications : 0 : data.modifications?.length ?? 0}</b></Modifications>
                        {/*@ts-expect-error TODO: will be changed after refactor*/}
                        {type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ? <Modifications>Podbicia: <b>{data.numberOfRaises ?? 0}</b></Modifications> : null}
                    </ModificationsContainer>
                )}
            </PriceContainer>

            <BottomActions>
                {width <= 490 && !(type === 'auction' || type === 'tender') && (
                    <InformationContainer>
                        <InfoElement icon={IconEnum.BUILDING} isVisible={!!data.type}>
                            <b>{data.type}</b>
                        </InfoElement>
                        <InfoElement icon={IconEnum.WORK} isVisible={!!data.typeOfMarket}>
                            Rynek <b>{data.typeOfMarket}</b>
                        </InfoElement>
                        <InfoElement icon={IconEnum.SQUARE} isVisible={!!data.area}>
                            <b>{data.area}</b> m<sup>2</sup>
                        </InfoElement>
                        <InfoElement icon={IconEnum.BED} isVisible={!!data.numberOfRooms}>
                            <b>{data.numberOfRooms}</b> pokoje
                        </InfoElement>
                        <InfoElement icon={IconEnum.FLOOR_LEVEL} isVisible={!!data.floor}>
                            <b>{data.floor}</b> piętro
                        </InfoElement>
                        <InfoElement icon={IconEnum.TWO_TYPE} isVisible={!!data.buildingType}>
                            <b>{data.buildingType}</b>
                        </InfoElement>
                        <InfoElement icon={IconEnum.CALENDAR} isVisible={!!data.builtYear}>
                            <b>{data.builtYear}</b>
                        </InfoElement>
                    </InformationContainer>
                )}
                {width < 991 && type !== 'users' && (
                    <ModificationsContainer>
                        {/*@ts-expect-error TODO: will be changed after refactor*/}
                        <Modifications>Duplikaty: <b>{data.duplications?.length ?? data.numberOfDuplicates ? data.numberOfDuplicates : 0}</b></Modifications>
                        {/*@ts-expect-error TODO: will be changed after refactor*/}
                        <Modifications>Modyfikacje: <b>{data.modifications?.length ?? data.numberOfModifications ? data.numberOfModifications : 0}</b></Modifications>
                    </ModificationsContainer>
                )}
                <InteractiveIconsWrapper
                    type={type}
                    data={data}
                    onNoteAdd={onNoteAdd}
                />
            </BottomActions>
        </Wrapper>
    );
};

export default CardBodyDefault;
