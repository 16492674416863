import React, { FC, useMemo } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

const NotInteractiveIconsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    border-radius: 20px;
    background: var(--color-white);
    padding: 0 5px;
`;

const NotInteractiveIcon = styled.div`
    display: flex;
    padding: 10px;
    gap: 10px;
`;

export type NotInteractiveElement = {
    icon: IconEnum;
    text: string;
}

type NotInteractiveIconsProps = {
    notInteractiveElements: NotInteractiveElement[];
}

const NotInteractiveIcons: FC<NotInteractiveIconsProps> = ({ notInteractiveElements }) => {
    const memoizedNotInteractiveElements = useMemo(() => notInteractiveElements, [notInteractiveElements]);

    return (
        <NotInteractiveIconsWrapper>
            {memoizedNotInteractiveElements.map((element, index) => (
                <NotInteractiveIcon key={index}>
                    <Icon icon={element.icon}/>
                    {element.text}
                </NotInteractiveIcon>
            ))}
        </NotInteractiveIconsWrapper>
    );
};

export default NotInteractiveIcons;
