export const getPercatangeScorePrecision = (scorePrecision: number | null) => {
    switch (scorePrecision) {
    case 1:
        return '20%';
    case 2:
        return '35%';
    case 3:
        return '50%';
    case 4:
        return '65%';
    case 5:
        return '80%';
    case 6:
        return '100%';
    default:
        return null;
    }
};