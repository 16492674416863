import React, { FC, useEffect, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { RangeFilterType } from 'utils/types/InputTypes';

import AutocompleteInput from './AutocompleteInput/AutocompleteInput';

const Container = styled.div`
    display: grid;
    gap: 10px;
    row-gap: 5px;
    align-items: center;
    justify-content: flex-start;
    background: var(--color-alt-second);
    border-radius: var(--border-radius);
    height: 50px;
    grid-template-columns: 1fr auto 1fr;

    input {
        text-align: center;
    }
`;

const Spacer = styled.div`
    width: 8px;
    ${(props) => !props.customSpacer ? 'border-top: 2px solid var(--color-primary)' : '&:before {\n content: \'z\';\n }'};
`;

const ErrorWrapper = styled.p`
    --font-size-body: var(--font-size-body-4);
    
    color: var(--color-error);
    padding: 0 10px;
    grid-column: 1 / -1;
`;

type RangeSelectInputProperties = {
    value: RangeFilterType,
    onChange: (value: { from?: string, to?: string }) => void,
    onBlur?: (value: { from?: number, to?: number }) => void,
    disabled?: boolean,
    numeric?: boolean,
    placeholderFrom?: string,
    placeholderTo?: string,
    suffix?: string,
    options: { label: string, value: number | string }[]
    customOptionsSecondInput?: { label: string, value: number | string }[]
    inputType?: 'money';
    maxInputLength?: number;
    allowComma?: boolean;
    customSpacer?: string;
}

const RangeSelectInput: FC<RangeSelectInputProperties> = ({
    options,
    customOptionsSecondInput,
    placeholderTo,
    placeholderFrom,
    disabled,
    value,
    onChange,
    onBlur,
    numeric,
    suffix,
    inputType,
    maxInputLength,
    allowComma,
    customSpacer,
}) => {
    const { user, dispatch } = useUser();
    const [errorTo, setErrorTo] = useState(false);
    const [errorFrom, setErrorFrom] = useState(false);
    const [errorText, setErrorText] = useState('');
    const secondInputOptions = customOptionsSecondInput ?? options;

    useEffect(() => {
        if (!user.isInputError) {
            setErrorText('');
            setErrorTo(false);
            setErrorFrom(false);
        }
    }, [user.isInputError]);

    const handleStringToNumber = (value?: string) => {
        const formattedValue = value?.toString().replace(/\s/g, '').replace(',', '.');

        return formattedValue === '' || typeof value === 'undefined' || value === null ? undefined : Number(value.toString().replace(/\s/g, '').replace(',', '.'));
    };

    return (
        <Container>
            <AutocompleteInput
                enableCustomValue
                disabled={disabled}
                value={value.from}
                onChange={(valueIn) => onChange({ from: valueIn as string, to: value.to })}
                onBlur={(e) => {
                    const fromToNumber = handleStringToNumber(value.from);
                    const valueToNumber = handleStringToNumber(value.to);

                    if ((typeof value.from !== 'undefined' && typeof value.to !== 'undefined') && (fromToNumber && valueToNumber && fromToNumber > valueToNumber)) {
                        setErrorText(`Wartość musi być mniejsza niż ${value.to}`);
                        setErrorFrom(true);
                        setErrorTo(false);
                        UserActions.changeErrorVisibility(dispatch, true);
                    } else {
                        setErrorText('');
                        setErrorTo(false);
                        setErrorFrom(false);
                        UserActions.changeErrorVisibility(dispatch, false);
                    }

                    onBlur && onBlur({
                        from: handleStringToNumber((e.target as HTMLInputElement).value),
                        to: typeof value.to === 'number' ? value.to : handleStringToNumber(value.to ?? '')
                    });
                }}
                placeholder={placeholderFrom}
                type="text"
                options={options.filter((x) => !value.to || Number(x.value.toString().replace(/\s/g, '')) <= Number(value.to.toString().replace(/\s/g, '')))}
                noAction
                numeric={numeric}
                suffix={suffix}
                side="left"
                inputType={inputType}
                maxInputLength={maxInputLength}
                allowComma={allowComma}
                isError={errorFrom}
                allowNegative={false}
            />
            <Spacer customSpacer={customSpacer} />
            <AutocompleteInput
                enableCustomValue
                disabled={disabled}
                value={value.to}
                type="text"
                onChange={(valueIn) => onChange({ from: value.from, to: valueIn as string })}
                onBlur={(e) => {
                    const fromToNumber = handleStringToNumber(value.from);
                    const valueToNumber = handleStringToNumber(value.to);

                    if ((typeof value.from !== 'undefined' && typeof value.to !== 'undefined') && (valueToNumber && fromToNumber && valueToNumber < fromToNumber)) {
                        setErrorText(`Wartość musi być większa niż ${value.from}`);
                        setErrorTo(true);
                        setErrorFrom(false);
                        UserActions.changeErrorVisibility(dispatch, true);
                    } else {
                        setErrorText('');
                        setErrorTo(false);
                        setErrorFrom(false);
                        UserActions.changeErrorVisibility(dispatch, false);
                    }

                    onBlur && onBlur({
                        from: typeof value.from === 'number' ? value.from : handleStringToNumber(value.from ?? ''),
                        to: handleStringToNumber((e.target as HTMLInputElement).value)
                    });
                }}
                placeholder={placeholderTo}
                options={secondInputOptions.filter((x) => !value.from || Number(x.value.toString().replace(/\s/g, '')) >= Number(value.from.toString().replace(/\s/g, '')))}
                noAction
                numeric={numeric}
                suffix={suffix}
                side="right"
                inputType={inputType}
                maxInputLength={maxInputLength}
                allowComma={allowComma}
                isError={errorTo}
                allowNegative={false}
            />
            {errorFrom || errorTo ? <ErrorWrapper>{errorText}</ErrorWrapper> : null}
        </Container>
    );
};

export default RangeSelectInput;
