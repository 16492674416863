import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { getPercatangeScorePrecision } from 'utils/getPercatangeScorePrecision';

import InteractiveIcons from 'components/common/Card/common/InteractiveIcons';
import Icon, { IconEnum } from 'components/common/Icon';
import RatingSetter from 'components/common/RatingSetter/RatingSetter';
import RatingStars from 'components/common/RatingStars/RatingStars';
import { activeNotification } from 'components/functions/activeNotification';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

const Title = styled.h2`
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;

     @media (max-width: 761px) {
        flex-direction: column;
        align-items: flex-start;
     }
`;

type ActionPropType = {
    columnDisplayOnSmallScreens?: boolean
}
const Actions = styled.div<ActionPropType>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 10px;

    div {
        background: var(--color-alt-second);
    }

    .rating-setter {
        padding: 10px;
        border-radius: 50px;
    }

    ${({ columnDisplayOnSmallScreens }) =>
        columnDisplayOnSmallScreens &&
        `
        @media (max-width: 991px) {
            margin-bottom: 10px;
            flex-direction: column;
            margin-left: 0;
            align-items: flex-start;
        }

    `}
`;

const Input = styled.textarea`
    background: transparent;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color-primary);
    resize: none;
    height: auto;
`;

const InputIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(color-white);

    &.icon-in-editor {
        background-color: ${(props) => props.darkMode ? 'var(--color-white)' : 'inherit'};
    }

    &:hover {
        background: var(--color-alt);
        cursor: pointer;

        span {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: var(--color-alt-second);
    border-radius: var(--box-border-radius);
`;

const NoteContent = styled.p`
    --font-weight: 400;
`;

const OpinionHeader = styled.div`
    display: flex;
    gap: 30px;
    align-items: center;

    @media (max-width: 961px) {
      flex-direction: column;
      align-items: flex-start;
    }


`;

const CommunityRating = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        --font-weight: 400;

        font-size: 11px;
    }

    b {
        font-weight: 500;
    }

    img {
        width: 20px;
        height: 18px;
    }
`;

const ApplicationRating = styled.div`
    display: flex;
    align-items: center;
    gap: 28px;

    span {
        --font-weight: 400;

        font-size: 11px;
    }

    b {
        font-weight: 500;
    }

    .rating-setter {
        padding: 10px;
        border-radius: 50px;
        background: var(--color-alt-second);

    }
`;

const RatingInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const Divider = styled.div`
   @media (max-width: 991px) {
      display: none;
   }
`;

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media (max-width: 961px) {
      flex-direction: column;
      align-items: flex-start;
    }
`;

const RatingLabel = styled.span`
    --font-weight: 400;
    
    width: max-content;
    
    b {
        font-weight: 500;
    }
   `;

export type CommentType = {
    id: number,
    content: string,
    date: string,
    rating: number,
    isUserComment: boolean,
}

export type OpinionTabProps = {
    comments?: CommentType[];
    applicationScore: number | null;
    applicationScorePrecision: number | null;
    opinionsNumber: number;
    opinionsRating: number;
    commentsCount: number;
    handleSaveOpinion: (rating: number, content: string, id?: number) => Promise<void>;
    handleDeleteOpinion: (id: number) => void;
}

const OpinionTab: FC<OpinionTabProps> = ({
    comments,
    opinionsNumber,
    opinionsRating,
    commentsCount,
    handleSaveOpinion,
    handleDeleteOpinion,
    applicationScore,
    applicationScorePrecision
}) => {
    const { user } = useUser();
    const [value, setValue] = useState('');
    const [editedValue, setEditedValue] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [editModeId, setEditModeId] = useState<number>(0);
    const [userRating, setUserRating] = useState<number>(0);
    const [isUserAlreadyCommented, setIsUserAlreadyCommented] = useState(false);
    const scorePrecisionPercentage = getPercatangeScorePrecision(applicationScorePrecision);

    const inputRef = useRef<HTMLTextAreaElement>();
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [value, editedValue]);

    useEffect(() => {
        comments?.map((item) => {
            if (item.isUserComment) {
                setUserRating(item.rating);
                setIsUserAlreadyCommented(true);
            } else {
                setIsUserAlreadyCommented(false);
            }
        });
    }, [comments]);

    const onSave = () => {
        if (userRating === 0 && !value && !isEditMode) {
            activeNotification('Popraw dane', 'Ocena nie może być pusta, zaznacz odpowiednią ilość gwiazdek i/lub napisz opinię', 'warning');

            return;
        }

        if (isEditMode) {
            handleSaveOpinion(userRating, editedValue, editModeId);
            setEditMode(false);
        } else {
            handleSaveOpinion(userRating, value);
        }

        setValue('');
    };

    const handleEdit = (id: number) => {
        // jeśli dany komentarz nie istnieje, to nie pozwalamy na edycję, jeśli istnieje, to ustawiamy wartość
        comments?.find((item) => item.id === id && setEditedValue(item.content));
        setEditModeId(id);
        setEditMode(true);
    };

    const handleDelete = (id: number) => {
        handleDeleteOpinion(id);
        setUserRating(0);
        setTimeout(() => {
            setIsUserAlreadyCommented(false);
        }, 500);
    };

    const memoizedComments = useMemo(() => comments?.map((item) => (
        <BoxContainer key={item.id} data-notclickable={true}>
            <Title>
                <span>{dayjs(item.date).format('DD.MM.YYYY, HH:mm')}</span>
                <div>
                    <Actions>
                        <RatingSetter
                            isChangeable={item.isUserComment && isEditMode}
                            rating={item.isUserComment && isEditMode ? userRating : item.rating}
                            setRating={item.isUserComment ? setUserRating : undefined}
                        />
                        {item.isUserComment ? <InputIcon onClick={() => {
                            handleEdit(item.id!);
                            setUserRating(item.rating);
                        }}>
                            <Icon icon={IconEnum.EDIT}/>
                        </InputIcon> : null}
                        {item.isUserComment ? <InputIcon onClick={() => handleDelete(item.id!)}>
                            <Icon icon={IconEnum.DELETE}/>
                        </InputIcon> : null}
                    </Actions>
                </div>
            </Title>
            {isEditMode && editModeId === item.id ?
                <InputContainer>
                    <Input
                        rows={1}
                        placeholder={'Wpisz treść oceny...'}
                        value={editedValue}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEditedValue(e.target.value)}
                    />
                    <InputIcon onClick={onSave}>
                        <Icon icon={IconEnum.SEND}/>
                    </InputIcon>
                </InputContainer> :
                <NoteContent>
                    {item.content}
                </NoteContent>}
        </BoxContainer>
    )), [comments, editedValue, isEditMode, userRating]);

    const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const textarea = event.target;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;

        if (isEditMode) {
            setEditedValue(textarea.value);
        } else {
            setValue(textarea.value);
        }
    };

    return (
        <>
            <BoxContainer data-notclickable={true}>
                <OpinionHeader>
                    <h1>Opinie:</h1>
                    <CommunityRating>
                        <InteractiveIcons icon={IconEnum.STAR}/>
                        <RatingInfo>
                            <span><b>Ocena społeczności</b></span>
                            {!commentsCount && !opinionsRating ? <span>Dodaj pierwszą ocenę!</span> : null}
                            {commentsCount && !opinionsRating ? <span>Brak opinii</span> : null }
                            <span>
                                {opinionsRating > 0 && opinionsNumber > 0 ? <>
                                    <b>{opinionsRating}</b> na {opinionsNumber} opinii</> : null}
                            </span>
                            <span>
                                {commentsCount > 0 ? <>
                                    <b>{commentsCount}</b>  komentarz/y</> : null}
                            </span>
                        </RatingInfo>
                    </CommunityRating>
                    {applicationScore && scorePrecisionPercentage?
                        <div>
                            <ApplicationRating>
                                <Divider>|</Divider>
                                <RatingInfo>
                                    <span><b>Ocena aplikacji</b></span>
                                    <span>precyzja oceny <b>{scorePrecisionPercentage}</b></span>
                                </RatingInfo>
                                <RatingStars score={applicationScore}/>
                            </ApplicationRating>
                        </div> : null}
                </OpinionHeader>
            </BoxContainer>
            {memoizedComments}
            {!isUserAlreadyCommented ? <BoxContainer data-notclickable={true}>
                <TitleContainer>
                    <Title>Dodaj nową opinię</Title>
                    <Actions columnDisplayOnSmallScreens>
                        <RatingLabel><b>Jak oceniasz to ogłoszenie?</b> Wybierz ocenę:</RatingLabel>
                        <RatingSetter rating={userRating} setRating={setUserRating}/>
                    </Actions>
                </TitleContainer>
                <InputContainer>
                    <Input
                        rows={1}
                        placeholder={'Wpisz treść opinii...'}
                        value={value}
                        onChange={handleInput}
                    />
                    <InputIcon darkMode={user.isDarkMode} className={'icon-in-editor'} onClick={onSave}>
                        <Icon icon={IconEnum.SEND}/>
                    </InputIcon>
                </InputContainer>
            </BoxContainer> : null }
        </>
    );
};

export default OpinionTab;
