import { initialState as initialStateAddAdvert } from 'utils/state-managment/user/userAddAdvert';
import {
    BoxEditDto, BoxEditDtoModuleEnum, BoxInfoDto,
    CapitalDetailDto, CapitalEditDto, DealDetailDto,
    DealEditDto, InvestorDetailDto,
    InvestorEditDto,
} from 'utils/types/UserModels';
import { UserAdvertEditReturnData } from 'utils/types/UsersAdvert';

export enum MessageSenders {
    AD_OWNER = 'AD_OWNER',
    INTERESTED = 'INTERESTED'
}

export enum MessagesAdvertType {
    BUY = 'buy',
    SALE = 'sale',
}

export type NotesType = {
    noteId: number,
    ownerId: string,
    content: string,
    date: string,
    originalNote: boolean,
}

type UserOfferDetailsResponseDto = InvestorDetailDto & DealDetailDto & CapitalDetailDto;

type OfferResponseDto = BoxInfoDto;

type MyOfferDetailsResponseDto = InvestorEditDto & DealEditDto & CapitalEditDto;

export interface UserOffersResponseContent extends OfferResponseDto {
    detailedContent?: UserOfferDetailsResponseDto;
}
export interface UserProfileOffersResponseContent extends BoxInfoDto {
    detailedContent?: UserOfferDetailsResponseDto;
}
export interface UserMyOffersResponseContent extends BoxEditDto {
    detailedContent?: MyOfferDetailsResponseDto;
}

interface OffersResponse {
    last: boolean;
    empty: boolean,
    pageable: {
        pageNumber: number,
        pageSize: number,
    },
    numberOfElements: number,
    totalElements?: number,
    totalPages: number,
    size: number,
}

export interface UserOffersResponse extends OffersResponse {
    content: UserOffersResponseContent[],
}

export interface UserProfileOffersResponse extends OffersResponse {
    content: UserProfileOffersResponseContent[];
}

export interface UserMyOffersResponse extends OffersResponse {
    content: UserMyOffersResponseContent[],
}

export type Profile = {
    email: string,
    name: string,
    phoneNumber: string,
    description: string,
    userPhoto: string,
}

type LastUserMessages = {
    lastMessageId: string,
    name: string,
    content: string,
    sendDate: string,
    messageSender: MessageSenders,
    newMessage: boolean,
    photo?: string,
}

export type UserMessages = {
    content: string,
    messageId: string,
    messageSender: MessageSenders,
    readTime: string | null,
    sendDate: string
}

export type UserMessageListResponse = {
    title: string,
    price: number | null,
    messages: LastUserMessages[],
    module: BoxEditDtoModuleEnum,
    mainPhoto: string | null,
    photo?: string,
    advertisementId: string,
}

export type State = {
    isLoading: boolean,
    isLogged: boolean,
    isLogged2: boolean,
    activePackages: string[],
    user: object,
    token: string,
    refreshToken: string,
    activePackages2: string[],
    user2: object,
    token2: string,
    refreshToken2: string,
    isDarkMode: boolean,
    isInputError: boolean,
    usersOfferList: UserOffersResponse,
    myOfferList: UserMyOffersResponse,
    notes?: NotesType[],
    profileOfferList: UserProfileOffersResponse,
    offerToEdit: UserAdvertEditReturnData,
    profile: Profile;
    userMessageList: UserMessageListResponse[],
}

export const initialState: State = {
    isLoading: false,
    isLogged: false,
    isLogged2: false,
    activePackages: [],
    profile: {
        email: '',
        name: '',
        phoneNumber: '',
        description: '',
        userPhoto: '',
    },
    user: {},
    token: '',
    refreshToken: '',
    activePackages2: [],
    user2: {},
    token2: '',
    refreshToken2: '',
    usersOfferList: {
        empty: false,
        content: [],
        pageable: {
            pageNumber: 0,
            pageSize: 0,
        },
        numberOfElements: 0,
        totalPages: 0,
        size: 25,
        last: false
    },
    myOfferList: {
        empty: false,
        last: false,
        content: [],
        pageable: {
            pageNumber: 0,
            pageSize: 0,
        },
        numberOfElements: 0,
        totalPages: 0,
        size: 25,
    },
    profileOfferList: {
        last: false,
        empty: false,
        content: [],
        pageable: {
            pageNumber: 0,
            pageSize: 0,
        },
        numberOfElements: 0,
        totalPages: 0,
        size: 25,
    },
    // @ts-expect-error TODO: INVEST-237
    offerToEdit: { advertisementId: 0, ...initialStateAddAdvert.initialState() },
    userMessageList: [],
    isDarkMode: localStorage.getItem('darkTheme') === 'true',
    isInputError: false,
};
