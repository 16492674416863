export enum ActiveFilterEnum {
    ADVERTISEMENT = 'advertisement',
    RENT_FLAT = 'RENT_FLAT',
    RENT_HOUSE = 'RENT_HOUSE',
    RENT_PLOT = 'RENT_PLOT',
    RENT_OTHER = 'RENT_OTHER',
    TENDER = 'tender',
    AUCTION = 'auction',
    COOPERATIVE = 'cooperative',
    BAILIFF_NOTICE = 'bailiff_notice'
  }