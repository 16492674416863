import dayjs from 'dayjs';
import { ModulesType } from 'utils/types/ModulesType';

import { setStateValues } from 'components/functions/advertisementsFuncs';

const filterInitialState = setStateValues({
    filterId: null,
    name: null,
    numberOfRoomsFrom: '',
    numberOfRoomsTo: '',
    floorFrom: '',
    floorTo: '',
    offerFrom: null,
    unwantedKeywords: [],
    wantedKeywords: [],
    // @ts-expect-error TODO: need to specify what type should be here
    city: null,
    areaFrom: '',
    areaTo: '',
    typeOfMarket: null,
    // @ts-expect-error TODO: need to specify what type should be here
    dateFrom: null,
    dateTo: null,
    buildingType: [],
    groupDuplicates: true,
    priceFrom: '',
    priceTo: '',
    waivePriceTo: '',
    waivePriceFrom: '',
    rentPriceFrom: '',
    rentPriceTo: '',
    depositPriceFrom: '',
    depositPriceTo: '',
    pricePerSquareMeterFrom: '',
    pricePerSquareMeterTo: '',
    builtYearFrom: '',
    builtYearTo: '',
    type: ['Mieszkania'],
    includeZeroArea: true,
    alertSms: false,
    alertEmail: false,
    pushAlert: false,
    manyLocations: false,
    dateRangeFilter: false,
    daysAmount: 3,
    pageToMonitor: '',
    tossedUp: true,
    // @ts-expect-error TODO: need to specify what type should be here
    notificationsDelay: null,
    savedFilter: [],
    boundaries: '15,35,50,70,100,#',
    // reportType:['wg przedziałów powierzchni', 'wg czasu', 'wg trendu'], // TODO: podmienić po naprawie raprtów wg trendu
    reportType:['wg przedziałów powierzchni', 'wg czasu'],
    timeRange: ['Tydzień'],
    filterPropertyType: '',
    fence: [],
    media: [],
});

export const initialState = {
    filterInitialState: (type: ModulesType) => {
        const baseState = filterInitialState;

        switch (type)
        {
        case 'tender':
            baseState.daysAmount = 30;
            baseState.date.from = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
            baseState.type = [];
            break;
        case 'auction':
        case 'analysis':
            baseState.daysAmount = 30;
            baseState.date.from = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
            break;
        case 'cooperative':
        case 'bailiff_notice':
            baseState.type = [];
            baseState.daysAmount = undefined;
            break;
        default:
            baseState.daysAmount = 3;
            baseState.date.from = dayjs().subtract(3, 'day').format('YYYY-MM-DD');
            baseState.type = ['Mieszkania'];
            break;
        }

        return baseState;
    },
};
