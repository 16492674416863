import React, { FC, useCallback, useContext } from 'react';
import clsx from 'clsx';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { offersApiRequests } from 'utils/api-requests/offers';
import RentContext from 'utils/context/RentContext';
import convertOffersListToCSV from 'utils/convertOffersListToCSV';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import getTitleByType from 'utils/getTitleByType';
import { getValueFromState } from 'utils/state-managment/rent/rentFilter';
import { ModulesType } from 'utils/types/ModulesType';

export const OffersHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 20px;
    padding: 0 0 24px;
    border-bottom: 1px solid #030D451A;
    flex-direction: row;

    @media (max-width: 1100px) {
        padding: 0 0 10px;
    }

    &.favorites-page {
        margin-right: 45px;
    }

    & > div {
        align-items: flex-start;
        display: flex;
        gap: 40px;

        &:last-child {
            align-self: flex-end;
        }

        a {
            display: block;

            @media (max-width: 1100px) {
                display: block;
                position: absolute;
                left: 40px;
                top: 20px;
                white-space: nowrap;
            }
        }
    }

    @media (max-width: 1100px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
`;

export const Title = styled.span`
    height: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0 0 15px;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;
    color: var(--color-primary);

    span {
        font-size: 20px;
        font-weight: 400;
        color: var(--color-primary);
    }
`;

const BaseClickableSpan = styled.span`
    margin-top: 20px;
    margin-left: 15px;
    font-weight: var(--font-weight);
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    cursor: pointer;

    @media (max-width: 1100px) {
        margin-top: 0;

        &:first-child {
            display: none;
        }
    }

    b {
        font-weight: 600;
    }

    &:hover {
        color: var(--color-alt);
    }
`;

export const BaseClickableLink = styled.a`
    margin-top: 20px;
    font-weight: var(--font-weight);
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
    cursor: pointer;
    text-decoration-line: underline;

    &:hover {
        color: var(--color-alt);
    }
`;

type HeaderSectionProperties = {
    type: string,
    analysisPrepared?: boolean,
    handlePDFClick?: () => void,
    activeFavoriteOfferType?: ModulesType,
}

const HeaderSection: FC<HeaderSectionProperties> = ({
    type,
    analysisPrepared,
    handlePDFClick,
    activeFavoriteOfferType,
}) => {
    const { offers, dispatch } = useOffers();
    const { user } = useUser();

    const {
        rentFiltersState,
    } = useContext(RentContext);
    const isTypeWithOffers = ['users', 'advertisement', 'tender', 'auction', 'rent', 'favorites'].includes(type);
    const handleCSVClick = useCallback(() => {
        convertOffersListToCSV(offers.list);
    }, [offers.list]);

    const handleCSVAPIFile = useCallback(() => {
        offersApiRequests.getOffersCSVFile(getValueFromState(rentFiltersState), getNormalizedPropertyType(rentFiltersState.module));
    }, [rentFiltersState]);

    const auctionOffersLength = offers.list.filter((el) => el.portal?.link.includes('licytacje.komornik.pl')).length;
    const tenderOffersLength = offers.list.filter((el) => !el.portal?.link.includes('licytacje.komornik.pl')).length;

    const getOffersNumber = (type: string) => {
        switch (type) {
        case 'rent':
            return offers.rentList.totalElements;
        case 'users':
            return user.usersOfferList.totalElements;
        case 'profile':
            return user.profileOfferList.totalElements;

        case 'favorites':
            switch (activeFavoriteOfferType) {
            case 'auction':
                return auctionOffersLength;
            case 'tender':
                return tenderOffersLength;
            case 'RENT_FLAT':
            case 'RENT_HOUSE':
            case 'RENT_PLOT':
            case 'RENT_OTHER':
                return offers.rentList.totalElements;
            default:
                return offers.list.length;
            }
        case 'auction':
            return auctionOffersLength;
        case 'tender':
            return tenderOffersLength;
        default:
            return offers.list.length;
        }
    };

    return (
        <>
            <OffersHeader className={clsx({ 'favorites-page': type === 'favorites' })}>
                <Title>{getTitleByType(type)} {(offers.list.length > 0 && !offers.list[0].noItems) || (!offers.rentList.empty && offers.rentList.content?.length > 0) ?
                    <span>({getOffersNumber(type)})</span> : (
                        <span>
                            {type === 'users' && user.usersOfferList.content?.length > 0 || type === 'profile' && user.profileOfferList.content?.length > 0 ? `(${getOffersNumber(type)})` : null}
                        </span>
                    )}
                </Title>
                {(offers.list?.length > 0 || offers.rentList.content?.length > 0 || analysisPrepared || offers.isTendeOrAuctionListEmpty) && type !== 'users' ?
                    <div>
                        {isTypeWithOffers && (
                            <>
                                <BaseClickableSpan className={'exel'}
                                    onClick={type !== 'rent' ? handleCSVClick : handleCSVAPIFile}>Pobierz
                                    plik <b>EXCEL</b></BaseClickableSpan>
                            </>
                        )}
                        {analysisPrepared &&
                            <BaseClickableSpan onClick={handlePDFClick}>Pobierz plik <b>PDF</b></BaseClickableSpan>}
                        {type !== 'favorites' ? <BaseClickableLink onClick={() => {
                            OffersActions.clearOffers(dispatch);
                            dispatch({ type: 'RESET_IS_TENDER_OR_AUCTION_LIST_EMPTY' });
                        }} href={'#filters'}>Wróć do
                            wyszukiwania</BaseClickableLink> : null}
                    </div> : null}
            </OffersHeader>
        </>
    );
};

export default HeaderSection;
