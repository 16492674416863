import React, { ChangeEvent, forwardRef, ReactNode, useState } from 'react';
import styled from 'styled-components';

import Tooltip from './Tooltip/Tooltip';
import Icon, { IconEnum } from './Icon';

const Container = styled.span`
    position: relative;
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding-bottom: 0;
    ${(props) => props.inputColumnPosition ? `grid-column: ${props.inputColumnPosition};` : ''};
`;

const InputTitle = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    color: var(--color-primary);
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-2);
    line-height: 0;

    @media (max-width: 1475px) {
        line-height: 1;
    }
`;

const Title = styled.div`
    font-size: var(--font-size-body-2);
    display: flex;
    align-items: center;
`;

const Action = styled.div`
    font-style: normal;
    font-weight: var(--font-weight);
    font-size: var(--font-size-body-3);
    line-height: 150%;
    text-decoration-line: underline;
    color: var(--color-primary-70);
    cursor: pointer;
    margin-left: auto;

    &:hover {
        color: var(--color-primary);
    }

    text-align: right;
`;

const ActionCheckbox = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    margin-left: auto;

    input:checked + label {
        color: var(--color-primary);
    }

    input[type="checkbox"] {
        --checkbox-color: var(--color-primary-70);

        width: 14px;
        height: 14px;
    }

    input[type="checkbox"]:checked::before {
        width: 2px;
        height: 5px;
    }

    input[type="checkbox"]:checked {
        --checkbox-color: var(--color-primary);
    }

    label {
        color: var(--color-primary-70);
        font-size: 12px;
        text-decoration-line: underline;
        text-align: right;
    }
`;

const HelperWrapper = styled.div`
    .icon {
        margin-left: 5px;
    }
`;

const Star = styled.span`
    color: var(--color-error);
    margin-left: 5px;
`;

const AdditionalWrapper = styled.span`
    margin-left: auto;
    font-size: var(--font-size-body-3);
    color: var(--color-primary-70);
    padding-left: 40px;
`;

type InputContainerProperties = {
    icon?: IconEnum,
    title: string,
    actionText?: string,
    helperText?: string,
    actionCheckbox?: boolean,
    onActionClick?: (e?: ChangeEvent<HTMLInputElement>) => void,
    onMouseEnter?: () => void,
    onMouseLeave?: () => void,
    children: ReactNode,
    checked?: boolean,
    className?: string,
    required?: boolean
    additionalText?: string;
    inputColumnPosition?: string;
}
const InputContainer = forwardRef<HTMLSpanElement, InputContainerProperties>(({
    children,
    title,
    icon,
    onActionClick,
    actionText,
    onMouseEnter,
    onMouseLeave,
    actionCheckbox,
    checked,
    helperText,
    className,
    required,
    additionalText,
    inputColumnPosition
}, ref) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [referenceElement, setReferenceElement] = useState(null);

    return (
        <Container inputColumnPosition={inputColumnPosition} className={className} ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <InputTitle>
                {icon && <Icon icon={icon}/>}
                <Title>
                    {title}
                    {helperText &&
                        (<HelperWrapper onMouseEnter={() => setShowTooltip(true)}
                            onMouseLeave={() => setShowTooltip(false)}
                            ref={setReferenceElement}>
                            <Icon icon={IconEnum.QUESTION_CIRCLE}/>
                            <Tooltip text={helperText} referenceElement={referenceElement} showTooltip={showTooltip}/>
                        </HelperWrapper>)
                    }
                    {
                        required && <Star>*</Star>
                    }
                </Title>
                {additionalText && <AdditionalWrapper>{additionalText}</AdditionalWrapper>}
                {actionText && !actionCheckbox && <Action onClick={onActionClick}>{actionText}</Action>}
                {actionText && actionCheckbox &&
                    <ActionCheckbox>
                        <input onChange={onActionClick}
                            checked={checked}
                            type="checkbox"
                            id={actionText.replace(/\s+/g, '')}/>
                        <label htmlFor={actionText.replace(/\s+/g, '')}>{actionText}</label>
                    </ActionCheckbox>}
            </InputTitle>
            {children}
        </Container>
    );
});

export default InputContainer;
