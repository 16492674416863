import qs from 'qs';
import { axiosApiInstance2 } from 'Store';
import { getNormalizedSaleModuleName } from 'utils/formatters/getNormalizedSaleModuleName';
import { AdvertisementAnalysis, SaleModule } from 'utils/types/AnalysisModels';
import { AnalysisSaleFilterReturnData } from 'utils/types/AnalysisSale';

import { activeNotification } from 'components/functions/activeNotification';

const getAnalysis = (module: SaleModule, filters: AnalysisSaleFilterReturnData): Promise<AdvertisementAnalysis> => {
    return axiosApiInstance2({
        method: 'POST',
        url: `/analysis/sale/${getNormalizedSaleModuleName(module)}`,
        data: {
            ...filters,
            // priceFrom: filters.priceFrom ? Number((filters.priceFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            // priceTo: filters.priceTo ? Number((filters.priceTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            // areaFrom: filters.areaFrom ? Number(filters.areaFrom.toString().replace(',', '.')) : null,
            // areaTo: filters.areaTo ? Number(filters.areaTo.toString().replace(',', '.')) : null,
            // pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? Number((filters.pricePerSquareMeterFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            // pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? Number((filters.pricePerSquareMeterTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            // reportType: filters.reportType?.toString(),
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    })
        .then((resp) => {
            if (resp.data) {
                return {
                    ...resp.data
                    // filters: filters as AnalysisFiltersType,
                    // city,
                };
            }

            activeNotification(
                'Nie znaleziono wyników',
                'Zmień wartości wyszukiwania',
                'warning'
            );

            return {
                noItems: true,
                areaAnalysisList: [],
                timeAnalysisList: [],
                trendResult: [],
                // filters: filters as AnalysisFiltersType,
            };
        })
        .catch(() => {
            return {
                noItems: true,
                areaAnalysisList: [],
                timeAnalysisList: [],
                trendResult: [],
                // filters: filters as AnalysisFiltersType,
            };
        });
};

export const analysisApiRequests = {
    getAnalysis,
};
