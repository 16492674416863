import React, { FC } from 'react';
import styled from 'styled-components';
import { isNumber } from 'utils/validators/numbers';

const Wrapper = styled.div`
    display: flex;
    column-gap: 10px;
    margin-bottom: 20px;
    margin-top: 10px;

    @media (min-width: 1100px) {
        flex-direction: column;
        align-items: end;
        margin-top: auto;
        margin-bottom: unset;
    }
`;

const Modifications = styled.div`
    display: flex;
    gap: 5px;
    color: var(--color-primary);
    font-weight: 400;

    b {
        font-weight: 500;
    }
`;

export type ModificationsProps = {
    duplicationsNumber?: number;
    modificationsNumber: number;
    boostsNumber: number;
}

type ModificationsContainerProps = {
    modifications: ModificationsProps;
}

const ModificationsContainer: FC<ModificationsContainerProps> = ({ modifications }) => {
    return (
        <Wrapper role='group'>
            {isNumber(modifications.duplicationsNumber) ? (
                <Modifications role="listitem" aria-label="Duplikaty">
                    Duplikaty: <b>{modifications.duplicationsNumber}</b>
                </Modifications>
            ) : null}
            <Modifications role="listitem" aria-label="Modyfikacje">
                Modyfikacje: <b>{modifications.modificationsNumber}</b>
            </Modifications>
            <Modifications role="listitem" aria-label="Podbicia">
                Podbicia: <b>{modifications.boostsNumber}</b>
            </Modifications>
        </Wrapper>
    );
};

export default ModificationsContainer;
