import React, { useMemo, useState } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import { DotsList, HeaderStyle, SectionBox } from 'components/analysis/AnalysisSaleList';
import PieWrapper from 'components/common/PieWrapper';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-items: center;

  @media (max-width: 756px) {
    grid-template-columns: 1fr;
  }
`;

const List = styled.ul`
  align-self: center;
  padding: 10px;

  li {
    margin-right: 0;
  }

  b {
    font-weight: 400;
    -webkit-text-stroke: 0.4px currentColor;
  }
`;

export const TimeRangePie = ({ transactionRangesData }) => {
    const { user } = useUser();
    const rgbColor = user.isDarkMode ? '242, 245, 249' : '2, 85, 170';
    const [activePieName, setActivePieName] = useState('');
    const pieRatio = 1 / transactionRangesData.length;
    const list = useMemo(() => transactionRangesData.map((entry, index) => {
        const opacity = 1 - (index * pieRatio);

        return <DotsList fill={`rgba(${rgbColor}, ${opacity})`} key={entry.name}>

            <span>{entry.name.replace('-', ' - ')}</span> • {activePieName === entry.name ?
                <b>{new Intl.NumberFormat().format(entry.value)} ofert</b> :
                <>
                    {new Intl.NumberFormat().format(entry.value)} ofert
                </>}
        </DotsList>;
    }), [transactionRangesData, pieRatio, rgbColor, activePieName]);

    return (
        <SectionBox>
            <HeaderStyle>
                <h2>Liczba ofert według czasu</h2>
                <Grid>
                    <List>
                        {list}
                    </List>
                    <PieWrapper pieData={transactionRangesData} handleActivePie={(name) => setActivePieName(name)}/>
                </Grid>
            </HeaderStyle>
        </SectionBox>
    );
};
