import React, { FC } from 'react';

type DotProps = {
    slideIndex: number;
    currentIndex: number;
};

const Dot: FC<DotProps> = ({ slideIndex, currentIndex }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_602_13126)">
                <circle cx="10" cy="10" r={slideIndex === currentIndex ? '5' : '4'} stroke="white"
                    fill={slideIndex === currentIndex ? 'white' : 'none'} strokeWidth="2"
                    shapeRendering="geometricPrecision"/>
            </g>
            <defs>
                <filter id="filter0_d_602_13126" x="0" y="0" width="20" height="20"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"/>
                    <feOffset/>
                    <feGaussianBlur stdDeviation="2.5"/>
                    <feComposite in2="hardAlpha" operator="out"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix"
                        result="effect1_dropShadow_602_13126"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_602_13126"
                        result="shape"/>
                </filter>
            </defs>
        </svg>
    );
};

export default Dot;
