import dayjs from 'dayjs';
import { getArrayFilters, getRangeFilters } from 'utils/generateActiveFiltersContent';
import { getNameFromModule } from 'utils/getNameFromModule';
import { RentFiltersStateType, RentModuleType, RentOfferRequestData } from 'utils/types/Rent';
import { SortType } from 'utils/types/SortType';

import { FilterItem } from 'components/common/V2ActiveFilters';
import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setStateValues = (state: RentOfferRequestData): RentFiltersStateType => {
    const dateFromWithDefault = state.offerAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        ...state,
        city: state.city ?? [],
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        wantedPhoneNumbers: state.wantedPhoneNumbers ?? [],
        unwantedPhoneNumbers: state.unwantedPhoneNumbers ?? [],
        numberOfRooms: {
            from: state.numberOfRoomsFrom ?? null,
            to: state.numberOfRoomsTo ?? null,
        },
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.offerAddedTo && state.offerAddedTo.includes('T') ? state.offerAddedTo.split('T')[0] : state.offerAddedTo,
        },
        floor: {
            from: state.floorFrom ?? '',
            to: state.floorTo ?? '',
        },
        floors: {
            from: state.floorsFrom ?? '',
            to: state.floorsTo ?? '',
        },
        area: {
            from: state.areaFrom ?? '',
            to: state.areaTo ?? '',
        },
        pricePerSquareMeter: {
            from: state.pricePerSquareMeterFrom ?? '',
            to: state.pricePerSquareMeterTo ?? '',
        },
        buildingType: state.buildingType ?? [],
        price: {
            from: state.priceFrom ?? '',
            to: state.priceTo ?? '',
        },
        depositPrice: {
            from: state.depositPriceFrom ?? '',
            to: state.depositPriceTo ?? '',
        },
        rentPrice: {
            from: state.rentPriceFrom ?? '',
            to: state.rentPriceTo ?? '',
        },
        builtYear: {
            from: state.builtYearFrom ?? '',
            to: state.builtYearTo ?? '',
        },
        savedFilter: state.savedFilter ?? [],
        daysAmount: state.daysAmount ?? 3,
        dateRangeFilter: !!state.offerAddedTo,
    };
};

export const getValueFromState = ( state: RentFiltersStateType ): RentOfferRequestData => {
    const dataToReturn = {
        ...state,
        city: state.city ? state.city : [],
        offerAddedTo: state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : null,
        offerAddedFrom: dateFormat(dateToFunc(state.offerAdded.from)),
        areaFrom: state.area.from,
        areaTo: state.area.to,
        priceFrom: state.price.from,
        priceTo: state.price.to,
        depositPriceFrom: state.depositPrice.from,
        depositPriceTo: state.depositPrice.to,
        rentPriceFrom: state.rentPrice.from,
        rentPriceTo: state.rentPrice.to,
        pricePerSquareMeterFrom: state.pricePerSquareMeter.from,
        pricePerSquareMeterTo: state.pricePerSquareMeter.to,
        numberOfRoomsFrom: state.numberOfRooms.from,
        numberOfRoomsTo: state.numberOfRooms.to,
        builtYearFrom: state.builtYear.from,
        builtYearTo: state.builtYear.to,
        buildingType:
            state.buildingType?.length > 0
                ? state.buildingType
                : [],
        floorFrom: state.floor.from,
        floorTo: state.floor.to,
        floorsFrom: state.floors.from,
        floorsTo: state.floors.to,
        typeOfMarket: state.typeOfMarket?.length > 0 ? state.typeOfMarket : [],
        savedFilter: undefined,
    };

    return dataToReturn;
};

const rentFilterInitialState = setStateValues({
    id: null,
    name: null,
    numberOfRoomsFrom: '', // flats, houses
    numberOfRoomsTo: '', // flats, houses
    floorFrom: '', // flats, houses
    floorTo: '', // flats, houses
    floorsFrom: '', // flats
    floorsTo: '', // flats
    unwantedKeywords: [],
    wantedKeywords: [],
    unwantedPhoneNumbers: [],
    wantedPhoneNumbers: [],
    city: [],
    locations: [],
    offerFrom: [],
    savedLocations: {},
    searchLocationResult: [],
    areaFrom: '',
    areaTo: '',
    typeOfMarket: [], // flats, other, houses
    buildingType: [], // flats, houses
    priceFrom: '',
    priceTo: '',
    rentPriceFrom: '',
    rentPriceTo: '',
    pricePerSquareMeterFrom: '',
    pricePerSquareMeterTo: '',
    builtYearFrom: '', // flats, houses, other
    builtYearTo: '', // flats, houses, other
    alertSms: false,
    alertEmail: false,
    pushAlert: false,
    savedFilter: [],
    module: RentModuleType.RENT_FLAT,
    tossedUp: true,
    notificationsDelay: '0',
    includeZeroArea: true,
    groupDuplicate: true,
    depositPriceFrom: '',
    depositPriceTo: '',
    offerAddedFrom: '',
    offerAddedTo: '',
    modification: true,
    daysAmount: 3,
    sortType: SortType.DATE_DESC,
    dateRangeFilter: false,
});

export const initialState = {
    filterInitialState: () => {
        return rentFilterInitialState;
    },
};

export const getRentFiltersFromState = (state: RentFiltersStateType): FilterItem[] => {
    return [
        ...getArrayFilters('Lokalizacja', Object.values(state.savedLocations).map((x) => x.fullName), 'savedLocations', true),
        ...getRangeFilters('Cena', state.price, 'price', 'zł'),
        ...getRangeFilters('Metraż', state.area, 'area', 'm2'),
        ...getRangeFilters('Liczba pokoi', state.numberOfRooms, 'numberOfRooms'),
        ...getRangeFilters('Piętro', state.floor, 'floor'),
        ...getRangeFilters('Ilość pięter', state.floors, 'floors'),
        ...getRangeFilters('Rok budowy', state.builtYear, 'builtYear'),
        ...getRangeFilters('Cena za m²', state.pricePerSquareMeter, 'pricePerSquareMeter'),
        ...getRangeFilters('Czynsz', state.rentPrice, 'rentPrice', 'zł'),
        ...getRangeFilters('Kaucja', state.depositPrice, 'depositPrice', 'zł'),
        ...getArrayFilters('Typ budynku', state.buildingType, 'buildingType'),
        ...getArrayFilters('Oferta od', state.offerFrom, 'offerFrom'),
        ...getArrayFilters('Rynek', state.typeOfMarket, 'typeOfMarket'),
        ...getArrayFilters('Słowa pożądane', state.wantedKeywords, 'wantedKeywords'),
        ...getArrayFilters('Słowa niepożądane', state.unwantedKeywords, 'unwantedKeywords'),
        ...getArrayFilters('Numery pożądane', state.wantedPhoneNumbers, 'wantedPhoneNumbers'),
        ...getArrayFilters('Numery niepożądane', state.unwantedPhoneNumbers, 'unwantedPhoneNumbers'),
        { label: 'Typ nieruchomości', value: getNameFromModule(state.module), index: 0, noDeleteButton: true },
        ...(state.daysAmount && !state.dateRangeFilter ? [{ label: 'Ogłoszenia od', value: state.daysAmount === -1 ? 'wszystkie dni' : `${state.daysAmount} dni`, noDeleteButton: true }] : []),
        ...(state.dateRangeFilter ? [
            { label: 'Data od', value: state.offerAdded.from, noDeleteButton: true },
            { label: 'Data do', value: state.offerAdded.to, filterName: 'offerAdded' }
        ] : [])
    ];
};
