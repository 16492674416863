import React, { useContext, useMemo, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { userApiRequests } from 'utils/api-requests/user';
import UserFiltersContext from 'utils/context/UserFiltersContext';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import {
    areaOptions,
    investmentTimeOptions, investPriceOptions,
    percentageOptions,
    rentPriceOptions,
    userFloorOptions,
    v2offerTypeOptions,
} from 'utils/options';
import { getValueFromState } from 'utils/state-managment/user/userFilter';
import { InputType, LocationChangeType, ObjectAndSingleValueChangeType } from 'utils/types/InputTypes';
import {
    BooleanEnum,
    BuildingTypeEnum, CapitalBaseDtoFormOfInvestmentEnum, CapitalBaseDtoInvestmentSecurityEnum,
    DealBaseDtoFeaturesEnum, DealBaseDtoLegalStatusEnum,
    FilterEditCapitalDtoFormOfProfitEnum,
    InvestorBaseDtoInvestmentSecurityEnum, InvestorBaseDtoLegalStatusEnum,
    PropertyConditionEnum
} from 'utils/types/UserModels';
import { UsersFiltersStateType, V2OfferType } from 'utils/types/UsersAdvert';

import Button from 'components/atom/Button';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer, { ElementsToRender } from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';

const FiltersUsers = () => {
    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);
    const { user, dispatch: dispatchUser } = useUser();
    const {
        handleChangeOfferType,
        handleChange,
        offerType,
        clearFilters,
        handleDeleteFilter,
        handleSetFiltersValues,
        usersAdvertsFiltersState,
        handleChangeDateList,
        getSavedFilters
    } = useContext(UserFiltersContext);
    const defaultRangeState = { from: '', to: '' };

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, usersAdvertsFiltersState.locations);

        handleChange('locations', newLocations || []);
    };

    const resetFilters = () => {
        clearFilters(true);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = usersAdvertsFiltersState.savedLocations;

        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const handleSaveOrUpdateFilter = async () => {
        if (usersAdvertsFiltersState.city.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');

            return;
        }

        if (usersAdvertsFiltersState.offerType.length <= 0) {
            activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawny rodzaj oferty', 'warning');

            return;
        }

        const existFilter = usersAdvertsFiltersState.savedFilter?.find(
            (filter) => filter.name === usersAdvertsFiltersState.name
        );

        let id;

        if (existFilter && !usersAdvertsFiltersState.filterId) {
            activeNotification('Nazwa filtra', 'Istnieje już filtr o takiej nazwie', 'warning');

            return;
        }

        if (existFilter) {
            await userApiRequests.updateFilter({
                ...getValueFromState(usersAdvertsFiltersState),
            }, usersAdvertsFiltersState.offerType);
        } else {
            const resp = await userApiRequests.addFilter({
                ...getValueFromState(usersAdvertsFiltersState)
            }, usersAdvertsFiltersState.offerType);

            id = resp?.data;
        }

        getSavedFilters(id);
    };

    const handleChangeNotificationAlert = async (valueName: string, id: number) => {
        await userApiRequests.updateFilter({ ...getValueFromState({ ...usersAdvertsFiltersState, [valueName]: !(usersAdvertsFiltersState[valueName as keyof UsersFiltersStateType]) }) }, offerType);
        getSavedFilters(id);
    };

    const handleSubmit = async () => {

        if (!getValueFromState(usersAdvertsFiltersState).offerType) {
            const filteringNotPossible = !getValueFromState(usersAdvertsFiltersState).offerType
                && (usersAdvertsFiltersState.city.length > 0
                    || (usersAdvertsFiltersState.wantedKeywords && usersAdvertsFiltersState.wantedKeywords.length > 0)
                    || (usersAdvertsFiltersState.unwantedKeywords && usersAdvertsFiltersState.unwantedKeywords.length > 0)
                    || (usersAdvertsFiltersState.wantedPhoneNumbers && usersAdvertsFiltersState.wantedPhoneNumbers.length > 0)
                    || (usersAdvertsFiltersState.unwantedPhoneNumbers && usersAdvertsFiltersState.unwantedPhoneNumbers.length > 0)
                    || (usersAdvertsFiltersState.daysAmount && usersAdvertsFiltersState.daysAmount > -1)
                    || (usersAdvertsFiltersState.dateRangeFilter
                        && (usersAdvertsFiltersState.dateAdded && (usersAdvertsFiltersState.dateAdded.from || usersAdvertsFiltersState.dateAdded.to))));

            if (filteringNotPossible) {
                activeNotification('Popraw wpisane dane', 'Wybierz rodzaj oferty, aby przefiltrować oferty', 'warning');

                return;
            }

            await UserActions.loadUserOffersAsync(dispatchUser, 1, 25);
        } else {
            await UserActions.loadUserOffersAsync(dispatchUser, 1, 25, getValueFromState(usersAdvertsFiltersState), usersAdvertsFiltersState.offerType);
        }
        document.body.scrollTo(0, 0);
    };

    const handleMultipleFieldsChange = (fieldName: string[], value: ObjectAndSingleValueChangeType) => {
        handleChange(fieldName[0], (value as { from: string }).from);
        handleChange(fieldName[1], (value as { to: string }).to);
    };

    const usersFiltersStructure = useMemo<ElementsToRender[]>(() => [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues,
                            },
                            modulesProps: {
                                filterId: usersAdvertsFiltersState.filterId,
                                options: usersAdvertsFiltersState.savedFilter.map((x) => ({
                                    ...x,
                                    id: x.filterId,
                                }
                                )),
                            },
                        }
                    },
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: usersAdvertsFiltersState.locations,
                                searchLocationResult: usersAdvertsFiltersState.searchLocationResult,
                                savedLocations: usersAdvertsFiltersState.savedLocations,
                                selectAllCheckbox: true,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city',
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange,
                            }
                        }
                    }
                },
                {
                    title: 'Data dodania',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !usersAdvertsFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: usersAdvertsFiltersState.dateAdded!,
                                dateRangeFilter: usersAdvertsFiltersState.dateRangeFilter || false,
                                daysAmount: usersAdvertsFiltersState.daysAmount!,
                                fieldName: 'dateAdded',
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList,
                            }
                        }
                    }
                },
                {
                    title: 'Rodzaj oferty',
                    type: InputType.AUTOCOMPLETE,
                    icon: IconEnum.IN_TYPE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleChange: (_, value) => handleChangeOfferType(value! as V2OfferType),
                            },
                            modulesProps: {
                                value: usersAdvertsFiltersState.offerType,
                                fieldName: 'offerType',
                                options: v2offerTypeOptions,
                            },
                        }
                    },
                },
            ]
        },
        ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR || usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL || usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
            accordionTitle: 'Parametry',
            hideTitle: true,
            alignToEnd: true,
            filters: [
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Wkład inwestora',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.investorContribution || defaultRangeState,
                                fieldName: 'investorContribution',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL ? [{
                    title: 'Kwota inwestycji',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.investmentValue || defaultRangeState,
                                fieldName: 'investmentValue',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Prognozowany zysk',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.expectedProfit || defaultRangeState,
                                fieldName: 'expectedProfit',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Cena',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.price || defaultRangeState,
                                fieldName: 'price',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Nakład na remont',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.renovationExpenses || defaultRangeState,
                                fieldName: 'renovationExpenses',
                                options: rentPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Kwota do realizacji inwestycji',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.investmentBudget || defaultRangeState,
                                fieldName: 'investmentBudget',
                                options: rentPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Szacowany zwrot (ROI)',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.roi || defaultRangeState,
                                fieldName: 'roi',
                                options: percentageOptions,
                                suffix: '%',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Procent inwestycji',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.percentageOfInvestment || defaultRangeState,
                                fieldName: 'percentageOfInvestment',
                                options: percentageOptions,
                                suffix: '%',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Odstępne',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.leaseFee || defaultRangeState,
                                fieldName: 'leaseFee',
                                options: rentPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Rodzaj nieruchomości',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                value: (usersAdvertsFiltersState.buildingType || []) as string[],
                                options: generateOptionsFromEnum(BuildingTypeEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                                fieldName: 'buildingType',
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Piętro',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.FLOOR_LEVEL,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.floor || defaultRangeState,
                                fieldName: 'floor',
                                options: userFloorOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Powierzchnia',
                    type: InputType.RANGE_METERS_SELECT,
                    icon: IconEnum.SQUARE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_METERS_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.area || defaultRangeState,
                                fieldName: 'area',
                                options: areaOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Dostępne udogodnienia',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.PEOPLE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: (usersAdvertsFiltersState.features || []) as string[],
                                options: generateOptionsFromEnum(DealBaseDtoFeaturesEnum),
                                optionsIcon: IconEnum.PEOPLE,
                                fieldName: 'features',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Podpisana umowa przedwstępna',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: (usersAdvertsFiltersState.preContract || []) as string[],
                                fieldName: 'preContract',
                                options: generateOptionsFromEnum(BooleanEnum),
                                optionsIcon: IconEnum.BOOKMARK,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Ekipa budowlana',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.OPTIONS,
                    placeholder: 'Wybierz...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: (usersAdvertsFiltersState.constructionCrew || []) as string[],
                                fieldName: 'constructionCrew',
                                options: generateOptionsFromEnum(BooleanEnum),
                                optionsIcon: IconEnum.OPTIONS,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Wkład własny',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.ownContribution || defaultRangeState,
                                fieldName: 'ownContribution',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Całkowita wartość inwestycji',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.totalInvestmentValue || defaultRangeState,
                                fieldName: 'totalInvestmentValue',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Cena sprzedaży',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: { from: usersAdvertsFiltersState.minimumSalePrice || '', to: usersAdvertsFiltersState.maximumSalePrice || '' },
                                fieldName: 'salePrice',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange: (_: string, value: ObjectAndSingleValueChangeType) => handleMultipleFieldsChange(['minimumSalePrice', 'maximumSalePrice'], value),
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL ? [{
                    title: 'Czas trwania inwestycji w miesiącach',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.BED,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: { from: usersAdvertsFiltersState.investmentTimeFrom || '', to: usersAdvertsFiltersState.investmentTimeTo || '' },
                                fieldName: 'investmentTime',
                                options: investmentTimeOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange: (_: string, value: ObjectAndSingleValueChangeType) => handleMultipleFieldsChange(['investmentTimeFrom', 'investmentTimeTo'], value),
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Kwota inwestycji',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.BED,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.investmentAmountFrom || defaultRangeState,
                                fieldName: 'investmentAmount',
                                options: investPriceOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Dostępne od',
                    type: InputType.DATE,
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    options: {
                        [InputType.DATE]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.availableFrom || '',
                                fieldName: 'availableFrom',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL ? [{
                    title: 'Przewidywany przychód z najmu',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.rentalIncome || defaultRangeState,
                                fieldName: 'rentalIncome',
                                options: rentPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Stan nieruchomości',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                fieldName: 'propertyCondition',
                                value: (usersAdvertsFiltersState.propertyCondition || []) as string[],
                                options: generateOptionsFromEnum(PropertyConditionEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Szacowana data sprzedaży od',
                    type: InputType.DATE,
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data sprzedaży od...',
                    options: {
                        [InputType.DATE]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.estimatedSaleDateFrom || '',
                                fieldName: 'estimatedSaleDateFrom',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Szacowana data sprzedaży do',
                    type: InputType.DATE,
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data sprzedaży do...',
                    options: {
                        [InputType.DATE]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.estimatedSaleDateTo || '',
                                fieldName: 'estimatedSaleDateTo',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL ? [{
                    title: 'Forma zysku',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                fieldName: 'formOfProfit',
                                value: (usersAdvertsFiltersState.formOfProfit || []) as string[],
                                options: generateOptionsFromEnum(FilterEditCapitalDtoFormOfProfitEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL ? [{
                    title: 'Kwota zysku',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.profitAmount || defaultRangeState,
                                fieldName: 'profitAmount',
                                options: investPriceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL ? [{
                    title: 'Oczekiwana stopa zwrotu',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.PRICE_PART,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.expectedReturnRate || defaultRangeState,
                                fieldName: 'expectedReturnRate',
                                options: percentageOptions,
                                maxInputLength: 3,
                                suffix: '%',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Zabezpieczenie',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                value: usersAdvertsFiltersState.investmentSecurity as unknown as string[] || [],
                                fieldName: 'investmentSecurity',
                                options: usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL ? generateOptionsFromEnum(CapitalBaseDtoInvestmentSecurityEnum) : generateOptionsFromEnum(InvestorBaseDtoInvestmentSecurityEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.CAPITAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Forma inwestycji',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                value: (usersAdvertsFiltersState.formOfInvestment || []) as string[],
                                fieldName: 'formOfInvestment',
                                options: generateOptionsFromEnum(CapitalBaseDtoFormOfInvestmentEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Stan prawny',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                value: (usersAdvertsFiltersState.legalStatus || []) as string[],
                                fieldName: 'legalStatus',
                                options: usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? generateOptionsFromEnum(InvestorBaseDtoLegalStatusEnum) : generateOptionsFromEnum(DealBaseDtoLegalStatusEnum),
                                optionsIcon: IconEnum.TWO_TYPE,
                            }
                        }
                    },
                }] : []),
                ...(usersAdvertsFiltersState.offerType === V2OfferType.DEAL || usersAdvertsFiltersState.offerType === V2OfferType.INVESTOR ? [{
                    title: 'Numer księgi wieczystej',
                    type: InputType.TEXT,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz numer...',
                    options: {
                        [InputType.TEXT]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                fieldName: 'landRegistryNumber',
                                value: usersAdvertsFiltersState.landRegistryNumber || '',
                            }
                        }
                    },
                }] : []),
            ]
        }] : []),
        {
            accordionTitle: 'Tagi',
            hideTitle: true,
            filters: [
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.wantedKeywords || [],
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.unwantedKeywords || [],
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Pożądane numery telefonów',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj numery telefonów, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.PHONE,
                    placeholder: 'Wpisz numer...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.wantedPhoneNumbers || [],
                                fieldName: 'wantedPhoneNumbers',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Numer telefonu musi mieć 9 znaków',
                                minLength: 9,
                                maxLength: 9,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length !== 9,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Niepożądane numery telefonów',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj numery telefonów, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.PHONE,
                    placeholder: 'Wpisz numer...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.unwantedPhoneNumbers || [],
                                fieldName: 'unwantedPhoneNumbers',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Numer telefonu musi mieć 9 znaków',
                                minLength: 9,
                                maxLength: 9,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length !== 9,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Zerowe lub puste wartości',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które nie spełniają parametrów filtra z powodu braku danych. Więcej w zakładce Pomoc',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.includeZeroArea || false,
                                fieldName: 'includeZeroArea',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: usersAdvertsFiltersState.filterId ? 'Aktualizuj' : undefined,
                    containerActionClick: handleSaveOrUpdateFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: usersAdvertsFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true,
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter,
                            }
                        }
                    },
                },
                {
                    title: 'Aktywacja powiadomień',
                    type: InputType.NOTIFICATIONS,
                    helperText: 'Przed aktywacją powiadomienia zapisz filtr',
                    icon: IconEnum.ALERT,
                    placeholder: '',
                    options: {
                        [InputType.NOTIFICATIONS]: {
                            contextFunctions: {
                                handleChange: handleChangeNotificationAlert,
                            },
                            modulesProps: {
                                alertEmail: usersAdvertsFiltersState.alertEmail || false,
                                alertSms: usersAdvertsFiltersState.alertSms || false,
                                pushAlert: usersAdvertsFiltersState.pushAlert || false,
                                filterId: usersAdvertsFiltersState.filterId,
                            },
                        }
                    },
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przeglądaj oferty...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit,
                            },
                            modulesProps: {
                                actionText: 'Resetuj',
                            },
                        }
                    },
                },
            ],
        }
    ], [usersAdvertsFiltersState, usersAdvertsFiltersState.offerType]);

    return (
        <>
            <V2FiltersRenderer elementsToRender={usersFiltersStructure} type={'users'} bottomActions={
                <StickyBottom
                    isVisible={isFilterButtonVisible}
                    active={user.usersOfferList.content?.length > 0 || user.usersOfferList.empty}
                    className={'sticky-filter-button'}>
                    <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                        actionText={'Resetuj'}
                        onActionClick={resetFilters}>
                        <Button onClick={handleSubmit}>
                            {'Przeglądaj oferty...'}
                        </Button>
                    </InputContainer>
                </StickyBottom>
            }/>
        </>
    );
};

export default FiltersUsers;
