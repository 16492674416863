import styled from 'styled-components';

const StickyBottom = styled.div<StickyBottomProperties>`
    position: sticky;
    display: ${({ isVisible, active }) => isVisible || !active ? 'none' : 'block'};
    bottom: 0;
    border-radius: 0 0 10px 10px;
    background: var(--color-white);
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.07);
    padding: 20px 15px;

    @media (max-width: 1100px) {
        display: ${({ isVisible }) => isVisible ? 'none' : 'block'};
    }
`;

type StickyBottomProperties = {
    isVisible: boolean;
    active: boolean;
}

export default StickyBottom;