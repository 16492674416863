import React, { FC } from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const Badge = styled.span`
    position: absolute;
    font-size: var(--font-size-body-4);
    top: -12px;
    left: 15px;
    background-color: var(--color-error);
    color: white;
    padding: 2px 5px;
    border-radius: 5px;
    z-index: var(--z-index-nav-badge);
`;

type NavElementProperties = {
  title: string;
  icon: IconEnum;
  isNew?: boolean;
}

const NavElement: FC<NavElementProperties> = ({ title, icon, isNew }) => {
    return (
        <div className='nav-element'>
            {isNew ? <Badge>NEW!</Badge> : null}
            <Icon icon={icon}/>
            <span className='nav-title'>{title}</span>
        </div>
    );
};

export default NavElement;
