import React, { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import dayjs from 'dayjs';
import styled from 'styled-components';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { useWindowWidth } from 'utils/useWindowWidth';

import { IconEnum } from 'components/common/Icon';
import Tabs from 'components/common/Tabs/Tabs';

import InfoBadge from './common/InfoBadge';
import InteractiveIcons from './common/InteractiveIcons';
import InteractiveIconsWrapper from './common/InteractiveIconsWrapper';
import { InfoBoxContainer } from './BaseCard';

export const BoxContainer = styled.div`
    background-color: var(--color-white);
    border-radius: var(--box-border-radius);
    position: relative;
    margin-bottom: 15px;
    height: 270px;
    width: 100%;
    background: var(--color-background);

    h1 {
        padding: 10px 15px;
    }
`;

export const InfoBoxContainerOverride = styled(InfoBoxContainer)`
    position: unset;
    padding: 0;
`;

export const ImgOverlay = styled.div`
    position: absolute;
    top: 0;
    padding: 15px 65px 0 25px;
    width: 100%;
    left: 0;
    display: grid;
    grid-template-columns: 30% 70%;

    .user-offer-details-modal{
        position: absolute;
        top: 15px;
        right: 65px;
    }
    
    .icons-modal {
        align-self: flex-start;
    }
`;

export const HeaderImg = styled.img`
    height: 270px;
    width: 100%;
    object-fit: cover;
    border-radius: 6px;
`;

type OfferDetailsProperties = {
    type: ModulesType,
    data: OfferData,
    onToggleContainerDetails: () => void,
    preferredTab?: string,
    isUsersDetails?: boolean,
}

const OfferDetails: FC<OfferDetailsProperties> = ({
    preferredTab,
    type,
    data,
    onToggleContainerDetails,
    isUsersDetails,
}) => {
    const width = useWindowWidth();
    const [offerDate, setOfferDate] = useState(data.dateAdded || data.dateAddedToDataBase);
    // @ts-expect-error will be changed after refactoring
    const [rentOfferDate, setRentOfferDate] = useState(data.lastUpdated || data.dateAdded);
    // @ts-expect-error will be changed after refactoring
    const [isSuccess, setIsSuccess] = useState(type !== 'rent' ? !data.tooltipDate : !data.lastUpdated);
    const [tabIndex, setTabIndex] = useState(1);
    const navigate = useNavigate();

    const handleBadgeChange = (e: Event) => {
        // @ts-expect-error will be changed after refactoring
        if ((data.tooltipDate || data.lastUpdated) && width <= 991) {
            e.stopPropagation();
            // @ts-expect-error will be changed after refactoring
            offerDate === data.dateAdded ? setOfferDate(data.tooltipDate || data.lastUpdated) : setOfferDate(data.dateAdded);
            setIsSuccess((prev) => !prev);
        }
    };

    const onMouseLeave = useCallback(() => {
        if (data.tooltipDate && width > 991 && type !== 'rent') {
            setTimeout(() => {
                setOfferDate(data.dateAdded);
                setIsSuccess(false);
            }, 150);
        }

        // @ts-expect-error will be changed after refactoring
        if (data.lastUpdated && width > 991 && type === 'rent') {
            setTimeout(() => {
                // @ts-expect-error will be changed after refactoring
                setRentOfferDate(data.lastUpdated);
                setIsSuccess(false);
            }, 150);
        }
        // @ts-expect-error will be changed after refactoring
    }, [data.dateAdded, data.tooltipDate, data.lastUpdated, width]);

    const onMouseEnter = useCallback(() => {
        if (data.tooltipDate && width > 991 && type !== 'rent') {
            setTimeout(() => {
                setOfferDate(data.tooltipDate);
                setIsSuccess(true);
            }, 150);
        }

        // @ts-expect-error will be changed after refactoring
        if (data.lastUpdated && width > 991 && type === 'rent') {
            setTimeout(() => {
                setRentOfferDate(data.dateAdded);
                setIsSuccess(true);
            }, 150);
        }
        // @ts-expect-error will be changed after refactoring
    }, [data.dateAdded, width, data.lastUpdated]);

    useEffect(() => {
        switch (preferredTab) {
        case 'note':
            setTabIndex(4);
            break;
        default:
            setTabIndex(1);
        }
    }, [preferredTab]);

    const goToProfile = () => {
        if (type === 'users')
            navigate(`/profile/${data.profileId}`);
    };

    return (
        <>
            <BoxContainer>
                <HeaderImg src={data.thumbnailPath}/>
                <ImgOverlay>
                    <InfoBoxContainerOverride>
                        <InfoBadge onClick={handleBadgeChange}
                            onMouseLeave={onMouseLeave}
                            onMouseEnter={onMouseEnter}
                            // @ts-expect-error will be changed after refactoring
                            isSuccess={isSuccess} isWarning={!isSuccess} showInfoIcon={data.tooltipDate || data.lastUpdated}>
                            {type !== 'rent' ? dayjs(offerDate || data.dateAddedToDataBase).format('DD.MM.YYYY, HH:mm') : dayjs(rentOfferDate).format('DD.MM.YYYY, HH:mm')}
                        </InfoBadge>
                        {data.offerFrom && <InfoBadge isWhite>
                            {data.offerFrom}
                        </InfoBadge>}
                    </InfoBoxContainerOverride>
                    {width >= 991 ? <InteractiveIconsWrapper
                        type={type}
                        className={clsx('icons-modal', { 'user-offer-details-modal': type === 'users' || type === 'profile' })}
                        data={data}
                        onNoteAdd={() => setTabIndex(4)}
                        bottomTooltip
                        isUsersDetails={isUsersDetails}
                    /> : null}
                    {type === 'users' || type === 'my_offer' || type === 'profile' ? <InteractiveIcons onClick={goToProfile} text={<p>{data.userName}</p>} isModalOpen isUserNameBtn={type === 'users' || type === 'profile'} isLink
                        icon={IconEnum.USER} /> : null}
                </ImgOverlay>
            </BoxContainer>
            <Tabs type={type} data={data} toggleContainerDetails={onToggleContainerDetails} tabIndex={tabIndex}/>
        </>
    );
};

export default OfferDetails;
