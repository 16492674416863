import React, { useContext, useMemo, useState } from 'react';
import SaleContext from 'utils/context/SaleContext';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import {
    areaOptions,
    buildYearOptions,
    plotAreaOptions,
    priceOptions,
    pricePerSquareMeterSaleOptions,
    roomOptions, salePropertyTypeOptions,
    scheduleOptions,
    userFloorOptions, userFloorsOptions
} from 'utils/options';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';
import {
    FlatBuildingTypeEnum,
    HouseBuildingTypeEnum,
    Module,
    OfferFromEnum,
    OtherBuildingTypeEnum, PlotBuildingTypeEnum, TypeOfMarketEnum
} from 'utils/types/SaleModels';

import Button from 'components/atom/Button';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer, {
    ElementsToRender,
} from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import { activeNotification } from 'components/functions/activeNotification';
import { getVoivodeshipCities } from 'components/functions/locations';

const FiltersSale = () => {
    const {
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        clearFilters,
        saleFiltersState,
        propertyType,
        handleChangeDateList,
        handleChangePropertyType,
        saleOfferState,
        loadSaleOffers,
        handleSaveOrUpdateFilter,
        handleChangeNotificationAlert
    } = useContext(SaleContext);

    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);
    const buildingOptions = useMemo(() => {
        if (saleFiltersState.module === Module.SALE_FLAT) return generateOptionsFromEnum(FlatBuildingTypeEnum);
        if (saleFiltersState.module === Module.SALE_HOUSE) return generateOptionsFromEnum(HouseBuildingTypeEnum);
        if (saleFiltersState.module === Module.SALE_OTHER) return generateOptionsFromEnum(OtherBuildingTypeEnum);
        if (saleFiltersState.module === Module.SALE_PLOT) return generateOptionsFromEnum(PlotBuildingTypeEnum);

        return [];
    }, [saleFiltersState.module]);

    const resetFilters = () => {
        clearFilters(true);
    };

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, saleFiltersState.locations);
        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = saleFiltersState.savedLocations;
        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const handleSubmit = () => {
        if (!saleFiltersState.city || !saleFiltersState.city.length) activeNotification('Popraw wpisane dane', 'Musisz wybrać poprawną miejscowość', 'warning');
        else {
            loadSaleOffers();
            document.body.scrollTo(0, 0);
        }
    };

    const saleFiltersStructure = useMemo<ElementsToRender[]>(() => [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues,
                            },
                            modulesProps: {
                                filterId: saleFiltersState.id ?? null,
                                options: saleFiltersState.savedFilter,
                            },
                        }
                    },
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: saleFiltersState.locations,
                                searchLocationResult: saleFiltersState.searchLocationResult,
                                savedLocations: saleFiltersState.savedLocations,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city',
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange,
                            }
                        }
                    }
                },
                {
                    title: 'Data',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !saleFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: saleFiltersState.offerAdded,
                                dateRangeFilter: saleFiltersState.dateRangeFilter,
                                daysAmount: saleFiltersState.daysAmount,
                                fieldName: 'offerAdded',
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList,
                            }
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Typ nieruchomości',
                    type: InputType.AUTOCOMPLETE_PROPERTY,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.AUTOCOMPLETE_PROPERTY]: {
                            contextFunctions: {
                                handleChangePropertyType,
                                handleChange,
                            },
                            modulesProps: {
                                value: saleFiltersState.module,
                                options: salePropertyTypeOptions,
                            }
                        }
                    },
                },
                {
                    title: 'Cena',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.price,
                                fieldName: 'price',
                                options: priceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Cena za metr',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE_PART,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.pricePerSquareMeter,
                                fieldName: 'pricePerSquareMeter',
                                options: pricePerSquareMeterSaleOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Metraż',
                    type: InputType.RANGE_METERS_SELECT,
                    icon: IconEnum.SQUARE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_METERS_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.area,
                                fieldName: 'area',
                                options: saleFiltersState.module === Module.SALE_PLOT ? plotAreaOptions : areaOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Typ ogłoszeniodawcy',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.PEOPLE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.offerFrom,
                                options: generateOptionsFromEnum(OfferFromEnum),
                                optionsIcon: IconEnum.PEOPLE,
                                fieldName: 'offerFrom',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                ...(saleFiltersState.module === Module.SALE_FLAT || saleFiltersState.module === Module.SALE_HOUSE ? [{
                    title: 'Pokoje',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: 'bed' as IconEnum,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.numberOfRooms,
                                fieldName: 'numberOfRooms',
                                options: roomOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(saleFiltersState.module === Module.SALE_FLAT || saleFiltersState.module === Module.SALE_OTHER || saleFiltersState.module === Module.SALE_HOUSE ? [{
                    title: 'Piętro',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.FLOOR_LEVEL,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.floor,
                                fieldName: 'floor',
                                options: userFloorOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(saleFiltersState.module === Module.SALE_FLAT ? [{
                    title: 'Ilość pięter',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.FLOOR_LEVEL,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.floors,
                                fieldName: 'floors',
                                options: userFloorsOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(saleFiltersState.module === Module.SALE_FLAT || saleFiltersState.module === Module.SALE_HOUSE || saleFiltersState.module === Module.SALE_OTHER ? [{
                    title: 'Rok budowy',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.CALENDAR,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.builtYear,
                                fieldName: 'builtYear',
                                options: buildYearOptions,
                                maxInputLength: 4,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                {
                    title: saleFiltersState.module === Module.SALE_PLOT ? 'Typ działki' : 'Typ budynku',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.TWO_TYPE,
                    placeholder: 'Wybierz rodzaj...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.buildingType,
                                options: buildingOptions,
                                optionsIcon: IconEnum.TWO_TYPE,
                                fieldName: 'buildingType',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                ...(saleFiltersState.module === Module.SALE_FLAT || saleFiltersState.module === Module.SALE_HOUSE || saleFiltersState.module === Module.SALE_OTHER ? [{
                    title: 'Rodzaj rynku',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.WORK,
                    placeholder: 'Wybierz rodzaj rynku...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: saleFiltersState.typeOfMarket,
                                options: generateOptionsFromEnum(TypeOfMarketEnum),
                                optionsIcon: IconEnum.COG,
                                fieldName: 'typeOfMarket',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
            ]
        },
        {
            accordionTitle: 'Tagi',
            hideTitle: true,
            filters: [
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: saleFiltersState.wantedKeywords,
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: saleFiltersState.unwantedKeywords,
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Pożądane numery telefonów',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj numery telefonów, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.PHONE,
                    placeholder: 'Wpisz numer...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: saleFiltersState.wantedPhoneNumbers,
                                fieldName: 'wantedPhoneNumbers',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Numer telefonu musi mieć 9 znaków',
                                minLength: 9,
                                maxLength: 9,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length !== 9,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Niepożądane numery telefonów',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj numery telefonów, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.PHONE,
                    placeholder: 'Wpisz numer...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: saleFiltersState.unwantedPhoneNumbers,
                                fieldName: 'unwantedPhoneNumbers',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Numer telefonu musi mieć 9 znaków',
                                minLength: 9,
                                maxLength: 9,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length !== 9,
                                handleChange,
                            },
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Ustawienia',
            hideTitle: true,
            filters: [
                {
                    title: 'Grupowanie duplikatów',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy te same ogłoszenia mają zostać połączone.',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: saleFiltersState.groupDuplicate,
                                fieldName: 'groupDuplicate',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Zerowe lub puste wartości',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które nie spełniają parametrów filtra z powodu braku danych. Więcej w zakładce Pomoc',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: saleFiltersState.includeZeroArea,
                                fieldName: 'includeZeroArea',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Dodatkowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Harmonogram',
                    type: InputType.AUTOCOMPLETE,
                    helperText: 'Co jaki okres chcesz otrzymywać powiadomienia? Jeżeli wybierzesz "Natychmiast" to każda oferta zostanie wysłana w osobnym mailu. w pozostałych przypadkach otrzymasz jednego maila np. co 5 min z listą ogłoszeń, które pojawiły się w tym czasie.',
                    icon: IconEnum.TIMER,
                    placeholder: 'Wybierz godziny...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleChange,
                            },
                            modulesProps: {
                                value: saleFiltersState.notificationsDelay,
                                fieldName: 'notificationsDelay',
                                options: scheduleOptions,
                            },
                        }
                    },
                },
                {
                    title: 'Powiadomienia o modyfikacjach',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które zostały zmodyfikowane.',
                    icon: IconEnum.AUDIO,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: saleFiltersState.modification,
                                fieldName: 'modification',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Powiadomienia o podbiciach',
                    type: InputType.RADIO,
                    helperText: 'Czy chcesz, abyśmy wysyłali Ci powiadomienia jeżeli ogłoszenie zostanie "podbite" przez sprzedającego (znajdzie się znów na pierwszej stronie portalu)?',
                    icon: IconEnum.AUDIO,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: saleFiltersState.tossedUp,
                                fieldName: 'tossedUp',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            lastElement: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: saleFiltersState.id ? 'Aktualizuj' : undefined,
                    containerActionClick: handleSaveOrUpdateFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: saleFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true,
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter,
                            }
                        }
                    },
                },
                {
                    title: 'Aktywacja powiadomień',
                    type: InputType.NOTIFICATIONS,
                    helperText: 'Przed aktywacją powiadomienia zapisz filtr',
                    icon: IconEnum.ALERT,
                    placeholder: '',
                    options: {
                        [InputType.NOTIFICATIONS]: {
                            contextFunctions: {
                                handleChange: handleChangeNotificationAlert,
                            },
                            modulesProps: {
                                alertEmail: saleFiltersState.alertEmail,
                                alertSms: saleFiltersState.alertSms,
                                pushAlert: saleFiltersState.pushAlert,
                                filterId: saleFiltersState.id ?? null,
                            },
                        }
                    },
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przeglądaj oferty...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit,
                            },
                            modulesProps: {
                                actionText: 'Resetuj',
                            },
                        }
                    },
                },
            ],
        }
    ], [saleFiltersState, propertyType]);

    return (
        <V2FiltersRenderer elementsToRender={saleFiltersStructure} type={'rent'} bottomActions={
            <StickyBottom
                isVisible={isFilterButtonVisible}
                active={saleOfferState && saleOfferState.saleOfferList && saleOfferState.saleOfferList.content?.length > 0 || saleOfferState.saleOfferList.empty && saleOfferState.saleOfferList.afterSearch}
                className={'sticky-filter-button'}>
                <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                    actionText={'Resetuj'}
                    onActionClick={resetFilters}>
                    <Button onClick={handleSubmit}>
                        {'Przeglądaj oferty...'}
                    </Button>
                </InputContainer>
            </StickyBottom>
        }/>
    );
};

export default FiltersSale;
