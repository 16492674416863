import { FC, Fragment, useMemo } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import { formatFilterPrice } from 'utils/formatFilterPrice';

import Icon, { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

import { DetailedInfo, DetailedInfoContainer, DetailsDescription, Grid, Value } from './DetailsTab';

type V2SpecificModificationsProps = {
    field: string;
    newValue?: string;
    oldValue?: string;
}

type V2ModificationsProps = {
    date: string;
    modificationDetail?: V2SpecificModificationsProps[]
}

export type V2ModificationsTabProps = {
    modifications: V2ModificationsProps[];
}

const V2ModificationsTab: FC<V2ModificationsTabProps> = ({ modifications }) => {
    return (
        <>
            {modifications.map((mod, index: number) => {
                const memoizedMod = useMemo(() => mod, [mod]);

                return (
                    <BoxContainer key={index}>
                        <h2>{dayjs(mod.date).format('DD.MM.YYYY, HH:mm')}</h2>
                        {memoizedMod.modificationDetail?.map((mod, indexIn: number) => {
                            return (
                                <Fragment key={indexIn}>
                                    {(() => {
                                        switch (mod.field) {
                                        case 'Cena':
                                            return (
                                                <div key={indexIn}>
                                                    <h1>Cena</h1>
                                                    <Grid>
                                                        <DetailedInfoContainer>
                                                            <Icon icon={IconEnum.PRICE}/>
                                                            <DetailedInfo>
                                                                Cena po aktualizacji:
                                                                <Value>{mod.newValue && mod.newValue !== 'Brak danych' ? formatFilterPrice(mod.newValue.replace('zł', ' złotych')) : 'Brak informacji'}</Value>
                                                            </DetailedInfo>
                                                        </DetailedInfoContainer>
                                                        <DetailedInfoContainer>
                                                            <Icon icon={IconEnum.PRICE}/>
                                                            <DetailedInfo>
                                                                Pierwotna cena:
                                                                <Value>{mod.oldValue && mod.oldValue !== 'Brak danych' ? formatFilterPrice(mod.oldValue.replace('zł', ' złotych')) : 'Brak informacji'}</Value>
                                                            </DetailedInfo>
                                                        </DetailedInfoContainer>
                                                    </Grid>
                                                </div>
                                            );
                                        case 'Opis':
                                            return (
                                                <div key={indexIn}>
                                                    <h1>{mod.field}</h1>
                                                    <DetailsDescription>{mod.oldValue}</DetailsDescription>
                                                    <h2>zmieniono na:</h2>
                                                    <DetailsDescription><b>{mod.newValue}</b></DetailsDescription>
                                                </div>
                                            );
                                        default:
                                            return (
                                                <div key={indexIn}>
                                                    <h1>{mod.field}</h1>
                                                    <DetailsDescription>{mod.oldValue} zmieniono na: <b>{mod.newValue}</b></DetailsDescription>
                                                </div>
                                            );
                                        }
                                    })()}
                                </Fragment>
                            );
                        })}
                    </BoxContainer>
                );
            })}
        </>
    );
};

export default V2ModificationsTab;
