import React, { FC } from 'react';
import styled from 'styled-components';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import NoteBox from 'components/common/NoteBox';

import CardBodyMini from './common/CardBodyMini';

const Container = styled.div`
    display: grid;
    width: 100%;
    border-radius: var(--box-border-radius);
    background-color: var(--color-white);
    padding: 15px;
    gap: 22px;
    grid-template-columns: auto;
    grid-template-rows: 1fr;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        transition: box-shadow 0.3s ease-in-out;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    }
`;

type MiniCardProperties = {
    data: OfferData;
    onClick: (event?: Event) => void;
    type: ModulesType;
    openOfferTab: (tab: string) => void;
    showLastNote?: () => void;
    showNote?: boolean;
}

const MiniCard: FC<MiniCardProperties> = ({
    data,
    onClick,
    type,
    openOfferTab,
    showNote,
    showLastNote,
}) => {
    return (
        <Container onClick={onClick}>
            <CardBodyMini
                data={data}
                type={type}
                openOfferTab={openOfferTab}
                showLastNote={showLastNote}
            />
            {showNote ? (
                <NoteBox newestNote={data.note} openOfferTab={openOfferTab}/>
            ) : null}
        </Container>
    );
};

export default MiniCard;
