import { FC, Fragment } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import Icon, { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

import { DetailedInfo, DetailedInfoContainer, DetailsDescription, Grid, Value } from './DetailsTab';

type ModificationsTabProps = {
    data: OfferData;
    type: ModulesType
}

type ModificationsProps = {
    field: string;
    value: string;
    actualPrice?: string;
}

type V2SpecificModificationsProps = {
    field: string;
    newValue: string;
    oldValue: string;
}

type V2ModificationsProps = {
    date: string;
    modificationDetail: V2SpecificModificationsProps[]
}

const ModificationsTab: FC<ModificationsTabProps> = ({ data, type }) => {
    let fieldPriceIndexes: number[] = [];

    data.modifications?.forEach((item, index) => {
        item.modifications?.forEach((mod: ModificationsProps) => {
            if (mod.field === 'Cena') {
                fieldPriceIndexes = [...fieldPriceIndexes, index];
            }
        });
    });

    const newModifications = data.modifications?.map((item, index) => {
        if (fieldPriceIndexes.includes(index)) {
            return {
                date: item.date,
                modifications: item.modifications?.map((mod: ModificationsProps) => {
                    if (mod.field === 'Cena') {
                        let actualPrice = data.price || data.originalPrice;

                        if (typeof fieldPriceIndexes[fieldPriceIndexes.indexOf(index) + 1] !== 'undefined') {
                            actualPrice = data.modifications[fieldPriceIndexes[fieldPriceIndexes.indexOf(index) + 1]].modifications.filter((mod: ModificationsProps) => mod.field === 'Cena')[0].value;
                        }

                        return {
                            ...mod,
                            actualPrice,
                        };
                    } else {
                        return mod;
                    }
                })
            };
        } else {
            return item;
        }
    });

    return (
        type !== 'rent' && type !== 'RENT_FLAT' && type !== 'RENT_PLOT' && type !== 'RENT_HOUSE' && type !== 'RENT_OTHER' ? newModifications.map((item, index) => (
            <BoxContainer>
                <h2>{dayjs(item.date).format('DD.MM.YYYY, HH:mm')}</h2>
                {item.modifications.map((mod: ModificationsProps, indexIn: number) => {
                    const modPrice = formatFilterPrice(mod.value.replace('zł', ''));

                    return (
                        <>
                            {(() => {
                                switch (mod.field) {
                                case 'Cena':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>{mod.field}</h1>
                                            <Grid>
                                                <DetailedInfoContainer>
                                                    <Icon icon={IconEnum.PRICE}/>
                                                    <DetailedInfo>
                                                                Cena po aktualizacji:
                                                        <Value>{formatFilterPrice(mod.actualPrice!.replace('zł', ''))} złotych</Value>
                                                    </DetailedInfo>
                                                </DetailedInfoContainer>
                                                <DetailedInfoContainer>
                                                    <Icon icon={IconEnum.PRICE}/>
                                                    <DetailedInfo>
                                                                Pierwotna cena:
                                                        <Value>{modPrice === 'NaN' ? 'Brak danych' : `${modPrice} złotych`}</Value>
                                                    </DetailedInfo>
                                                </DetailedInfoContainer>
                                            </Grid>
                                        </div>
                                    );
                                case 'Opis':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>Szczegóły ogłoszenia</h1>
                                            <DetailsDescription>{mod.value}</DetailsDescription>
                                        </div>
                                    );
                                case 'Tytuł':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>Tytuł</h1>
                                            <DetailsDescription>{mod.value}</DetailsDescription>
                                        </div>
                                    );
                                case 'Metraż':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>Metraż</h1>
                                            <DetailsDescription>{mod.value}</DetailsDescription>
                                        </div>
                                    );
                                case 'Piętro':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>Piętro</h1>
                                            <DetailsDescription>{mod.value}</DetailsDescription>
                                        </div>
                                    );
                                case 'Liczba pokoi':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>Liczba pokoi</h1>
                                            <DetailsDescription>{mod.value}</DetailsDescription>
                                        </div>
                                    );
                                case 'Typ budynku':
                                    return (
                                        <div key={`${index}-${indexIn}`}>
                                            <h1>Typ budynku</h1>
                                            <DetailsDescription>{mod.value}</DetailsDescription>
                                        </div>
                                    );
                                }
                            })()}
                        </>
                    );
                })}
            </BoxContainer>
        )) :
            // @ts-expect-error TODO: fix after refactor
            data.modifications.map((mod: V2ModificationsProps, index: number) => {
                return (
                    <BoxContainer key={index}>
                        <h2>{dayjs(mod.date).format('DD.MM.YYYY, HH:mm')}</h2>
                        {mod.modificationDetail.map((mod: V2SpecificModificationsProps, indexIn: number) => {
                            return (
                                <Fragment key={indexIn}>
                                    {(() => {
                                        switch (mod.field) {
                                        case 'Cena':
                                            return (
                                                <div key={indexIn}>
                                                    <h1>Cena</h1>
                                                    <Grid>
                                                        <DetailedInfoContainer>
                                                            <Icon icon={IconEnum.PRICE}/>
                                                            <DetailedInfo>
                                                                Cena po aktualizacji:
                                                                <Value>{formatFilterPrice(mod.newValue.replace('zł', ''))} złotych</Value>
                                                            </DetailedInfo>
                                                        </DetailedInfoContainer>
                                                        <DetailedInfoContainer>
                                                            <Icon icon={IconEnum.PRICE}/>
                                                            <DetailedInfo>
                                                                Pierwotna cena:
                                                                <Value>{formatFilterPrice(mod.oldValue.replace('zł', ''))} złotych</Value>
                                                            </DetailedInfo>
                                                        </DetailedInfoContainer>
                                                    </Grid>
                                                </div>
                                            );
                                        case 'Opis':
                                            return (
                                                <div key={indexIn}>
                                                    <h1>{mod.field}</h1>
                                                    <DetailsDescription>{mod.oldValue}</DetailsDescription>
                                                    <h2>zmieniono na:</h2>
                                                    <DetailsDescription><b>{mod.newValue}</b></DetailsDescription>
                                                </div>
                                            );
                                        default:
                                            return (
                                                <div key={indexIn}>
                                                    <h1>{mod.field}</h1>
                                                    <DetailsDescription>{mod.oldValue} zmieniono na: <b>{mod.newValue}</b></DetailsDescription>
                                                </div>
                                            );
                                        }
                                    })()}
                                </Fragment>
                            );
                        })}
                    </BoxContainer>
                );
            })
    );
};

export default ModificationsTab;
