import React, { FC, ReactNode,useEffect, useRef } from 'react';
import clsx from 'clsx';

type StickyBottomChildItemProperties = {
    children: ReactNode;
    className?: string;
    onVisibleChange: (visible: boolean) => void;
}

const StickyBottomChildItem: FC<StickyBottomChildItemProperties> = ({ children, className, onVisibleChange }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const viewportHeight = window.innerHeight;

    useEffect(() => {
        const observerCallback = (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;

            if (entry.isIntersecting) {
                onVisibleChange(true);
            } else if (!entry.isIntersecting) {
                const elementTopDistance = (elementRef.current)?.parentElement?.getBoundingClientRect().top;
                const elementHeight = (elementRef.current)?.parentElement?.getBoundingClientRect().height;

                if (elementTopDistance && elementHeight && !(elementTopDistance + elementHeight < viewportHeight)) {
                    onVisibleChange(false);
                }
            }
        };

        const observerOptions = {
            root: null,
            threshold: 1,
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        if (elementRef.current) {
            observer.observe(elementRef.current);
        }

        return () => {
            if (elementRef.current) {
                observer.unobserve(elementRef.current);
            }
        };
    }, [onVisibleChange, viewportHeight]);

    return (
        <div className={clsx(className, 'sticky-bottom-wrapper')} ref={elementRef}>
            <div className={clsx('sticky-element-wrapper')}>{children}</div>
        </div>
    );
};

export default StickyBottomChildItem;
