import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { RentModuleType } from 'utils/types/Rent';
import { useWindowWidth } from 'utils/useWindowWidth';

import InteractiveIconsWrapper from 'components/common/Card/common/InteractiveIconsWrapper';
import { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

import Tab from './common/Tab';
import TabPanel from './common/TabPanel';
import AdditionalTab from './tabs/AdditionalTab';
import DetailsTab from './tabs/DetailsTab';
import DuplicatesTab from './tabs/DuplicatesTab';
import ModificationsTab from './tabs/ModificationsTab';
import NoteTab from './tabs/NoteTab';

type TabsProperties = {
    type: ModulesType;
    data: OfferData;
    toggleContainerDetails: () => void;
    tabIndex?: number;
}

const TabWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 10px;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--color-alt-second-10) var(--color-background);
    scroll-behavior: smooth;
    padding: 10px 0;
    margin: 0 20px;

    ::-webkit-scrollbar-thumb {
        background-color: var(--color-primary);
        border: none;
    }
    
    ::-webkit-scrollbar {
        height: 4px;
    }

    @media (min-width: 1100px) {
        justify-content: flex-end;
        overflow: hidden;
    }

    & > button:first-child {
        width: fit-content;
        margin-right: auto;
        @media (max-width: 1100px) {
            margin-right: unset;
            width: unset;
        }
    }
    
    & > button:last-child {
        margin-right: 10px;
    }
`;

const Badger = styled.span`
    color: var(--color-white);
    background-color: var(--color-primary);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0;
    right: -15px;
`;

const Tabs: FC<TabsProperties> = ({ type, data, toggleContainerDetails, tabIndex }) => {
    const [activeTabIndex, setActiveTabIndex] = useState(1);
    const { dispatch } = useOffers();
    const { dispatch: dispatchUser } = useUser();
    const tabWrapperRef = useRef<HTMLDivElement>(null);
    const width = useWindowWidth();

    useEffect(() => {
        if (tabIndex)
            setActiveTabIndex(tabIndex);
    }, [tabIndex]);

    useEffect(() => {
        if (type === 'my_offer' || type === 'users' || type === 'profile') {
            // @ts-expect-error TODO: we need to adapt this component to new modules
            UserActions.loadNotesAsync(dispatchUser, (data.adType || getNormalizedPropertyType(data.module)), data.advertisementId);
        } else {
            if (type === 'rent') {
                OffersActions.loadOldNotesAsync(dispatch, type, data.advertisementId, data.city || data.reaction.city || data.note.city || '', data.module as RentModuleType);
            } else if ( type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                OffersActions.loadOldNotesAsync(dispatch, type, data.advertisementId, data.city || data.note.city || '', type.replaceAll("'",'') as RentModuleType);
            } else {
                OffersActions.loadOldNotesAsync(dispatch, type, data.id, data?.city || data.reaction.city || data.note?.city || '');
            }
        }
    }, [data?.city, data.id, data.reaction?.city, data.note?.city, dispatch, type]);

    const getNoteCount = useCallback(() => {
        const count = data.numberOfNotes ?? 0;

        return count > 0 ? <Badger>{count}</Badger> : null;
    }, [data.numberOfNotes])();

    const handleDuplicateClick = () => {
        if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
            OffersActions.loadDuplicates(dispatch, data.module as RentModuleType, data.advertisementId).then(() => {
                setActiveTabIndex(2);
            });
        } else {
            setActiveTabIndex(2);
        }
    };

    return (<>
        {width < 991 ?
            <BoxContainer>
                <InteractiveIconsWrapper
                    type={type}
                    className="icons-modal"
                    data={data}
                    onNoteAdd={() => setActiveTabIndex(4)}
                />
            </BoxContainer> : null}
        <BoxContainer className="swipeable">
            <TabWrapper ref={tabWrapperRef}>
                {width > 991 ?
                    <Tab onClick={toggleContainerDetails} icon={IconEnum.LEAVE} label={'Wróć do wyszukiwania'}/> : null}
                <Tab onClick={() => setActiveTabIndex(1)} isActive={1 === activeTabIndex} label={'Szczegóły'}/>
                {type !== 'cooperative' && type !== 'auction' && type !== 'bailiff_notice' && type !== 'my_offer' && type !== 'users' && type !== 'profile' ?
                    // @ts-expect-error TODO: we need to adapt this component to new modules
                    type === 'rent' ? <Tab disabled={data.numberOfDuplicates === null || data.numberOfDuplicates === 0} onClick={handleDuplicateClick} isActive={2 === activeTabIndex} label={'Duplikaty'}>
                        {/*@ts-expect-error TODO: we need to adapt this component to new modules*/}
                        {data.numberOfDuplicates ? <Badger>{data.numberOfDuplicates}</Badger> : null}
                    </Tab> :
                        <Tab disabled={data.duplications?.length === 0}
                            onClick={handleDuplicateClick}
                            isActive={2 === activeTabIndex} label={'Duplikaty'}>{data.duplications?.length > 0 ?
                                <Badger>{data.duplications?.length}</Badger> : null}
                        </Tab> : null}
                {type !== 'cooperative' && type !== 'auction' && type !== 'bailiff_notice' && type !== 'my_offer' && type !== 'users' && type !== 'profile' ?
                    // @ts-expect-error TODO: we need to adapt this component to new modules
                    type === 'rent' ? <Tab disabled={data.numberOfModifications === null || data.numberOfModifications === 0} onClick={() => setActiveTabIndex(3)} isActive={3 === activeTabIndex} label={'Modyfikacje'}>
                        {/*@ts-expect-error TODO: we need to adapt this component to new modules*/}
                        {data.numberOfModifications ? <Badger>{data.numberOfModifications}</Badger> : null}
                    </Tab> :
                        <Tab disabled={data.modifications?.length === 0}
                            onClick={() => setActiveTabIndex(3)}
                            isActive={3 === activeTabIndex} label={'Modyfikacje'}>{data.modifications?.length > 0 ?
                                <Badger>{data.modifications?.length}</Badger> : null}
                        </Tab> : null}
                <Tab onClick={() => setActiveTabIndex(4)} isActive={4 === activeTabIndex}
                    label={'Notatki'}>{getNoteCount}</Tab>
                {/*@ts-expect-error fix after refactoring*/}
                {type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER' ? <Tab disabled={data.detailedContent?.metaValue.length === 0} onClick={() => setActiveTabIndex(5)} isActive={5 === activeTabIndex}
                    label={'Pozostałe'}/> : null}
            </TabWrapper>
        </BoxContainer>

        <TabPanel isActive={1 === activeTabIndex}>
            <DetailsTab type={type} data={data}/>
        </TabPanel>
        <TabPanel isActive={2 === activeTabIndex}>
            <DuplicatesTab type={type} data={data}/>
        </TabPanel>
        <TabPanel isActive={3 === activeTabIndex}>
            <ModificationsTab type={type} data={data}/>
        </TabPanel>
        <TabPanel isActive={4 === activeTabIndex}>
            <NoteTab type={type} data={data}/>
        </TabPanel>
        <TabPanel isActive={5 === activeTabIndex}>
            <AdditionalTab type={type} data={data}/>
        </TabPanel>
    </>);
};

export default Tabs;
