import React from 'react';
import { formatKwNumber } from 'utils/formatKwNumber';
import { BailiffOfferResponseContent } from 'utils/state-managment/bailiff/bailiffOffer';

import { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import { IconEnum } from 'components/common/Icon';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';

const generateOfferDetailsTabContent = (offer: BailiffOfferResponseContent) => {
    const detailsElements = [
        {
            icon: IconEnum.CALENDAR,
            text: 'Data dodania:',
            value: offer.dateAdded
        },
        ...(offer.estimateDate ? [{
            icon: IconEnum.CALENDAR,
            text: 'Data oszacowania:',
            value: offer.estimateDate
        }] : []),
        ...(offer.type ? [{
            icon: IconEnum.BUILDING,
            text: 'Typ nieruchomości:',
            value: offer.type
        }] : []),
        ...(offer.signature ? [{
            icon: IconEnum.INFO,
            text: 'Sygnatura:',
            value: `${offer.signature.toUpperCase()}`
        }] : []),
        ...(offer.kwNumber ? [{
            icon: IconEnum.DICTIONARY,
            text: 'Numer księgi wieczystej:',
            value:  formatKwNumber(offer.kwNumber)
        }] : []),
    ];

    const offerDetailsTabContent: V2DetailsTabsProps = {
        title: offer.location,
        address: `Lokalizacja sądu: ${offer.officeLocation ? offer.officeLocation : 'brak informacji'}`,
        detailsElements,
        description: offer.detailedContent?.description,
    };

    return offerDetailsTabContent;
};

const generateInfoElements = (offer: BailiffOfferResponseContent): InfoElementType[] => {
    return [
        ...(offer.estimateDate ? [{
            icon: IconEnum.CALENDAR,
            isVisible: !!offer.estimateDate,
            children: <b>Data oszacowania: {offer.estimateDate}</b>
        }] : []),
        {
            icon: IconEnum.BUILDING,
            isVisible: !!offer.type,
            children: <b>{offer.type}</b>
        },
        ...(offer.signature ? [{
            icon: IconEnum.INFO,
            isVisible: !!offer.signature,
            children: <b>{offer.signature.toUpperCase()}</b>
        }] : []),
        ...(offer.kwNumber ? [{
            icon: IconEnum.DICTIONARY,
            isVisible: !!offer.kwNumber,
            children: <b>{formatKwNumber(offer.kwNumber)}</b>
        }] : []),
    ];
};

export const bailiffOfferListFunctions = {
    generateOfferDetailsTabContent,
    generateInfoElements,
};
