export const formatFilterPrice = (price?: string | number | null, fraction = 0) => {
    if (!price) return price;

    const priceString = price.toString().replace(/\s/g, '').replace(',', '.');

    return new Intl.NumberFormat('pl-PL', {
        minimumFractionDigits: fraction,
        useGrouping: true,
    }).format(Number(priceString)).replace('\u00A0', ' ');
};
