import React, { FocusEvent } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

import { AlertType } from './AutocompleteInput';

const Action = styled.div`
    position: absolute;
    right: 10px;
    background: var(--color-white);
    width: 30px;    
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    pointer-events: none;
    cursor: pointer;

    &.header-input {
        right: -25px;
    }
`;

const TextModeWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: 5px;

    p {
        --font-weight: 600;

        margin-right: 5px;
    }

    .header-input {
        position: relative;        
        right: 0;
        bottom: 5px;
        min-width: 30px;
    }
`;

type AutocompleteTextModeWrapperProps = {
    value?: null | string | number,
    actualValueOption: {label: string, value: string | number, alerts?: AlertType | undefined, trash?: boolean | undefined} | undefined;
    showSuggestions: boolean;
    headerInput: boolean;
    handleClick: () => void;
    onBlur: (e: FocusEvent<HTMLInputElement | HTMLDivElement>) => void
    textModeRef: React.RefObject<HTMLDivElement>;
};

const AutocompleteTextModeWrapper: React.FC<AutocompleteTextModeWrapperProps> = ({
    value,
    actualValueOption,
    showSuggestions,
    headerInput,
    handleClick,
    onBlur,
    textModeRef
}) => {

    const { user } = useUser();

    return (
        <TextModeWrapper ref={textModeRef} onClick={handleClick} onBlur={onBlur}>
            <p>{actualValueOption ? actualValueOption.label : value}</p>
            <Action className={clsx('action', { 'header-input': headerInput })}>
                {showSuggestions ?
                    <Icon classNames={clsx({ 'on-dark-theme': user.isDarkMode })} icon={IconEnum.ARROW_UP}/> :
                    <Icon classNames={clsx({ 'on-dark-theme': user.isDarkMode })} icon={IconEnum.ARROW_DOWN}/>}
            </Action>
        </TextModeWrapper>);
};

export default AutocompleteTextModeWrapper;