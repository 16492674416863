import React, { FC } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';

import InfoElements, { InfoElementType } from 'components/common/Card/common/V2CardBodyDefault/InfoElements';
import ModificationsContainer, { ModificationsProps } from 'components/common/Card/common/V2CardBodyDefault/ModificationsContainer/ModificationsContainer';
import V2InteractiveIconsWrapper, {
    InteractiveIconsWrapperProperties
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    grid-area: bottom;
`;

const PaddingContainer = styled.div`
    margin: 10px 0;
`;

export type BottomProps = {
    infoElements?: InfoElementType[];
    modifications?: ModificationsProps;
} & InteractiveIconsWrapperProperties;

const Bottom: FC<BottomProps> = ({
    infoElements,
    modifications,
    userDetails,
    isNotInteractive,
    notInteractiveElements,
    interactiveElements,
    showMessages,
    setShowMessages,
    openOfferTab,
}) => {
    const width = useWindowWidth();

    return (
        <Wrapper>
            {width <= 490 && infoElements && infoElements.length > 0 && (
                <InfoElements infoElements={infoElements}/>
            )}
            {width < 991 && modifications && (<ModificationsContainer modifications={modifications}/>)}
            {width < 991 && !modifications && <PaddingContainer/>}
            <V2InteractiveIconsWrapper
                userDetails={userDetails}
                isNotInteractive={isNotInteractive}
                notInteractiveElements={notInteractiveElements}
                interactiveElements={interactiveElements}
                showMessages={showMessages}
                setShowMessages={setShowMessages}
                openOfferTab={openOfferTab}
            />
        </Wrapper>
    );
};

export default Bottom;
