import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from 'components/common/Icon';

type TabProperties = {
    label: string;
    icon?: IconEnum;
    isActive?: boolean;
    onClick: (e: React.MouseEvent) => void;
    children?: ReactNode;
    disabled?: boolean;
}

const Wrapper = styled.button`
  background: var(--color-alt-second);
  color: var(--color-primary);
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: background-color .3s;
  padding: 15px 20px;
  border-radius: 30px;
  display: flex;
  position: relative;

  &.active {
    span {
      outline: var(--color-white) solid 2px;
    }
  }
  
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  span {
    margin-right: 8px;
  }

  &.active,
  &:hover {
    transition: background-color .3s;
    background: var(--color-alt);
    color: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-white)'};

    img {
      filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
    }
  }
`;

const Tab: FC<TabProperties> = ({ disabled, label, icon, isActive, onClick, children }) => {
    const { user } = useUser();

    return (
        <Wrapper darkMode={user.isDarkMode} onClick={onClick} className={clsx({
            active: isActive,
            disabled: disabled,
        })}>
            {children}
            {icon ? <Icon icon={icon}/> : null}
            {label}
        </Wrapper>);
};

export default Tab;
