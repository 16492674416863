import { FiltersStateType } from 'utils/types/FiltersStateType';
import { ModulesType } from 'utils/types/ModulesType';

import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export type OfferRequestData = {
    dateFrom: string;
    city: string;
    daysAmount?: number,
    type: string[] | null,
    wantedKeywords: string[] | null,
    unwantedKeywords: string[] | null,
    buildingType: string[] | null,
    offerFrom: string[] | null,
    typeOfMarket: string[] | null,
    timeRange: string[],
    reportType: string[],
    filterId: number | null,
    name: null | string,
    pushAlert: boolean,
    alertEmail: boolean,
    alertSms: boolean,
    tossedUp: boolean,
    notificationsDelay: number,
    boundaries?: string,
    dateTo: string | null,
    groupDuplicates?: boolean,
    includeZeroArea?: boolean,
    manyLocations?: boolean,
    saveDate?: string,
    priceFrom?: string | number | null,
    priceTo?: string | number | null,
    depositPriceFrom?: string | number | null,
    depositPriceTo?: string | number | null,
    rentPriceFrom?: string | number | null,
    rentPriceTo?: string | number | null,
    areaFrom?: string | number | null,
    areaTo?: string | number | null,
    pricePerSquareMeterFrom?: string | number | null,
    pricePerSquareMeterTo?: string | number | null,
    numberOfRoomsFrom?: string | number | null,
    numberOfRoomsTo?: string | number | null,
    floorFrom?: string | number | null,
    floorTo?: string | number | null,
    builtYearFrom?: string | number | null,
    builtYearTo?: string | number | null,
    savedFilter?: [] | FiltersStateType[],
    module?: ModulesType,
}

export const setStateValues = (state: OfferRequestData) => {
    const dateFromWithDefault = state.dateFrom || (new Date(((new Date()).setDate((new Date()).getDate() - 3)))).toISOString();

    return {
        ...state,
        numberOfRooms: {
            from: state.numberOfRoomsFrom,
            to: state.numberOfRoomsTo,
        },
        floor: {
            from: state.floorFrom,
            to: state.floorTo,
        },
        area: {
            from: state.areaFrom,
            to: state.areaTo,
        },
        date: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.dateTo && state.dateTo.includes('T') ? state.dateTo.split('T')[0] : state.dateTo,
        },
        pricePerSquareMeter: {
            from: state.pricePerSquareMeterFrom,
            to: state.pricePerSquareMeterTo,
        },
        buildingType: state.buildingType ? state.buildingType : [],
        price: {
            from: state.priceFrom,
            to: state.priceTo,
        },
        depositPrice: {
            from: state.depositPriceFrom,
            to: state.depositPriceTo,
        },
        rentPrice: {
            from: state.rentPriceFrom,
            to: state.rentPriceTo,
        },
        builtYear: {
            from: state.builtYearFrom,
            to: state.builtYearTo,
        },
        type: state.type ? state.type : [],
        reportType: state.reportType ?? [],
        savedFilter: state.savedFilter ?? [],
    };
};

export const getValueFromState = ( state: FiltersStateType, type: ModulesType ): OfferRequestData => {
    const dataToReturn = {
        ...state,
        dateTo: state.date.to ? dateFormat(dateFromFunc(state.date.to)) : null,
        dateFrom: dateFormat(dateToFunc(state.date.from)),
        areaFrom: state.area.from,
        areaTo: state.area.to,
        priceFrom: state.price.from,
        priceTo: state.price.to,
        depositPriceFrom: state.depositPrice.from,
        depositPriceTo: state.depositPrice.to,
        rentPriceFrom: state.rentPrice.from,
        rentPriceTo: state.rentPrice.to,
        pricePerSquareMeterFrom: state.pricePerSquareMeter.from,
        pricePerSquareMeterTo: state.pricePerSquareMeter.to,
        numberOfRoomsFrom: state.numberOfRooms.from,
        numberOfRoomsTo: state.numberOfRooms.to,
        builtYearFrom: state.builtYear.from,
        builtYearTo: state.builtYear.to,
        type: state.type?.length > 0 && state.type.length !== 3 ? state.type : null,
        buildingType:
            state.buildingType?.length > 0 && state.buildingType.length !== 3
                ? state.buildingType
                : null,
        offerFrom: state.offerFrom?.length > 0 ? state.offerFrom : null,
        floorFrom: state.floor.from,
        floorTo: state.floor.to,
        typeOfMarket: state.typeOfMarket?.length > 0 ? state.typeOfMarket : null,
        wantedKeywords: state.wantedKeywords ? state.wantedKeywords : null,
        unwantedKeywords: state.unwantedKeywords ? state.unwantedKeywords : null,
        savedFilter: undefined
    };

    const defaultKeys = ['savedFilter'];
    let keysToDelete;

    switch (type) {
    case 'tender':
    case 'auction':
        keysToDelete = [...defaultKeys, 'tossedUp', 'city', 'manyLocations', 'dateRangeFilter', 'includeZeroArea', 'groupDuplicates', 'boundaries'];
        break;
    case 'analysis':
        keysToDelete = [...defaultKeys, 'tossedUp', 'city', 'filterId', 'daysAmount', 'alertSms', 'alertEmail', 'pushAlert'];
        break;
    case 'rent':
    case 'cooperative':
    case 'bailiff_notice':
        keysToDelete = [...defaultKeys, 'tossedUp', 'boundaries'];
        break;
    case 'advertisement':
        keysToDelete = [...defaultKeys, 'boundaries'];
        break;
    default:
        keysToDelete = defaultKeys;
    }

    for (const key of Object.keys(dataToReturn)) {
        if(keysToDelete.includes(key)) {
            delete dataToReturn[key as keyof typeof dataToReturn];
        } else if (dataToReturn[key as keyof typeof dataToReturn] === null ||
            dataToReturn[key as keyof typeof dataToReturn] === '' ||
            // @ts-expect-error cant read 'from' as part of MultipleFilterType
            ( typeof dataToReturn[key as keyof typeof dataToReturn] === 'object' && 'from' in dataToReturn[key as keyof FiltersStateType]!)) {
            delete dataToReturn[key as keyof typeof dataToReturn];
        }
    }

    return dataToReturn;
};
