import React, { FC } from 'react';
import clsx from 'clsx';
import styled from 'styled-components';

const Container = styled.span`
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;

    &.clickable {
        cursor: pointer;
    }
`;

export enum IconEnum {
    ACCORDION_DOWN = 'accordion_down',
    ACCORDION_UP = 'accordion_up',
    BOOKMARK = 'bookmark',
    MAP_PIN = 'map_pin',
    CALENDAR = 'calendar',
    IN_TYPE = 'in_type',
    PRICE = 'price',
    PRICE_PART = 'price_part',
    SQUARE = 'square',
    BED = 'bed',
    FLOOR_LEVEL = 'floor_level',
    TWO_TYPE = 'two_type',
    PEOPLE = 'people',
    DICTIONARY = 'dictionary',
    WORK = 'work',
    WORK_WHITE = 'work_white',
    PHONE = 'phone',
    OPTIONS = 'options',
    AUDIO = 'audio',
    TIMER = 'timer',
    ALERT = 'alert',
    ARROW_RIGHT = 'arrow_right',
    ARROW_LEFT = 'arrow_left',
    ARROW_UP = 'arrow_up',
    PLUS = 'plus',
    SEARCH = 'search',
    ARROW_DOWN = 'arrow_down',
    BAG_WHITE = 'bag_white',
    CALENDAR_WHITE = 'calendar_white',
    PEOPLES_2_WHITE = 'peoples_2_white',
    PEOPLES_3_WHITE = 'peoples_3_white',
    SQUARE_WHITE = 'square_white',
    LIST_WHITE = 'list_white',
    ANALYSIS_WHITE = 'analysis_white',
    SQUARE_ARROW_LEFT_WHITE = 'square_arrow_left_white',
    SQUARE_ARROW_RIGHT_WHITE = 'square_arrow_right_white',
    SQUARES = 'squares',
    BUILDING = 'building',
    LINK = 'link',
    BLOCKED = 'blocked',
    EYE = 'eye',
    LIKE = 'like',
    DISLIKE = 'dislike',
    MESSAGE = 'message',
    PEOPLES_3 = 'peoples_3',
    INFO_WHITE = 'info_white',
    CROSS = 'cross',
    LEAVE = 'leave',
    SQUARE_TIMER = 'square_timer',
    SEND = 'send',
    INFO = 'info',
    ANGLE_LEFT = 'angle_left',
    ANGLE_LEFT_TO_LINE = 'angle_left_to_line',
    ANGLE_RIGHT = 'angle_right',
    ANGLE_RIGHT_TO_LINE = 'angle_right_to_line',
    COG = 'cog',
    BOOKMARK_WHITE = 'bookmark_white',
    APPROVE = 'approve',
    DANGER = 'danger',
    DELETE = 'delete',
    EDIT = 'edit',
    QUESTION = 'question',
    EMAIL = 'email',
    HEART = 'heart',
    NOTE = 'note',
    NOTE2 = 'note2',
    CIRCLE_CROSS = 'circle_cross',
    MOON = 'moon',
    SUN = 'sun',
    QUESTION_CIRCLE = 'questionCircle',
    WALLET = 'wallet',
    WALLET_WHITE = 'wallet_white',
    FULLSCREEN = 'fullscreen',
    USER = 'user',
    EYE_HIDDEN = 'eye_hidden',
    PHOTOS = 'photos',
    SAVE = 'save',
    TRASH = 'trash',
    UPLOAD = 'upload',
    STAR = 'star',
    HALF_STAR = 'half_star',
    FILLED_STAR = 'filled_star',
    CSV = 'csv',
    PDF = 'pdf',
    TXT = 'txt',
    UNKNOWN_FILE = 'unknown_file'
}

type IconProperties = {
    icon: IconEnum
    onClick?: (e: React.MouseEvent) => void
    classNames?: string
}

// TODO: INVEST-260 Refactor icon type to enum
const Icon: FC<IconProperties> = ({ icon, onClick, classNames }) => {
    return (
        <Container onClick={onClick} className={clsx(classNames, 'icon', { clickable: onClick })}>
            <img src={`/icons/${icon}.svg`} alt={icon}/>
        </Container>
    );
};

export default Icon;
