import React, { FC, useEffect, useState } from 'react';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import { AdvertValidatorType, isFieldValid } from 'utils/validators/advertAddingValidator';

import Button from 'components/atom/Button';
import Indicator from 'components/atom/Indicator';
import Icon, { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import TextInput from 'components/common/Inputs/TextInput';
import { activeNotification } from 'components/functions/activeNotification';

const Title = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;

    font-style: normal;
    font-weight: var(--font-weight);
    font-size: 20px;
    line-height: 30px;

    color: var(--color-primary);
    padding: 12px 15px;

    &.users-title {
        padding: 20px 30px;
    }
`;

const Container = styled.div`
    padding: 15px 20px 0;

    img {
        filter: ${(props) => props.darkMode ? 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)' : 'unset'};
    }

    @media (max-width: 1100px) {
        padding: 20px 20px 0;
    }
`;

const ProfileBody = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 15px;
    border-radius: var(--box-border-radius);
    background: var(--color-white);

    @media (min-width: 991px) {
        grid-template-columns: 25% 1fr;
    }
`;

const ProfileImage = styled.div`
    height: fit-content;
    position: relative;
    border-radius: var(--image-border-radius);

    & > img {
        border-radius: var(--image-border-radius);
        max-width: 315px;
        height: 300px;
        object-fit: cover;
        width: 100%;
        display: block;
        margin: auto;
    }
`;

const Actions = styled.div`
    position: absolute;
    width: 100%;
    justify-content: center;
    align-items: center;
    bottom: 30px;
    margin: auto;
    display: flex;
    gap: 10px;

    img {
        width: 100%;
        height: 100%;
    }
`;

const ActionElement = styled.div`
    background: var(--color-white);
    border-radius: var(--border-radius);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:hover {
        transition: background-color ease-in .2s;
        background-color: var(--color-alt);
        cursor: pointer;

        img {
            filter: ${(props) => props.darkMode ? 'unset' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
        }
    }
`;

const ProfileInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const SaveButton = styled(Button)`
    background: var(--color-alt) !important;
    margin-bottom: 12px;
    width: 245px;
    align-self: center;

    img {
        filter: ${(props) => props.darkMode ? 'invert(77%) sepia(83%) saturate(1712%) hue-rotate(194deg) brightness(90%) contrast(103%)' : 'invert(14%) sepia(99%) saturate(2391%) hue-rotate(198deg) brightness(93%) contrast(101%)'};
    }
`;

const HiddenInput = styled.input`
    display: none;
`;

const ProfilePage: FC = () => {
    const { user, dispatch } = useUser();
    const [profileFields, setProfileFields] = useState(user.profile);
    const [triggerValidation, setTriggerValidation] = useState(false);
    const [profileImage, setProfileImage] = useState({ url: '', fileName: '' });
    const [isWithoutProfile, setIsWithoutProfile] = useState(false);
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
    const photoUrl = profileFields.userPhoto ? `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${profileFields.userPhoto}&photoSize=NORMAL_PHOTO` : '/defaultImg.png';
    const [photoUrlState, setPhotoUrlState] = useState(photoUrl);

    useEffect(() => {
        setPhotoUrlState(photoUrl);

        if (profileFields.userPhoto) {
            setProfileImage({ ...profileImage, fileName: profileFields.userPhoto });
        }
    }, [photoUrl]);

    useEffect(() => {
        UserActions.getProfile(dispatch);
    }, [dispatch]);

    useEffect(() => {
        setProfileFields(user.profile);

        if (user.profile.name === '') {
            setIsWithoutProfile(true);
        } else {
            setIsWithoutProfile(false);
        }
    }, [user.profile]);

    const handleChange = (field: string, value: string) => {
        setProfileFields((fields) => ({
            ...fields,
            [field]: value
        }));
    };

    const handleSaveProfile = () => {
        setTriggerValidation(true);

        if (isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, profileFields.name) &&
            isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, profileFields.email) &&
            isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, profileFields.phoneNumber)) {
            if (isWithoutProfile) {
                userApiRequests.addProfile({ ...profileFields, userPhoto: profileImage.fileName }).then(() => {
                    setTriggerValidation(false);
                    setIsWithoutProfile(false);
                    UserActions.getProfile(dispatch);
                });
            } else {
                userApiRequests.updateProfile({ ...profileFields, userPhoto: profileImage.fileName }).then(() => {
                    setTriggerValidation(false);
                    UserActions.getProfile(dispatch);
                });
            }
        }
    };

    const handleImg = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files && e.target.files.length > 0 && e.target.files[0];

        if (file) {
            setIsLoadingPhoto(true);
            userApiRequests.saveProfilePhoto(file).then((imgName) => {
                setProfileImage({ url: URL.createObjectURL(file), fileName: imgName as unknown as string });
                setPhotoUrlState(URL.createObjectURL(file));
                setIsLoadingPhoto(false);
            });
        }
    };

    const handleDeleteProfilePhoto = () => {
        if (!profileFields.userPhoto) {
            activeNotification('Zdjęcie profilowe', 'Nie masz zdjęcia profilowego', 'warning');

            return;
        } else {
            setIsLoadingPhoto(true);
            userApiRequests.updateProfile({ ...profileFields, userPhoto: '' }, true).then(()=> {
                setIsLoadingPhoto(false);
                setPhotoUrlState('/defaultImg.png');
            });
        }
    };

    return (
        <Container>
            <Title>Informacje o użytkowniku wyświetlane w ogłoszeniach</Title>
            <ProfileBody>
                <ProfileImage>
                    {isLoadingPhoto && <Indicator fitParentContainer/>}
                    <img
                        src={photoUrlState}
                        alt="profile"/>
                    <Actions>
                        <label htmlFor="file-upload">
                            <ActionElement><Icon icon={IconEnum.UPLOAD}/></ActionElement>
                        </label>
                        <HiddenInput id="file-upload" type="file" onChange={handleImg}/>
                        <ActionElement onClick={handleDeleteProfilePhoto}><Icon icon={IconEnum.TRASH}/></ActionElement>
                    </Actions>
                </ProfileImage>
                <ProfileInfo>
                    <InputContainer required title={'Imię i nazwisko lub nazwę firmy'} icon={IconEnum.USER}>
                        <TextInput
                            value={profileFields.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            placeholder={'Wpisz dane...'}
                            required
                            inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                            errorMessage={'Pole nie może być puste'}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>
                    <InputContainer required title={'E-mail kontaktowy'} icon={IconEnum.EMAIL}>
                        <TextInput
                            value={profileFields.email}
                            onChange={(e) => handleChange('email', e.target.value)}
                            placeholder={'Wpisz adres e-mail...'}
                            required
                            inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                            errorMessage={'Pole nie może być puste'}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>
                    <InputContainer required title={'Telefon kontaktowy'} icon={IconEnum.PHONE}>
                        <TextInput
                            value={profileFields.phoneNumber}
                            onChange={(e) => handleChange('phoneNumber', e.target.value)}
                            placeholder={'Wpisz numer telefonu...'}
                            required
                            inputValidation={(value: string | undefined | null | number) => !isFieldValid(AdvertValidatorType.NO_EMPTY_TEXT, value as string)}
                            errorMessage={'Pole nie może być puste'}
                            triggerValidation={triggerValidation}
                        />
                    </InputContainer>
                    <InputContainer title={'Opis użytkownika'} icon={IconEnum.NOTE2}
                        additionalText={`Pozostało ${(400 - (profileFields.description ? profileFields.description.length : 0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} znaków`}
                    >
                        <TextInput
                            description
                            value={profileFields.description}
                            onChange={(e) => handleChange('description', e.target.value)}
                            placeholder={'Wpisz treść opisu....'}
                            max={'400'}
                            maxLength={400}
                        />
                    </InputContainer>
                    <SaveButton darkMode={user.isDarkMode} onClick={handleSaveProfile}>
                        Zapisz zmiany
                    </SaveButton>
                </ProfileInfo>
            </ProfileBody>
        </Container>
    );
};

export default ProfilePage;
