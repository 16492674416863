import React, { ChangeEvent, FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';

import Button from 'components/atom/Button';
import InteractiveIcons from 'components/common/Card/common/InteractiveIcons';
import { IconEnum } from 'components/common/Icon';
import TextInput from 'components/common/Inputs/TextInput';
import Modal from 'components/common/Modal/Modal';
import RatingSetter from 'components/common/RatingSetter/RatingSetter';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { activeNotification } from 'components/functions/activeNotification';

import InfoElements, { InfoElementType } from './InfoElements';
import { Title } from './V2CardBodyDefault';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    grid-area: ${(props) => props.isUpperLabel ? '3 / left' : '2 / left'};
`;

const RatingWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;

    span {
        --font-weight: 400;

        font-size: 11px;
    }

    b {
        font-weight: 500;
    }

    img {
        width: 20px;
        height: 18px;
    }
`;

const LinkButton = styled.a`
    text-decoration: underline;
`;

const RatingInfo = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
    max-width: 330px;
    width: 100%;
    margin-top: 50px;

    button {
        width: 100%;
        justify-content: center;
    }
`;

const RatingModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 20px;
    align-items: center;
    margin-top: 45px;

    span {
        max-width: 425px;
    }

    p {
        max-width: 260px;
        text-align: center;
    }

    .rating-setter {
        margin-top: 15px;
        margin-bottom: 20px;

        img {
            width: 16px;
            height: 15px;
        }
    }
`;

export interface RatingContainerProps {
    title: string;
    commentsCount: number;
    handleSendRating: (rating: number, description: string) => void;
    handleGetComments: (id: number) => void;
    opinionsNumber: number;
    opinionsRating: number;
    userRatingValue?: number;
    userRatingComment?: string;
    isMyOpinion?: boolean;
}

interface InfoContainerProps {
    title: string;
    infoElements?: InfoElementType[];
    isUpperLabel?: boolean;
    ratingContainer?: RatingContainerProps;
    openOfferTab?: (tab: TabType) => void;
}

const InfoContainer: FC<InfoContainerProps> = ({
    title,
    infoElements,
    isUpperLabel,
    ratingContainer,
    openOfferTab,
}) => {
    const wrapperRef = useRef<HTMLDivElement>();
    const titleRef = useRef<HTMLHeadingElement>();
    const [ratingComment, setRatingComment] = useState(ratingContainer?.userRatingComment || '');
    const [ratingValue, setRatingValue] = useState(ratingContainer?.userRatingValue || 0);
    const width = useWindowWidth();
    const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
    const { user } = useUser();

    useEffect(() => {
        setRatingComment(ratingContainer?.userRatingComment || '');
        setRatingValue(ratingContainer?.userRatingValue || 0);
    }, [ratingContainer?.userRatingComment, ratingContainer?.userRatingValue]);

    const handleRatingModal = (e: MouseEvent | KeyboardEvent, value: boolean) => {
        e.stopPropagation();
        setIsRatingModalOpen(value);
    };

    const handleSendRating = (e: MouseEvent) => {
        if (ratingValue === 0 && !ratingComment) {
            e.stopPropagation();
            activeNotification('Popraw dane', 'Ocena nie może być pusta, zaznacz odpowiednią ilość gwiazdek i/lub napisz opinię', 'warning');

            return;

        } else {
            handleRatingModal(e, false);
            ratingContainer?.handleSendRating(ratingValue, ratingComment);
        }

    };

    const handleGetComments = (e: MouseEvent) => {
        e.stopPropagation();
        ratingContainer?.handleGetComments(1);
        openOfferTab?.(TabType.OPINIONS);
    };

    const handleText = (e: ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setRatingComment(e.target.value);
    };

    const handleRatingClick = (e: React.MouseEvent) => {
        if (ratingContainer?.isMyOpinion) {
            handleGetComments(e);
        } else {
            handleRatingModal(e, true);
        }
    };

    return (
        <Wrapper ref={wrapperRef} isUpperLabel={isUpperLabel} role='list'>
            <Title ref={titleRef}>{title}</Title>
            {width > 490 && infoElements && infoElements.length > 0 && (
                <InfoElements infoElements={infoElements}/>
            )}
            {ratingContainer ? <>
                <RatingWrapper>
                    <InteractiveIcons onClick={(e) => handleRatingClick(e)} icon={IconEnum.STAR} active={ratingContainer.opinionsNumber > 0}/>
                    <RatingInfo>
                        <span onClick={(e) => handleRatingClick(e)} ><b>Ocena społeczności</b></span>
                        {!ratingContainer.commentsCount && !ratingContainer.opinionsRating ?
                            <span onClick={(e) => handleRatingClick(e)}>Dodaj pierwszą ocenę!</span> : null}
                        <span onClick={(e) => handleRatingClick(e)}>
                            {ratingContainer.opinionsRating > 0 && ratingContainer.opinionsNumber > 0 ? <span>
                                <b>{ratingContainer.opinionsRating}</b> na {ratingContainer.opinionsNumber} opinii</span> : null}
                            {ratingContainer.commentsCount > 0 ? <>(<LinkButton
                                onClick={handleGetComments}>{ratingContainer.commentsCount} komentarze</LinkButton>)</> : null}
                        </span>
                    </RatingInfo>
                </RatingWrapper>
                <Modal isOpen={isRatingModalOpen} onClose={(e) => handleRatingModal(e, false)} centeredContent
                    strictContentHeight backgroundColor={user.isDarkMode ? 'var(--color-alt-hover)' : '#fff'}>
                    <RatingModalContainer>
                        <h1>Jak oceniasz to ogłoszenie?</h1>
                        <p>{ratingContainer.title}</p>
                        <RatingSetter rating={ratingValue} setRating={setRatingValue}/>
                        <TextInput value={ratingComment} onChange={handleText}
                            onClick={(e: MouseEvent) => e.stopPropagation()}
                            placeholder="Wpisz treść opinii..."/>
                        <ButtonWrapper>
                            <Button defaultVariant actionIconVisible={false}
                                onClick={(e) => handleRatingModal(e, false)}>Anuluj</Button>
                            <Button secondary actionIconVisible={false} onClick={handleSendRating}>Wystaw ocenę</Button>
                        </ButtonWrapper>
                    </RatingModalContainer>
                </Modal>
            </> : null}
        </Wrapper>
    );
};

export default InfoContainer;
