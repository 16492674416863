import React, { FC, useContext, useMemo, useState } from 'react';
import AnalysisSaleContext from 'utils/context/AnalysisSaleContext';
import { generateOptionsFromEnum } from 'utils/generateOptionsFromEnum';
import { analysiSalePropertyTypeOptions, areaOptions, buildYearOptions, plotAreaOptions,
    priceOptions, pricePerSquareMeterSaleOptions, roomOptions, userFloorOptions, userFloorsOptions } from 'utils/options';
import { FlatBuildingTypeEnum, OfferFromEnum, ReportType, SaleModule, TimeRange, TypeOfMarketEnum } from 'utils/types/AnalysisModels';
import { HouseBuildingTypeEnum, OtherBuildingTypeEnum, PlotBuildingTypeEnum } from 'utils/types/AnalysisModels';
import { InputType, LocationChangeType } from 'utils/types/InputTypes';

import Button from 'components/atom/Button';
import StickyBottom from 'components/common/FiltersRenderer/StickyBottom';
import V2FiltersRenderer from 'components/common/FiltersRenderer/V2FiltersRenderer';
import { IconEnum } from 'components/common/Icon';
import InputContainer from 'components/common/InputContainer';
import { FilterOptions } from 'components/common/Inputs/AutocompleteFiltersInput';
import { getVoivodeshipCities } from 'components/functions/locations';

type FiltersAnalysisProperties = object;

const FiltersAnalysisSale: FC<FiltersAnalysisProperties> = () => {

    const {
        analysisSaleFiltersState,
        handleDeleteFilter,
        handleSetFiltersValues,
        handleChange,
        handleChangePropertyType,
        handleChangeDateList,
        clearFilters,
        loadAnalysis,
        validateFilters,
        handleSaveOrUpdateFilter
    } = useContext(AnalysisSaleContext);

    const [isFilterButtonVisible, setIsFilterButtonVisible] = useState(false);

    const buildingOptions = useMemo(() => {
        if (analysisSaleFiltersState.module === SaleModule.SALE_FLAT) return generateOptionsFromEnum(FlatBuildingTypeEnum);
        if (analysisSaleFiltersState.module === SaleModule.SALE_HOUSE) return generateOptionsFromEnum(HouseBuildingTypeEnum);
        if (analysisSaleFiltersState.module === SaleModule.SALE_OTHER) return generateOptionsFromEnum(OtherBuildingTypeEnum);
        if (analysisSaleFiltersState.module === SaleModule.SALE_PLOT) return generateOptionsFromEnum(PlotBuildingTypeEnum);

        return [];
    }, [analysisSaleFiltersState.module]);

    const handleSubmit = () => {
        if (validateFilters()) {
            loadAnalysis();
            document.body.scrollTo(0, 0);
        }
    };

    const getCities = async (voivodeship: string) => {
        const newLocations = await getVoivodeshipCities(voivodeship, analysisSaleFiltersState.locations);
        handleChange('locations', newLocations || []);
    };

    const handleRemoveSavedLocation = (location: string) => {
        const savedLocations = analysisSaleFiltersState.savedLocations;
        delete savedLocations[location];

        handleChange('savedLocations', savedLocations);
        handleChange('city', Object.values(savedLocations as LocationChangeType));
    };

    const analysisFiltersStructure = [
        {
            accordionTitle: 'Podstawowe',
            hideTitle: true,
            filters: [
                {
                    title: 'Zapisane filtry',
                    type: InputType.FILTER_AUTOCOMPLETE,
                    icon: IconEnum.BOOKMARK,
                    placeholder: 'Wybierz filtr...',
                    options: {
                        [InputType.FILTER_AUTOCOMPLETE]: {
                            contextFunctions: {
                                handleDeleteFilter,
                                handleSetFiltersValues,
                            },
                            modulesProps: {
                                filterId: analysisSaleFiltersState.id ?? null,
                                options: analysisSaleFiltersState.savedFilter as FilterOptions[],
                            },
                        }
                    },
                },
                {
                    title: 'Lokalizacja',
                    type: InputType.LOCATION,
                    helperText: 'Wybierz z listy interesującą Ciebie lokalizację. Możesz zaznaczyć dowolną ilość.',
                    icon: IconEnum.MAP_PIN,
                    placeholder: 'Szukaj lokalizacji...',
                    options: {
                        [InputType.LOCATION]: {
                            modulesProps: {
                                locations: analysisSaleFiltersState.locations,
                                searchLocationResult: analysisSaleFiltersState.searchLocationResult,
                                savedLocations:  analysisSaleFiltersState.savedLocations,
                                savedLocationsFieldName: 'savedLocations',
                                cityFieldName: 'city',
                            },
                            contextFunctions: {
                                getCities,
                                handleRemoveSavedLocation,
                                handleChange,
                            }
                        }
                    }
                },
                {
                    title: 'Data',
                    type: InputType.FLEXIBLE_DATE,
                    helperText: 'Wybierz, z jakiego okresu chcesz wyświetlić oferty. Wybierz opcję "Przedział dat" aby precyzyjnie wskazać datę. Ten parametr nie wpływa na natychmiastowe wysyłanie alertów.',
                    icon: IconEnum.CALENDAR,
                    placeholder: 'Data dodania oferty...',
                    containerActionText: 'Przedział dat',
                    containerActionClick: () => handleChange('dateRangeFilter', !analysisSaleFiltersState.dateRangeFilter),
                    options: {
                        [InputType.FLEXIBLE_DATE]: {
                            modulesProps: {
                                date: analysisSaleFiltersState.offerAdded,
                                dateRangeFilter: analysisSaleFiltersState.dateRangeFilter,
                                daysAmount: analysisSaleFiltersState.daysAmount,
                                fieldName: 'offerAdded',
                            },
                            contextFunctions: {
                                handleChange,
                                handleChangeDateList,
                            }
                        }
                    }
                },

            ]
        },
        {
            accordionTitle: 'Parametry',
            hideTitle: true,
            filters: [
                {
                    title: 'Typ nieruchomości',
                    type: InputType.AUTOCOMPLETE_PROPERTY,
                    icon: IconEnum.LEAVE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.AUTOCOMPLETE_PROPERTY]: {
                            contextFunctions: {
                                handleChangePropertyType,
                                handleChange,
                            },
                            modulesProps: {
                                value: analysisSaleFiltersState.module,
                                options: analysiSalePropertyTypeOptions,
                            }
                        }
                    },
                },
                {
                    title: 'Cena',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.price,
                                fieldName: 'price',
                                options: priceOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Cena za metr',
                    type: InputType.RANGE_PRICE_SELECT,
                    icon: IconEnum.PRICE_PART,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_PRICE_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.pricePerSquareMeter,
                                fieldName: 'pricePerSquareMeter',
                                options: pricePerSquareMeterSaleOptions,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Metraż',
                    type: InputType.RANGE_METERS_SELECT,
                    icon: IconEnum.SQUARE,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_METERS_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.area,
                                fieldName: 'area',
                                options: analysisSaleFiltersState.module === SaleModule.SALE_PLOT ? plotAreaOptions : areaOptions,

                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Typ ogłoszeniodawcy',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.PEOPLE,
                    placeholder: 'Wybierz typ...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.offerFrom,
                                options: generateOptionsFromEnum(OfferFromEnum),
                                optionsIcon: IconEnum.PEOPLE,
                                fieldName: 'offerFrom',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                ...(analysisSaleFiltersState.module === SaleModule.SALE_FLAT || analysisSaleFiltersState.module === SaleModule.SALE_HOUSE ? [{
                    title: 'Pokoje',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: 'bed' as IconEnum,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.numberOfRooms,
                                fieldName: 'numberOfRooms',
                                options: roomOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(analysisSaleFiltersState.module === SaleModule.SALE_FLAT || analysisSaleFiltersState.module === SaleModule.SALE_OTHER || analysisSaleFiltersState.module === SaleModule.SALE_HOUSE ? [{
                    title: 'Piętro',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.FLOOR_LEVEL,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.floor,
                                fieldName: 'floor',
                                options: userFloorOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(analysisSaleFiltersState.module === SaleModule.SALE_FLAT ? [{
                    title: 'Ilość pięter',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.FLOOR_LEVEL,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.floors,
                                fieldName: 'floors',
                                options: userFloorsOptions,
                                maxInputLength: 3,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                ...(analysisSaleFiltersState.module === SaleModule.SALE_FLAT || analysisSaleFiltersState.module === SaleModule.SALE_HOUSE || analysisSaleFiltersState.module === SaleModule.SALE_OTHER ? [{
                    title: 'Rok budowy',
                    type: InputType.RANGE_NUMBER_SELECT,
                    icon: IconEnum.CALENDAR,
                    placeholder: '',
                    options: {
                        [InputType.RANGE_NUMBER_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.builtYear,
                                fieldName: 'builtYear',
                                options: buildYearOptions,
                                maxInputLength: 4,
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
                {
                    title: analysisSaleFiltersState.module === SaleModule.SALE_PLOT ? 'Typ działki' : 'Typ budynku',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.TWO_TYPE,
                    placeholder: 'Wybierz rodzaj...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.buildingType,
                                options: buildingOptions,
                                optionsIcon: IconEnum.TWO_TYPE,
                                fieldName: 'buildingType',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                ...(analysisSaleFiltersState.module === SaleModule.SALE_FLAT || analysisSaleFiltersState.module === SaleModule.SALE_HOUSE || analysisSaleFiltersState.module === SaleModule.SALE_OTHER ? [{
                    title: 'Rodzaj rynku',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.WORK,
                    placeholder: 'Wybierz rodzaj rynku...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.typeOfMarket,
                                options: generateOptionsFromEnum(TypeOfMarketEnum),
                                optionsIcon: IconEnum.COG,
                                fieldName: 'typeOfMarket',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                }] : []),
            ]
        },
        {
            accordionTitle: 'Tagi',
            hideTitle: true,
            filters: [
                {
                    title: 'Słowa pożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.wantedKeywords,
                                fieldName: 'wantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange,
                            },
                        }
                    }
                },
                {
                    title: 'Słowa niepożądane',
                    type: InputType.KEYWORDS_TEXT,
                    helperText: 'Podaj słowa kluczowe, które nie mają zawierać się w tytule lub opisie ogłoszenia, rozdzielając je przecinkiem. Więcej w zakładce Pomoc',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz słowo...',
                    options: {
                        [InputType.KEYWORDS_TEXT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.unwantedKeywords,
                                fieldName: 'unwantedKeywords',
                                icon: IconEnum.SEARCH,
                                actionIcon: IconEnum.PLUS,
                                errorMessage: 'Słowo musi mieć minimum 3 znaki',
                                minLength: 3,
                            },
                            contextFunctions: {
                                inputValidation: (val: string | number | null | undefined) => (val as string).length > 0 && (val as string).length < 3,
                                handleChange,
                            },
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Ustawienia',
            hideTitle: true,
            filters: [
                {
                    title: 'Zerowe lub puste wartości',
                    type: InputType.RADIO,
                    helperText: 'Wybierz czy mają pojawiać się ogłoszenia, które nie spełniają parametrów filtra z powodu braku danych. Więcej w zakładce Pomoc',
                    icon: IconEnum.OPTIONS,
                    placeholder: '',
                    options: {
                        [InputType.RADIO]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.includeZeroArea,
                                fieldName: 'includeZeroArea',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Przedziały',
            hideTitle: true,
            filters: [
                {
                    title: 'Przedział czasu',
                    type: InputType.AUTOCOMPLETE,
                    helperText: 'Podaj, z jakiego okresu ma być przeprowadzona analiza',
                    icon: IconEnum.PEOPLE,
                    placeholder: 'Wybierz przedział...',
                    options: {
                        [InputType.AUTOCOMPLETE]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.timeRange,
                                fieldName: 'timeRange',
                                options: generateOptionsFromEnum(TimeRange),
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    },
                },
                {
                    title: 'Granica przedziału',
                    type: InputType.TEXT,
                    helperText: 'Podaj po przecinku przedziały powierzchni, dla których będzie przeprowadzona analiza "wg przedziałów powierzchni"',
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz przedział...',
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.boundaries,
                                fieldName: 'boundaries',
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                },
                {
                    title: 'Rodzaj raportu',
                    type: InputType.MULTIPLE_SELECT,
                    icon: IconEnum.TWO_TYPE,
                    placeholder: 'Wybierz rodzaj raportu...',
                    options: {
                        [InputType.MULTIPLE_SELECT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.reportType,
                                fieldName: 'reportType',
                                options: generateOptionsFromEnum(ReportType),
                                optionsIcon: IconEnum.TWO_TYPE
                            },
                            contextFunctions: {
                                handleChange,
                            }
                        }
                    }
                },
            ]
        },
        {
            accordionTitle: 'Podsumowanie',
            hideTitle: true,
            isWideSpace: true,
            lastElement: true,
            filters: [
                {
                    title: 'Zapisz nowy filtr',
                    type: InputType.TEXT,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Wpisz nazwę...',
                    containerActionText: analysisSaleFiltersState.id ? 'Aktualizuj' : undefined,
                    containerActionClick: handleSaveOrUpdateFilter,
                    options: {
                        [InputType.TEXT]: {
                            modulesProps: {
                                value: analysisSaleFiltersState.name || '',
                                fieldName: 'name',
                                inputIcon: IconEnum.BOOKMARK_WHITE,
                                actionIcon: IconEnum.ARROW_RIGHT,
                                important: true,
                                isSaveFilterButton: true,
                            },
                            contextFunctions: {
                                handleChange,
                                inputActionHandler: handleSaveOrUpdateFilter,
                            }
                        }
                    },
                },
                {
                    title: 'Zastosuj filtry',
                    type: InputType.SAVE_BUTTON,
                    icon: IconEnum.DICTIONARY,
                    placeholder: 'Przygotuj analizę...',
                    options: {
                        [InputType.SAVE_BUTTON]: {
                            contextFunctions: {
                                onChangeVisibleButtonFilter: setIsFilterButtonVisible,
                                onActionClick: clearFilters,
                                onClick: handleSubmit,
                            },
                            modulesProps: {
                                actionText: 'Resetuj',
                            },
                        }
                    },
                },
            ],
        }
    ];

    return (
        <V2FiltersRenderer elementsToRender={analysisFiltersStructure} type={'analysis'} bottomActions={
            <StickyBottom
                isVisible={isFilterButtonVisible}
                className={'sticky-filter-button'}>
                <InputContainer title={'Zastosuj filtry'} icon={IconEnum.DICTIONARY}
                    actionText={'Resetuj'}
                    onActionClick={clearFilters}>
                    <Button onClick={handleSubmit}>
                        {'Przygotuj analizę...'}
                    </Button>
                </InputContainer>
                STICKY BOTTOM
            </StickyBottom>
        }/>
    );
};

export default FiltersAnalysisSale;
