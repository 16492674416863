import React, { FC, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';

import { IconEnum } from 'components/common/Icon';
import { activeNotification } from 'components/functions/activeNotification';

import InteractiveIcons from './InteractiveIcons';

type InteractiveIconsPopupProperties = {
    type: ModulesType;
    data: OfferData
}

export enum AlertType {
    EMAIL = 'email',
    SMS = 'sms',
    PUSH = 'push',
    ERROR = 'error',
    OFF = 'off'
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 250px;
    position: absolute;
    background-color: white;
    top: -190px;
    gap: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    padding: 15px;

    div {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 20px;
        color: var(--color-primary);

        &:hover {
            color: white;
        }
    }
`;

const InteractiveIconsPopup: FC<InteractiveIconsPopupProperties> = ({ type, data }) => {
    const [alertEmail, setAlertEmail] = useState(data.reaction.emailReminderStatus === true);
    const [alertSms, setAlertSms] = useState(data.reaction.smsReminderStatus === true);

    const calendar = {
        title: 'Dziś licytacja komornicza - investoro.pl',
        date: `${data.auctionDate
            .split('-')
            .join('')
            .slice(0, 8)}T050000Z/${data.auctionDate
            .split('-')
            .join('')
            .slice(0, 8)}T060000Z`,
        details: `Link do licytacji: <a href="${data.link}">przejdź do licytacji</a>`,
        city: data.city,
    };

    const openAlert = (type: AlertType) => {
        switch (type) {
        case AlertType.EMAIL:
            activeNotification(
                'Powiadomienie email zostało ustawione!',
                'Dzień przed licytacją/przetargiem otrzymasz wiadomość z przypomnieniem na wskazany adres w profilu',
                'success'
            );

            break;

        case AlertType.SMS:
            activeNotification(
                'Powiadomienie SMS zostało ustawione!',
                'Dzień przed licytacją/przetargiem otrzymasz wiadomość z przypomnieniem na wskazany numer w profilu',
                'success'
            );

            break;

        case AlertType.ERROR:
            activeNotification('Coś poszło nie tak', 'Spróbuj ponownie!', 'danger');

            break;

        case AlertType.OFF:
            activeNotification(
                'Udało się!',
                'Przypomnienie zostało wyłaczone',
                'warning'
            );

            break;

        default:
            break;
        }
    };

    const linkCalendar = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${calendar.title}&dates=${calendar.date}&details=${calendar.details}&location=${calendar.city}`;

    const addReminder = async (type: AlertType) => {
        const auctionId = data.auctionId;
        axios({
            method: 'PUT',
            url: `auction/reaction/${auctionId}`,
            data: {
                alertSms: type === AlertType.SMS ? !alertSms : alertSms,
                alertEmail: type === AlertType.EMAIL ? !alertEmail : alertEmail,
            },
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(() => {
                if (type === AlertType.SMS) {
                    if (alertSms) {
                        openAlert(AlertType.OFF);
                        setAlertSms((prevState) => !prevState);
                    } else {
                        openAlert(type);
                        setAlertSms((prevState) => !prevState);
                    }
                } else if (type === AlertType.EMAIL) {
                    if (alertEmail) {
                        openAlert(AlertType.OFF);
                        setAlertEmail((prevState) => !prevState);
                    } else {
                        openAlert(type);
                        setAlertEmail((prevState) => !prevState);
                    }
                }
            })
            .catch(() => {
                openAlert(AlertType.ERROR);
            });
    };

    return (
        <Wrapper>
            {type === 'auction' ?
                <>
                    <InteractiveIcons isLink text={<a target="_blank" rel="noopener noreferrer" href={linkCalendar}>Dodaj do kalendarza google</a>}
                        icon={IconEnum.BLOCKED}/>
                    <InteractiveIcons isLink onClick={() => addReminder(AlertType.EMAIL)} text={'Przypomnienie email'}
                        icon={IconEnum.PHONE}/>
                    <InteractiveIcons isLink onClick={() => addReminder(AlertType.SMS)} text={'Przypomnienie sms'}
                        icon={IconEnum.PEOPLES_3}/>
                </> : null}
        </Wrapper>);
};

export default InteractiveIconsPopup;
