import React, { FC, useEffect, useState } from 'react';
import {
    Bar,
    CartesianGrid,
    ComposedChart, Line,
    Rectangle,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { CurveType } from 'recharts/types/shape/Curve';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
    & > div {
        position: relative;

        .text-layer {
            position: absolute;
            top: 0;
        }
    }
`;

const LegendList = styled.ul`
    list-style: none;
    display: flex;
    margin-top: 10px;
    margin-bottom: 40px;
 
    &.tooltip-legend {
        flex-direction: column;
        padding: 0 20px;

        span,
        p {
            --font-size-body: var(--font-size-body-2);

            margin-bottom: 5px;
            line-height: 20px;
        }

        li {
            margin-right: 0;
        }
    }

    li {
        margin-right: 30px;
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;

        &:nth-of-type(1):before {
            content: "";
            background-color: ${(props) => props.darkMode ? '#CDCED3' : '#0255AA'};
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
        }

        &:nth-of-type(2):before {
            content: "";
            background-color: ${(props) => props.darkMode ? '#7E7F85' : '#A2C0DF'};
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 10px;
        }
    }
`;

type LineBarWrapperProperties = {
    lineBarData: {
        name: string;
        value: number;
        percentage: string;
    }[];
    type: CurveType;
    legendListText?: string[];
}

type CursorProps = {
    x?: number;
    y?: number;
    payloadIndex?: number;
    width?: number;
    height?: number;
}

type TooltipProps = {
    active?: boolean;
    payload?: { payload: { bar: number, line: number } }[];
    label?: string;
}

type TickProps = {
    x?: number;
    y?: number;
    payload?: { value: string; index: number; }
}

const LineBarWrapper: FC<LineBarWrapperProperties> = ({ lineBarData, type, legendListText }) => {
    const { user } = useUser();
    const [activeBarIndex, setActiveBarIndex] = useState<number>();
    const [toolTipPosition, setToolTipPosition] = useState({ x: 0, y: 0 });
    const [barGraphData, setBarGraphData] = useState({ x: 0, y: 0 });
    const [dataVersion, setDataVersion] = useState(0);

    useEffect(() => {
        const barCharts = document.querySelectorAll('.line-bar .recharts-bar-rectangle');
        if (!barCharts || activeBarIndex === undefined) return;
        const barChart = barCharts[activeBarIndex];

        setToolTipPosition({
            x: (barChart.getBoundingClientRect().left - barChart.parentElement!.getBoundingClientRect().left) + 10,
            y: (barChart.getBoundingClientRect().bottom - barChart.parentElement!.getBoundingClientRect().bottom) - 95
        });
    }, [activeBarIndex]);

    useEffect(() => {
        setDataVersion((value) => value + 1);
    }, [lineBarData]);

    const CustomizedDot = (props: { cx: number, cy: number }) => {
        const { cx, cy } = props;

        return (
            <circle cx={cx} cy={cy} r={7} stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeWidth={4}
                fill={user.isDarkMode ? '#0F0F0F' : '#F2F5F9'}/>
        );
    };

    const CustomTooltip = ({ active, payload, label }: TooltipProps) => {

        if (active && payload && payload.length) {

            return (
                <TooltipWrapper>
                    <div>
                        <svg width="290" height="100" viewBox="0 0 290 100" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0 10C0 4.47715 4.47715 0 10 0H264C269.523 0 274 4.47715 274 10V79C274 84.5229 269.523 89 264 89H150.124C147.191 89 144.405 90.2882 142.505 92.5235L138.524 97.2072C137.725 98.1468 136.275 98.1468 135.476 97.2072L131.495 92.5235C129.595 90.2882 126.809 89 123.876 89H10C4.47715 89 0 84.5229 0 79V10Z"
                                fill={user.isDarkMode ? '#0F0F0F' : '#F2F5F9'}/>
                            <path
                                d="M0.5 10C0.5 4.75329 4.75329 0.5 10 0.5H264C269.247 0.5 273.5 4.75329 273.5 10V79C273.5 84.2467 269.247 88.5 264 88.5H150.124C147.044 88.5 144.119 89.8526 142.124 92.1997L138.143 96.8834C137.544 97.5881 136.456 97.5881 135.857 96.8834L131.876 92.1997C129.881 89.8526 126.956 88.5 123.876 88.5H10C4.7533 88.5 0.5 84.2467 0.5 79V10Z"
                                stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity="0.05"/>
                        </svg>
                        <div className="text-layer">
                            <LegendList darkMode={user.isDarkMode} className="tooltip-legend">
                                <p>Przedział {label}</p>
                                {legendListText ? <>
                                    <li>
                                        <span>{new Intl.NumberFormat().format(payload[0].payload.bar)}</span>
                                        {legendListText[0]}
                                    </li>
                                    <li>
                                        <span>{new Intl.NumberFormat().format(payload[0].payload.line)}</span>
                                        {legendListText[1]}
                                    </li>
                                </> : <>
                                    <li>
                                        <span>{new Intl.NumberFormat().format(payload[0].payload.bar)} złotych</span> średnia
                                        cena
                                    </li>
                                    <li>
                                        <span>{new Intl.NumberFormat().format(payload[0].payload.line)} złotych</span> mediana
                                        ceny
                                    </li>
                                </>}
                            </LegendList>
                        </div>
                    </div>
                </TooltipWrapper>
            );
        }

        return null;
    };

    const CustomizedCursor = (props: CursorProps) => {
        const { y, height } = props;

        return (<>
            <defs>
                <linearGradient id="myGradient" gradientTransform="rotate(90)">
                    <stop offset="0%" stopColor={user.isDarkMode ? '#CDCED300' : '#0255AA00'}/>
                    <stop offset="62.5%" stopColor={user.isDarkMode ? '#CDCED31A' : '#0255AA1A'}/>
                </linearGradient>
            </defs>
            <Rectangle radius={[0, 0, 6, 6]} fill="url('#myGradient')" x={barGraphData.x - 30} y={y} width={100}
                height={height! + 35}/></>
        );
    };

    const CustomizedTick = (props: TickProps) => {
        const { x, y, payload } = props;

        return (
            <text x={x} y={y! + 15} fill={user.isDarkMode ? '#F2F5F9' : '#030D45'} fontSize={13}
                fontWeight={payload!.index === activeBarIndex ? 600 : 400}
                textAnchor="middle">
                {payload!.value}
            </text>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart className="line-bar" margin={{ left: 20, bottom: 20, top: 10 }} barGap={10} data={lineBarData}
                onMouseMove={(e) => setActiveBarIndex(e.activeTooltipIndex!)}>
                <CartesianGrid strokeWidth={1} stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity={0.2}
                    vertical={false}
                    strokeDasharray="0"/>
                <defs>
                    <linearGradient id="linearBar" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor={user.isDarkMode ? '#CDCED3' : '#0255AA'}/>
                        <stop offset="62.5%" stopColor={user.isDarkMode ? '#CDCED3' : '#0255AA'}/>
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                        <stop offset="0%" stopColor={user.isDarkMode ? '#CDCED300' : '#0255AA00'}/>
                        <stop offset="62.5%" stopColor={user.isDarkMode ? '#CDCED31A' : '#0255AA1A'}/>
                    </linearGradient>
                </defs>
                <XAxis dataKey="name" tickLine={false}
                    stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeOpacity={0.1} tick={<CustomizedTick/>}/>
                <YAxis dataKey="bar" orientation='left' yAxisId="left" domain={[(dataMin: number) => dataMin - (dataMin * 0.1), 'auto']}
                    tick={{ fill: user.isDarkMode ? '#F2F5F9' : '#030D45', fontWeight: 400, fontSize: 13 }}
                    axisLine={false} tickLine={false}
                    tickFormatter={(x) => new Intl.NumberFormat().format(x)}/>
                <YAxis dataKey="line" orientation='right' yAxisId="right" domain={[(dataMin: number) => dataMin - (dataMin * 0.1), 'auto']}
                    tick={{ fill: user.isDarkMode ? '#F2F5F9' : '#030D45', fontWeight: 400, fontSize: 13 }}
                    axisLine={false} tickLine={false}
                    tickFormatter={(x) => new Intl.NumberFormat().format(x)}/>
                <Tooltip content={<CustomTooltip/>}
                    position={{ x: barGraphData.x - 115, y: toolTipPosition.y }}
                    allowEscapeViewBox={{ x: true, y: true }} cursor={<CustomizedCursor/>}/>
                <Bar yAxisId="left" dataKey="bar" fill="url('#linearBar')" barSize={40} radius={[6, 6, 0, 0]}
                    onMouseOver={(data) => setBarGraphData(data)}/>
                <Line key={`data-${dataVersion}-line`} yAxisId="right" dot={CustomizedDot} type={type} dataKey="line"
                    stroke={user.isDarkMode ? '#F2F5F9' : '#030D45'} strokeWidth={4}/>
            </ComposedChart>
        </ResponsiveContainer>
    );
};

export default LineBarWrapper;
