import { AxiosResponse } from 'axios';
import qs from 'qs';
import { axiosApiInstance, axiosApiInstance2 } from 'Store';
import { formatFilterPrice } from 'utils/formatFilterPrice';
import { getNormalizedPropertyType, NormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { getPropertyByTypeRent } from 'utils/formatters/getPropertyByType';
import { stringToNumber } from 'utils/formatters/stringToNumber';
import { stringToNumberOrNull } from 'utils/formatters/stringToNumberOrNull';
import { Filter, SavedFilter } from 'utils/types/Filter';
import {
    CitiesType,
    LocationOptionsType, SavedLocationsType, SearchLocationResult,
    SearchLocationResultType,
    Voivodeship,
    VoivodeshipCities
} from 'utils/types/Locations';
import { ModulesType } from 'utils/types/ModulesType';
import { RentModuleType, RentOfferRequestData } from 'utils/types/Rent';

import { activeNotification } from 'components/functions/activeNotification';
import { OfferRequestData } from 'components/functions/advertisementsFuncs';

const getFilterEndpointByType = (type: ModulesType) => {
    switch (type) {
    case 'advertisement':
        return 'advertisement';
    case 'auction':
    case 'tender':
        return 'auction';
    case 'bailiff_notice':
        return 'bailiff_notice';
    case 'cooperative':
        return 'cooperative';
    case 'analysis':
        return 'analysis';
    case 'rent':
    default:
        return 'rent';
    }
};

const getSavedListRentFilters = async (): Promise<SavedFilter[]> => {
    return axiosApiInstance2
        .get<SavedFilter[]>('/rent-offer/filter/list')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name,
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getSavedListOtherFilters = async (type: ModulesType): Promise<Filter[]> => {
    return axiosApiInstance
        .get<Filter[]>(`/new-front/filter/find/all?module=${getFilterEndpointByType(type)}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    name: el.name || el.city,
                    filterId: el.filterId,
                    alertSms: el.alertSms,
                    alertEmail: el.alertEmail,
                    pushAlert: el.pushAlert,
                    manyLocations: el.manyLocations,
                }));
            }

            return [];
        })
        .catch((err) => {
            if (err?.response?.status === 401) {
                window.parent.location.reload();
            }

            return [];
        });
};

const getSavedListFilters = async (type: ModulesType): Promise<SavedFilter[] | Filter[]> => {
    if (type === 'rent') {
        return await getSavedListRentFilters();
    } else {
        return await getSavedListOtherFilters(type);
    }
};

const getVoivodeships = async (): Promise<LocationOptionsType[]> => {
    return axiosApiInstance2
        .get<Voivodeship[]>('/location/voivodeships')
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    label: el.name,
                    fullName: el.name,
                    regionNumber: null,
                    cities: [],
                }));
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const getVoivodeshipCities = async (id: number): Promise<CitiesType[]> => {
    return axiosApiInstance2
        .get<VoivodeshipCities[]>(`/location/voivodeship-cities?voivodeship=${id}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el.city,
                    label: el.city.name,
                    districts: el.districts?.map((district) => ({
                        ...district,
                        label: district.name,
                    })),
                }));
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const getLocationsFromSearch = async (search: string): Promise<SearchLocationResultType[]> => {
    return axiosApiInstance2
        .get<SearchLocationResult[]>(`/location/match?location=${search}`)
        .then((resp) => {
            if (resp.data) {
                return resp.data.map((el) => ({
                    ...el,
                    label: el.name,
                    fullName: el.name,
                }));
            }

            return [];
        })
        .catch(() => {
            return [];
        });
};

const removeSavedFilterRent = (filterId: number, adType: RentModuleType) => {
    return axiosApiInstance2
        .delete(`/rent-offer/filter/delete?id=${filterId}&module=${getPropertyByTypeRent(adType)}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilterOther = (filterId: string, type: ModulesType): Promise<void> => {
    return axiosApiInstance
        .delete(`/new-front/filter/delete/${filterId}?module=${getFilterEndpointByType(type)}`)
        .then(() => {
            activeNotification('Udało się!', 'Filtr został usunięty', 'success');
        })
        .catch(() => {
            activeNotification('Problem z usunięciem', 'Niestety nie udało się usunąć danych. Spróbuj ponownie później', 'danger');
        });
};

const removeSavedFilter = (filterId: number | string, type: ModulesType, module?: RentModuleType): Promise<void> => {
    if (type === 'rent' && module) {
        return removeSavedFilterRent(Number(filterId), module);
    } else {
        return removeSavedFilterOther(String(filterId), type);
    }
};

const addFilterRent = (params: RentOfferRequestData, propertyType: NormalizedPropertyType): Promise<AxiosResponse<number>> => {
    const savedLocations = Object.values(params.savedLocations).map((x) => {
        // @ts-expect-error dont need districts and cities
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { districts, cities, ...rest } = x;

        return { ...rest };
    });

    return axiosApiInstance2({
        method: 'post',
        url: `/rent-offer/filter/add/${propertyType}`,
        data: {
            ...params,
            city: savedLocations,
            priceFrom: stringToNumberOrNull(params.priceFrom),
            priceTo: stringToNumberOrNull(params.priceTo),
            areaFrom: stringToNumberOrNull(params.areaFrom),
            areaTo: stringToNumberOrNull(params.areaTo),
            pricePerSquareMeterFrom: stringToNumberOrNull(params.pricePerSquareMeterFrom),
            pricePerSquareMeterTo: stringToNumberOrNull(params.pricePerSquareMeterTo),
            rentPriceFrom: stringToNumberOrNull(params.rentPriceFrom),
            rentPriceTo: stringToNumberOrNull(params.rentPriceTo),
            depositPriceFrom: stringToNumberOrNull(params.depositPriceFrom),
            depositPriceTo: stringToNumberOrNull(params.depositPriceTo),
            daysAmount: params.dateRangeFilter ? null : params.daysAmount,
            offerAddedFrom: params.dateRangeFilter ? params.offerAddedFrom : null,
            offerAddedTo: params.dateRangeFilter ? params.offerAddedTo : null,
            notificationsDelay: params.notificationsDelay ? stringToNumber(params.notificationsDelay) : 0,
        },
        paramsSerializer: function (params) {
            return qs.stringify(params);
        },
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Błąd',
                    err.response.data,
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const addFilterOther = (params: OfferRequestData): Promise<AxiosResponse<number>> => {
    return axiosApiInstance({
        method: 'post',
        url: '/new-front/filter/create',
        params: { module: getFilterEndpointByType(params.module!) },
        data: {
            ...params,
            priceFrom: params.priceFrom ?
                typeof params.priceFrom !== 'number' ? Number(params.priceFrom.replace(/\s/g, '').replace(',', '.')) : params.priceFrom
                : null,
            priceTo: params.priceTo ?
                typeof params.priceTo !== 'number' ? Number(params.priceTo.replace(/\s/g, '').replace(',', '.')) : params.priceTo
                : null,
            areaFrom: params.areaFrom ?
                typeof params.areaFrom !== 'number' ? Number(params.areaFrom.replace(',', '.')) : params.areaFrom
                : null,
            areaTo: params.areaTo ?
                typeof params.areaTo !== 'number' ? Number(params.areaTo.replace(',', '.')) : params.areaTo
                : null,
            pricePerSquareMeterFrom: params.pricePerSquareMeterFrom ?
                typeof params.pricePerSquareMeterFrom !== 'number' ? Number(params.pricePerSquareMeterFrom.replace(/\s/g, '').replace(',', '.')) : params.pricePerSquareMeterFrom
                : null,
            pricePerSquareMeterTo: params.pricePerSquareMeterTo ?
                typeof params.pricePerSquareMeterTo !== 'number' ? Number(params.pricePerSquareMeterTo.replace(/\s/g, '').replace(',', '.')) : params.pricePerSquareMeterTo
                : null,
            rentPriceFrom: params.rentPriceFrom ?
                typeof params.rentPriceFrom !== 'number' ? Number(params.rentPriceFrom.replace(/\s/g, '')) : params.rentPriceFrom
                : null,
            rentPriceTo: params.rentPriceTo ?
                typeof params.rentPriceTo !== 'number' ? Number(params.rentPriceTo.replace(/\s/g, '')) : params.rentPriceTo
                : null,
            depositPriceFrom: params.depositPriceFrom ?
                typeof params.depositPriceFrom !== 'number' ? Number(params.depositPriceFrom.replace(/\s/g, '')) : params.depositPriceFrom
                : null,
            depositPriceTo: params.depositPriceTo ?
                typeof params.depositPriceTo !== 'number' ? Number(params.depositPriceTo.replace(/\s/g, '')) : params.depositPriceTo
                : null,
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został dodany', 'success');

            return resp;
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Wykorzystano dostępną ilość filtrów dla Twojego pakietu!',
                    'Zmień pakiet, lub usuń jeden z zapisanych filtrów.',
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const addFilter = async (params: OfferRequestData | RentOfferRequestData, type: ModulesType, propertyType?: RentModuleType): Promise<AxiosResponse<number>> => {
    if (type === 'rent' && propertyType) {
        return await addFilterRent(params as RentOfferRequestData, getNormalizedPropertyType(propertyType));
    } else {
        return await addFilterOther(params as OfferRequestData);
    }
};

const updateAlert = (params: OfferRequestData) => {
    return axiosApiInstance({
        method: 'put',
        url: '/new-front/filter/update/',
        params: { module: getFilterEndpointByType(params.module!) },
        data: {
            ...params,
            priceFrom: params.priceFrom ? Number((params.priceFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            priceTo: params.priceTo ? Number((params.priceTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            areaFrom: params.areaFrom ? Number(params.areaFrom.toString().replace(',', '.')) : null,
            areaTo: params.areaTo ? Number(params.areaTo.toString().replace(',', '.')) : null,
            pricePerSquareMeterFrom: params.pricePerSquareMeterFrom ? Number((params.pricePerSquareMeterFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            pricePerSquareMeterTo: params.pricePerSquareMeterTo ? Number((params.pricePerSquareMeterTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            rentPriceFrom: params.rentPriceFrom ? Number((params.rentPriceFrom as string).replace(/\s/g, '')) : null,
            rentPriceTo: params.rentPriceTo ? Number((params.rentPriceTo as string).replace(/\s/g, '')) : null,
            depositPriceFrom: params.depositPriceFrom ? Number((params.depositPriceFrom as string).replace(/\s/g, '')) : null,
            depositPriceTo: params.depositPriceTo ? Number((params.depositPriceTo as string).replace(/\s/g, '')) : null,
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    })
        .then(() => {
            activeNotification('Udało się!', 'Alert został zaaktualizowany', 'success');
        })
        .catch((err) => {
            if (err?.response?.status === 400) {
                activeNotification(
                    'Wykorzystano dostępną ilość filtrów dla Twojego pakietu!',
                    'Zmień pakiet, lub usuń jeden z zapisanych filtrów.',
                    'danger'
                );

                return Promise.reject(err);
            } else {
                activeNotification(
                    'Coś poszło nie tak!',
                    'Spróbuj ponownie',
                    'danger'
                );

                return Promise.reject(err);
            }
        });
};

const updateFilterRent = async (params: RentOfferRequestData, propertyType: string) => {
    return axiosApiInstance2({
        method: 'put',
        url: `/rent-offer/filter/edit/${propertyType}`,
        params: { id: params.id },
        data: {
            ...params,
            priceFrom: stringToNumber(params.priceFrom),
            priceTo: stringToNumber(params.priceTo),
            areaFrom: stringToNumber(params.areaFrom),
            areaTo: stringToNumber(params.areaTo),
            pricePerSquareMeterFrom: stringToNumber(params.pricePerSquareMeterFrom),
            pricePerSquareMeterTo: stringToNumber(params.pricePerSquareMeterTo),
            rentPriceFrom: stringToNumber(params.rentPriceFrom),
            rentPriceTo: stringToNumber(params.rentPriceTo),
            depositPriceFrom: stringToNumber(params.depositPriceFrom),
            depositPriceTo: stringToNumber(params.depositPriceTo),
            floorFrom: stringToNumber(params.floorFrom),
            floorTo: stringToNumber(params.floorTo),
            daysAmount: params.dateRangeFilter ? null : params.daysAmount,
            offerAddedFrom: params.dateRangeFilter ? params.offerAddedFrom : null,
            offerAddedTo: params.dateRangeFilter ? params.offerAddedTo : null,
            notificationsDelay: params.notificationsDelay ? stringToNumber(params.notificationsDelay) : 0,
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const updateFilterOther = async (params: OfferRequestData) => {
    return axiosApiInstance({
        method: 'put',
        url: '/new-front/filter/update/',
        params: { module: getFilterEndpointByType(params.module!) },
        data: {
            ...params,
            priceFrom: params.priceFrom ? Number((params.priceFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            priceTo: params.priceTo ? Number((params.priceTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            areaFrom: params.areaFrom ? Number(params.areaFrom.toString().replace(',', '.')) : null,
            areaTo: params.areaTo ? Number(params.areaTo.toString().replace(',', '.')) : null,
            pricePerSquareMeterFrom: params.pricePerSquareMeterFrom ? Number((params.pricePerSquareMeterFrom as string).replace(/\s/g, '').replace(',', '.')) : null,
            pricePerSquareMeterTo: params.pricePerSquareMeterTo ? Number((params.pricePerSquareMeterTo as string).replace(/\s/g, '').replace(',', '.')) : null,
            rentPriceFrom: params.rentPriceFrom ? Number((params.rentPriceFrom as string).replace(/\s/g, '')) : null,
            rentPriceTo: params.rentPriceTo ? Number((params.rentPriceTo as string).replace(/\s/g, '')) : null,
            depositPriceFrom: params.depositPriceFrom ? Number((params.depositPriceFrom as string).replace(/\s/g, '')) : null,
            depositPriceTo: params.depositPriceTo ? Number((params.depositPriceTo as string).replace(/\s/g, '')) : null,
        },
        paramsSerializer: function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        },
    })
        .then((resp) => {
            activeNotification('Udało się!', 'Filtr został zaktualizowany', 'success');

            return resp;
        })
        .catch((err) => {
            activeNotification('Problem z zapisem', 'Niestety nie udało się zapisać danych. Spróbuj ponownie później', 'danger');

            return Promise.reject(err);
        });
};

const updateFilter = async (params: OfferRequestData | RentOfferRequestData, type: ModulesType) => {
    if (type == 'rent') {
        await updateFilterRent(params as RentOfferRequestData, getNormalizedPropertyType(params.module! as RentModuleType));
    } else {
        await updateFilterOther(params as OfferRequestData);
    }
};

const getFilterDetailsRent = async (filterId: number, filterPropertyType: RentModuleType): Promise<RentOfferRequestData> => {
    return axiosApiInstance2
        .get(`/rent-offer/filter/detail?module=${filterPropertyType}&id=${filterId}`)
        .then((resp) => {
            const filters = resp.data;
            const savedLocations: SavedLocationsType[] = [];

            filters.city.forEach((city: LocationOptionsType) => {
                // @ts-expect-error asdsad
                savedLocations[city.fullName] = { ...city, label: city.fullName };
            });

            return {
                ...filters,
                city: filters.city ? filters.city : [],
                wantedKeywords: filters.wantedKeywords ? filters.wantedKeywords : [],
                unwantedKeywords: filters.unwantedKeywords ? filters.unwantedKeywords : [],
                wantedPhoneNumbers: filters.wantedPhoneNumbers ? filters.wantedPhoneNumbers : [],
                unwantedPhoneNumbers: filters.unwantedPhoneNumbers ? filters.unwantedPhoneNumbers : [],
                buildingType: filters.buildingType ? filters.buildingType : [],
                typeOfMarket: filters.typeOfMarket ? filters.typeOfMarket : [],
                propertyType: filters.propertyType ? filters.propertyType : [],
                id: filterId,
                name: filters.name ? filters.name : '',
                pushAlert: filters.pushAlert ? filters.pushAlert : false,
                alertEmail: filters.alertEmail ? filters.alertEmail : false,
                alertSms: filters.alertSms ? filters.alertSms : false,
                priceFrom: filters.priceFrom ? formatFilterPrice(filters.priceFrom) : '',
                priceTo: filters.priceTo ? formatFilterPrice(filters.priceTo) : '',
                rentPriceFrom: filters.rentPriceFrom ? formatFilterPrice(filters.rentPriceFrom) : '',
                rentPriceTo: filters.rentPriceTo ? formatFilterPrice(filters.rentPriceTo) : '',
                areaFrom: filters.areaFrom ? filters.areaFrom : '',
                areaTo: filters.areaTo ? filters.areaTo : '',
                pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? formatFilterPrice(filters.pricePerSquareMeterFrom) : '',
                pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? formatFilterPrice(filters.pricePerSquareMeterTo) : '',
                numberOfRoomsFrom: filters.numberOfRoomsFrom ? filters.numberOfRoomsFrom : '',
                numberOfRoomsTo: filters.numberOfRoomsTo ? filters.numberOfRoomsTo : '',
                floorFrom: filters.floorFrom ? filters.floorFrom : '',
                floorTo: filters.floorTo ? filters.floorTo : '',
                builtYearFrom: filters.builtYearFrom ? filters.builtYearFrom : '',
                builtYearTo: filters.builtYearTo ? filters.builtYearTo : '',
                module: filters.module ? filters.module : '',
                tossedUp: filters.tossedUp ? filters.tossedUp : false,
                notificationsDelay: filters.notificationsDelay !== null && filters.notificationsDelay !== undefined
                    ? filters.notificationsDelay.toString()
                    : '0',
                groupDuplicate: filters.groupDuplicate ? filters.groupDuplicate : false,
                includeZeroArea: filters.includeZeroArea ? filters.includeZeroArea : false,
                modification: filters.modification ? true : filters.modification,
                offerAddedFrom: filters.offerAddedFrom ? filters.offerAddedFrom : '',
                offerAddedTo: filters.offerAddedTo ? filters.offerAddedTo : '',
                depositPriceFrom: filters.depositPriceFrom ? formatFilterPrice(filters.depositPriceFrom) : '',
                depositPriceTo: filters.depositPriceTo ? formatFilterPrice(filters.depositPriceTo) : '',
                savedFilter: filters.savedFilter ? filters.savedFilter : false,
                locations: [],
                savedLocations: savedLocations,
                searchLocationResult: [],
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const getFilterDetailsOther = async (filterId: number, type: ModulesType): Promise<RentOfferRequestData> => {
    return axiosApiInstance
        .get(`new-front/filter/find/${filterId}?module=${getFilterEndpointByType(type)}`)
        .then((resp) => {
            const filters = resp.data;

            return {
                ...filters,
                buildingType: filters.buildingType
                    ? filters.buildingType.split(',')
                    : [],
                offerFrom: filters.offerFrom
                    ? filters.offerFrom.split(',')
                    : [],
                typeOfMarket: filters.typeOfMarket
                    ? filters.typeOfMarket.split(',')
                    : [],
                groupDuplicates: filters.groupDuplicates ?? true,
                type: filters.type ? filters.type.split(',') : type !== 'bailiff_notice' && type !== 'cooperative' ? ['Mieszkania'] : [],
                boundaries: filters.boundaries ?? '15,35,50,70,100,#',
                reportType: filters.reportType ? filters.reportType.split(',') : [],
                timeRange: filters.timeRange ? filters.timeRange.split(',') : [],
                priceFrom: filters.priceFrom ? formatFilterPrice(filters.priceFrom) : '',
                priceTo: filters.priceTo ? formatFilterPrice(filters.priceTo) : '',
                pricePerSquareMeterFrom: filters.pricePerSquareMeterFrom ? formatFilterPrice(filters.pricePerSquareMeterFrom) : '',
                pricePerSquareMeterTo: filters.pricePerSquareMeterTo ? formatFilterPrice(filters.pricePerSquareMeterTo) : '',
                rentPriceTo: filters.rentPriceTo ? formatFilterPrice(filters.rentPriceTo) : '',
                rentPriceFrom: filters.rentPriceFrom ? formatFilterPrice(filters.rentPriceFrom) : '',
                depositPriceFrom: filters.depositPriceFrom ? formatFilterPrice(filters.depositPriceFrom) : '',
                depositPriceTo: filters.depositPriceTo ? formatFilterPrice(filters.depositPriceTo) : '',
                builtYearTo: filters.builtYearTo ? filters.builtYearTo : '',
                builtYearFrom: filters.builtYearFrom ? filters.builtYearFrom : '',
                floorTo: filters.floorTo ? filters.floorTo : '',
                floorFrom: filters.floorFrom ? filters.floorFrom : '',
                numberOfRoomsTo: filters.numberOfRoomsTo ? filters.numberOfRoomsTo : '',
                numberOfRoomsFrom: filters.numberOfRoomsFrom ? filters.numberOfRoomsFrom : '',
                areaTo: filters.areaTo ? filters.areaTo : '',
                areaFrom: filters.areaFrom ? filters.areaFrom : '',
            };
        })
        .catch(() => {
            activeNotification('Problem z załadowaniem danych', 'Niestety nie udało się załadować danych. Spróbuj ponownie później', 'danger');
        });
};

const getFilterDetails = async (filterId: number | string, type: ModulesType, filterPropertyType?: RentModuleType): Promise<RentOfferRequestData | OfferRequestData> => {
    if (type == 'rent' && filterPropertyType) {
        return await getFilterDetailsRent(Number(filterId), filterPropertyType);
    } else {
        return await getFilterDetailsOther(Number(filterId), type);
    }
};

const submitRecommendedPage = (message: string) => {
    axiosApiInstance
        .post('sendEmail', { message })
        .then(() => {
            activeNotification('Strona została przekazana', 'Dziękujemy! ', 'success');
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );
        });
};

const getPageInCityWhereScannerWorks = (city: string): Promise<string[]> => {
    return axiosApiInstance
        .get(`cooperative/showScrappedServices?city=${city}`)
        .then((res) => {
            return res.data;
        })
        .catch(() => {
            activeNotification(
                'Coś poszło nie tak!',
                'Spróbuj ponownie',
                'danger'
            );
        });
};

export const filtersApiRequests = {
    getSavedListFilters,
    removeSavedFilter,
    addFilter,
    updateFilter,
    updateAlert,
    getFilterDetails,
    submitRecommendedPage,
    getPageInCityWhereScannerWorks,
    getVoivodeships,
    getVoivodeshipCities,
    getLocationsFromSearch
};
