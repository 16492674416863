import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactionType } from 'store/Offers/State';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import { MessageSenders, UserMessages, UserOffersResponseContent } from 'store/User/State';
import { userApiRequests } from 'utils/api-requests/user';
import UserFiltersContext from 'utils/context/UserFiltersContext';
import { getEnumPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { getMainValue } from 'utils/getMainValue';
import { getSubmainValues } from 'utils/getSubmainValues';
import { useProfileStatus } from 'utils/hooks/useProfileStatus';
import { getValueFromState } from 'utils/state-managment/user/userFilter';
import { ModulesType } from 'utils/types/ModulesType';
import { V2OfferType } from 'utils/types/UsersAdvert';

import { InteractiveElements } from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { CardData } from 'components/common/Card/V2BaseCard';
import { IconEnum } from 'components/common/Icon';
import { Note } from 'components/common/NoteBox';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';
import { TabType } from 'components/common/Tabs/V2Tabs';
import { activeNotification } from 'components/functions/activeNotification';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import {
    userOfferListFunctions
} from 'components/functions/offerListFunctions/userOfferListFunctions/userOfferListFunctions';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

type UsersOfferListProps = {
    showList: boolean;
    showNoItemsText: boolean;
    type: ModulesType;
    limitItems?: number;
}

const UsersOfferList: FC<UsersOfferListProps> = (props) => {
    const { isProfileActive } = useProfileStatus();
    const navigate = useNavigate();
    const { user, dispatch } = useUser();
    const { showList, showNoItemsText, limitItems } = props;
    const { usersAdvertsFiltersState, handleChange } = useContext(UserFiltersContext);
    const [activeAdvertPhoneNumberId, setActiveAdvertPhoneNumberId] = useState<null | string>(null);
    const [activeMessagesBoxId, setActiveMessagesBoxId] = useState('');

    const handleSetActiveNumber = (id: string) => {
        setActiveAdvertPhoneNumberId(id);
    };

    useEffect(() => {
        handleChange('offerType', '');
        UserActions.loadUserOffersAsync(dispatch, 1, 25);
    }, []);

    const [renderList, setRenderList] = useState<RenderList[]>([]);

    const handleChangePage = useCallback((currentPage: number, rows: number = 25) => {
        UserActions.loadUserOffersAsync(dispatch, currentPage, rows, getValueFromState(usersAdvertsFiltersState), usersAdvertsFiltersState.offerType);
    }, [usersAdvertsFiltersState]);

    const handleActiveFiltersChange = () => {
        UserActions.loadUserOffersAsync(dispatch, 2, 25, getValueFromState(usersAdvertsFiltersState), usersAdvertsFiltersState.offerType);
    };

    const handleNotes = async (offer: UserOffersResponseContent) => {
        UserActions.loadNotesAsync(dispatch, getEnumPropertyByTypeUser(offer.module), offer.advertisementId.toString());
    };

    const onMessageClick = (offer: UserOffersResponseContent) => {
        toggleContainerDetails(false, offer);
    };

    const handleDelete = (id: string, offerType: V2OfferType, noteId: string) => {
        UserActions.deleteNote(dispatch, id, offerType, noteId);
    };

    const handleSaveNote = async (value: string, id: string, offerType: V2OfferType, noteId?: string) => {
        await UserActions.updateNote(dispatch, id, offerType, value, noteId);
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: UserOffersResponseContent) => {
        const userReaction = Object.assign({}, offer.reactionDto);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location!;
        UserActions.reactionUpdate(dispatch, offer.advertisementId, city, userReaction as ReactionType, getEnumPropertyByTypeUser(offer.module));
    };

    const handleSendMessages = (message: string, offer: UserOffersResponseContent) => {
        if (!isProfileActive) {
            activeNotification('Twój profil nie został aktywowany', 'Przejdź do ustawień profilu i wypełnij wymagane pola.', 'warning');

            return;
        }

        const messageSender = offer.messageDetail?.adOwner ? MessageSenders.AD_OWNER : MessageSenders.INTERESTED;
        const messageId = offer.messageDetail?.messageDetails ? offer.messageDetail.messageDetails[0].messageId! : undefined;

        userApiRequests.sendMessage(offer.module, messageSender, message, offer.advertisementId, messageId).then((response) => {
            dispatch({
                type: 'SET_ADVERT_MESSAGES',
                payload: {
                    id: offer.advertisementId,
                    messages: response
                }
            });
        });
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: UserOffersResponseContent) => {
        if (!detailsOpen) {
            offer.reactionDto && !offer.reactionDto.viewedStatus ? UserActions.reactionUpdate(dispatch, offer.advertisementId, offer.location!, {
                ...offer.reactionDto,
                openedStatus: true,
                viewedStatus: true,
            } as ReactionType, getEnumPropertyByTypeUser(offer.module)) : null;
            UserActions.loadOfferDetailsAsync(dispatch, getEnumPropertyByTypeUser(offer.module), offer.advertisementId);
        }
    };

    const generateInteractiveElements = (offer: UserOffersResponseContent): InteractiveElements[] => {
        return [
            {
                tooltipText: 'Wyświetlono',
                icon: 'eye' as IconEnum,
                active: offer.reactionDto?.viewedStatus,
                disabled: offer.reactionDto?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reactionDto?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: 'like' as IconEnum,
                active: offer.reactionDto?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reactionDto?.likeStatus, offer),
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: 'dislike' as IconEnum,
                active: offer.reactionDto?.unlikeStatus,
                disabled: offer.reactionDto?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reactionDto?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: 'blocked' as IconEnum,
                active: offer.reactionDto?.hideStatus,
                disabled: offer.reactionDto?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reactionDto?.hideStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: 'phone' as IconEnum,
                active: offer.reactionDto?.phoneStatus,
                disabled: offer.reactionDto?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reactionDto?.phoneStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: 'peoples_3' as IconEnum,
                active: offer.reactionDto?.arrangedStatus,
                disabled: offer.reactionDto?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reactionDto?.arrangedStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: 'message' as IconEnum,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes : undefined,
                openTabOnClick: TabType.NOTES,
                onClick: () => onMessageClick(offer),
            },
            ...(offer.phoneNumber ? [{
                icon: 'phone' as IconEnum,
                isLink: true,
                active: false,
                isPhoneBtn: true,
                text: offer.advertisementId === activeAdvertPhoneNumberId ?
                    <p><a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a></p> : <p>Pokaż numer</p>,
                onClick: () => handleSetActiveNumber(offer.advertisementId)
            }] : []),
            ...(offer.photos && offer.photos.length > 0 ? [{
                icon: 'photos' as IconEnum,
                isModalOpen: true,
                isLink: true,
                isPhotoBtn: true,
                text: <p>Zobacz zdjęcia</p>,
                visibleDuringModal: true,
                sliderElements: {
                    photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                    title: offer.title
                },
            }] : []),
            {
                icon: 'people' as IconEnum,
                visibleDuringModal: true,
                isLink: true,
                isModalOpen: true,
                isUserNameBtn: true,
                text: <p>{offer.username}</p>,
                active: false,
                userPhoto: offer.userPhoto ? `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${offer.userPhoto}&photoSize=MINI_PHOTO` : undefined,
                onClick: () => navigate(`/profile/${offer.profileId}`)
            }
        ];
    };

    const thumbnailedUserPhoto = (userPhoto: string) => {
        return (userPhoto && userPhoto.length > 0) ? `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${userPhoto}&photoSize=MINI_PHOTO` : undefined;
    };

    const generateUsersDetails = (offer: UserOffersResponseContent) => {
        return {
            profileId: offer.profileId,
            userName: offer.username,
            title: offer.title,
            userPhoto: thumbnailedUserPhoto(offer.userPhoto!),
            offerPhoto: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            price: getMainValue(offer),
            showMessageButton: !offer.messageDetail?.adOwner,
            userMessages: offer.messageDetail?.messageDetails?.map<UserMessages>((message) => ({
                content: message.content!,
                messageId: message.messageId!,
                messageSender: MessageSenders[message.messageSender],
                readTime: message.readTime!,
                sendDate: message.sendDate!
            })) || [],
            handleSendMessages: (message: string) => handleSendMessages(message, offer),
        };
    };

    const generateCardData = (offer: UserOffersResponseContent): CardData => {
        return {
            preTitle: offer.location,
            date: offer.dateAdded,
            title: offer.title,
            infoElements: userOfferListFunctions.generateInfoElements(offer),
            interactiveElements: generateInteractiveElements(offer),
            note: offer.note as Note,
            thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
            ratingScore: null,
            images: offer.photos && offer.photos.length > 0 ? createImagesForSlider(offer.photos, offer.module) : undefined,
            mainValue: `${getMainValue(offer)} zł`,
            subMainValues: getSubmainValues(offer),
            showUserOptions: true,
            userDetails: generateUsersDetails(offer),
            showMessages: activeMessagesBoxId === offer.advertisementId,
            setShowMessages: (active) => setActiveMessagesBoxId(active ? offer.advertisementId : ''),
        };
    };

    useEffect(() => {
        if (!showList) return;
        const offersList = user.usersOfferList.content.map((offer): RenderList => {
            const interactiveElements = generateInteractiveElements(offer);
            const detailsTabContent: V2DetailsTabsProps = offer.detailedContent ? {
                title: offer.title,
                address: offer.location,
                detailsElements: userOfferListFunctions.generateDetailsTabElements(offer),
                description: offer.detailedContent.description ? offer.detailedContent.description : '',
                documents: offer.detailedContent.documents,
                handleDocumentClick: async (doc: string) => {
                    await userApiRequests.downloadFile(doc, getEnumPropertyByTypeUser(offer.module));
                }
            } : { detailsElements: [] };

            return {
                id: offer.advertisementId!,
                onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                baseCardContent: {
                    bigImage: true,
                    cardData: generateCardData(offer),
                    openOfferTab: () => {
                    }, // openoffertab
                },
                detailsCardData: {
                    userDetails: generateUsersDetails(offer),
                    isUsersOffersPage: true,
                    interactiveElements,
                    thumbnailPath: offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                    date: offer.dateAdded,
                    onToggleContainerDetails: () => {
                    },
                    tabsContent: {
                        toggleContainerDetails: () => {
                        },
                        interactiveElements,
                        detailsTabContent,
                        notesTabContent: {
                            notes: user.notes,
                            handleDelete: (noteId: string) => handleDelete(offer.advertisementId.toString(), getEnumPropertyByTypeUser(offer.module), noteId),
                            handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.advertisementId.toString(), getEnumPropertyByTypeUser(offer.module), id)
                        },
                        userDetails: generateUsersDetails(offer),
                        messagesTabContent: !offer.messageDetail?.adOwner,
                        handleLoadNotes: () => handleNotes(offer),
                        numberOfNotes: offer.numberOfNotes || 0,
                    }
                }
            };
        });

        setRenderList(offersList);
    }, [user.usersOfferList?.content, activeAdvertPhoneNumberId, activeMessagesBoxId, user.notes]);

    return (
        <V2OfferList
            showList={showList}
            totalPages={limitItems ? 1 : user.usersOfferList?.totalPages}
            pageNumber={limitItems ? 1 : user.usersOfferList?.pageable?.pageNumber || 1}
            lastPage={limitItems ? true : user.usersOfferList?.last}
            showNoItemsText={showNoItemsText}
            renderList={limitItems ? [...renderList].slice(0, limitItems) : renderList}
            handleChangePage={handleChangePage}
            handleActiveFiltersChange={handleActiveFiltersChange}
        />
    );
};

export default UsersOfferList;
