import { FC, useMemo } from 'react';
import React from 'react';

import { BoxContainer } from 'components/sales/SingleAdvertisement';

export type V2AdditionalTabProps = {
    keys: {
        value?: string;
        key: string;
    }[]
}

const V2AdditionalTab: FC<V2AdditionalTabProps> = ({ keys }) => {
    const memoizedKeys = useMemo(() => keys, [keys]);

    return (
        <>
            <BoxContainer>
                {memoizedKeys && memoizedKeys.length > 0 && (
                    <div>
                        <h3>Słowa kluczowe</h3>
                        {memoizedKeys.map((meta) => {
                            return <span key={meta.key}><b>{meta.key}</b> - <p>{meta.value}</p></span>;
                        })}
                    </div>
                )}
            </BoxContainer>
        </>
    );
};

export default V2AdditionalTab;
