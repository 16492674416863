import React, { FC } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from 'utils/useWindowWidth';

import Icon, { IconEnum } from 'components/common/Icon';

import ModificationsContainer, { ModificationsProps } from './ModificationsContainer/ModificationsContainer';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
    grid-area: ${(props) => props.isUpperLabel ? '3 / right' : '2 / right'};
    text-align: right;
`;

const OldPriceValue = styled.h4`
    text-decoration-line: line-through;
    font-weight: 500;
    margin: 0;
    letter-spacing: 1.4px;
    line-height: 20px;
    color: var(--color-primary);
`;

const MainValue = styled.h1`
    font-weight: 600;
    font-size: 19px;
    line-height: inherit;
    color: var(--color-alt);
`;

const Link = styled.a`
    display: flex;
    gap: 5px;
    width: min-content;
    text-decoration: none;
    color: var(--color-primary);
`;

const SubMainValues = styled.div`
    display: flex;
    gap: 5px;
    color: var(--color-primary);
    font-weight: 400;

    b {
        font-weight: 500;
    }
`;

export type LinkProps = {
    name: string;
    url: string;
}

type SubMainValues = (React.ReactNode | string)[]

type PriceContainerProps = {
    isUpperLabel?: boolean;
    oldPrice?: string;
    pricePerSquareMeter?: string;
    deposit?: string;
    category?: string;
    link?: LinkProps;
    modifications?: ModificationsProps;
    mainValue?: string;
    subMainValues?: SubMainValues;

}

const PriceContainer: FC<PriceContainerProps> = ({
    isUpperLabel,
    oldPrice,
    link,
    modifications,
    mainValue,
    subMainValues

}) => {
    const preventDefaultHandle = (e: Event) => {
        e.stopPropagation();
    };
    const width = useWindowWidth();

    return (
        <Wrapper isUpperLabel={isUpperLabel}>
            {oldPrice ? <OldPriceValue>{`${oldPrice} zł`} </OldPriceValue> : null}
            <MainValue>
                {mainValue}
            </MainValue>
            {subMainValues ? subMainValues.map((value, index) => <SubMainValues key={index}>{value}</SubMainValues> ) : null}

            {link ? <Link href={link.url} target="_blank" rel="noopener noreferrer"
                onClick={preventDefaultHandle}>
                <Icon icon={IconEnum.LINK}/>{link.name}
            </Link> : null}
            {width > 991 && modifications ? <ModificationsContainer modifications={modifications} /> : null}
        </Wrapper>
    );
};

export default PriceContainer;
