import { SavedFilter } from './Filter';
import {
    BaseStateType
} from './FiltersStateType';
import { MultipleFilterType } from './InputTypes';
import { CitiesType, LocationOptionsType, SavedLocationsType, SearchLocationResultType } from './Locations';
import { SortType } from './SortType';

export enum RentModuleType {
    RENT_FLAT = 'RENT_FLAT',
    RENT_HOUSE = 'RENT_HOUSE',
    RENT_PLOT = 'RENT_PLOT',
    RENT_OTHER = 'RENT_OTHER',
}

export interface RentFiltersStateType extends BaseStateType {
    id: number | null,
    depositPrice: MultipleFilterType,
    rentPrice: MultipleFilterType,
    city: CitiesType[];
    tossedUp: boolean,
    notificationsDelay: string,
    groupDuplicate: boolean,
    includeZeroArea: boolean,
    savedFilter: [] | SavedFilter[]
    module: RentModuleType,
    modification: boolean,
    offerAdded: MultipleFilterType,
    wantedPhoneNumbers: string[],
    unwantedPhoneNumbers: string[],
    offerFrom: string[],
    locations: LocationOptionsType[],
    searchLocationResult: SearchLocationResultType[],
    savedLocations: SavedLocationsType,
    floors: MultipleFilterType,
    sortType?: SortType,
}

export type RentOfferRequestData = {
    city: CitiesType[];
    wantedKeywords: string[],
    unwantedKeywords: string[],
    wantedPhoneNumbers: string[],
    unwantedPhoneNumbers: string[],
    buildingType: string[] | null,
    typeOfMarket: string[],
    offerFrom: string[],
    id: number | null,
    name: null | string,
    pushAlert: boolean,
    alertEmail: boolean,
    alertSms: boolean,
    priceFrom: string | number | null,
    priceTo: string | number | null,
    rentPriceFrom: string | number | null,
    rentPriceTo: string | number | null,
    areaFrom: string | number | null,
    areaTo: string | number | null,
    pricePerSquareMeterFrom: string | number | null,
    pricePerSquareMeterTo: string | number | null,
    numberOfRoomsFrom?: string | number | null,
    numberOfRoomsTo?: string | number | null,
    floorFrom?: string | number | null,
    floorTo?: string | number | null,
    floorsFrom?: string | number | null,
    floorsTo?: string | number | null,
    builtYearFrom: string | number | null,
    builtYearTo: string | number | null,
    module: RentModuleType,
    tossedUp: boolean,
    notificationsDelay: string,
    groupDuplicate: boolean,
    includeZeroArea: boolean,
    modification: boolean,
    offerAddedFrom: string | null;
    offerAddedTo: string | null;
    depositPriceFrom: string | number | null,
    depositPriceTo: string | number | null,
    savedFilter?: [] | SavedFilter[],
    locations: LocationOptionsType[],
    searchLocationResult: SearchLocationResultType[],
    savedLocations: SavedLocationsType,
    daysAmount: number,
    sortType?: SortType,
    dateRangeFilter: boolean,
}
