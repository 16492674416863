import React, { FC } from 'react';
import clsx from 'clsx';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const Wrapper = styled.div`
    display: flex;
    gap: 10px;
    padding: 15px 17px;
    background: var(--color-alt-second);
    border-radius: var(--border-radius);

    &.active,
    &:hover {
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        cursor: pointer;

        img {
            filter: none;
        }

        .title {
            color: var(--color-alt-second);
        }
    }

    img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
    }

    .title {
        --font-size-body: var(--font-size-body-2);
        line-height: 20px;
    }
`;

type ModuleProperties = {
    title: string;
    icon: IconEnum;
    onClick?: () => void;
    isActive?: boolean;
}

const Module: FC<ModuleProperties> = ({ title, icon, onClick, isActive }) => {
    const { user } = useUser();

    return (
        <Wrapper darkMode={user.isDarkMode} className={clsx({ 'active': isActive })} onClick={onClick}>
            <Icon icon={icon}/>
            <span className='title'>{title}</span>
        </Wrapper>
    );
};

export default Module;
