import { FC, useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import dayjs from 'dayjs';
import { MessageSenders, UserMessages } from 'store/User/State';
import styled from 'styled-components';
import { ImageSize } from 'utils/enums/imageSize';

import Icon, { IconEnum } from 'components/common/Icon';
import TextInput from 'components/common/Inputs/TextInput';
import TrashWithModal from 'components/common/TrashWithModal';
import UserPhotoIcon from 'components/common/UserPhotoIcon';

import 'dayjs/locale/pl';

import ChatWindow from './ChatWindow';

const Top = styled.div`
    border-radius: var(--box-border-radius);
    border: 3px solid var(--color-white);
    background: var(--color-alt-second);
    display: flex;
    flex-direction: column;
`;

const Line = styled.hr`
    border: 1px solid #030D451A;
    border-radius: var(--box-border-radius);
    width: 100%;
    height: 1px;
    margin: 0;
`;

const Header = styled.div`
    padding: 20px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const User = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 20px;
    color: var(--color-primary);

    p {
        margin-left: 10px;
    }
`;

const OfferInfo = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 15px;

    img {
        width: 50px;
        height: 40px;
        border-radius: var(--image-border-radius);
        margin-right: 10px;
        object-fit: cover;
    }

    p {
        --font-size-body: 11px;

        line-height: 20px;
        margin: 0;
    }
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
`;

const Price = styled.p`
    --font-weight: 600;

    color: var(--color-alt);
`;

const Body = styled.div`
    padding: 0 15px;
    max-height: ${(props) => props.isPopup ? '200px' : '450px' };
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--color-alt-second-10) var(--color-background);
    scroll-behavior: smooth;
`;

const DateSection = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
`;

const SenderMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;

    div {
        background: var(--color-alt);

        p {
            color: var(--color-white);
        }
    }
`;

const ReceiverMessage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;

    div {
        background: var(--color-alt-second);

        p {
            color: var(--color-primary);
        }
    }
`;

const Message = styled.div`
    padding: 10px 15px;
    border-radius: 4px;
    margin-bottom: 2px;

    p {
        --font-size-body: var(--font-size-body-2);
        --font-weight: 400;
    }
`;

const Hour = styled.p`
    --font-size-body: 11px;
    --font-weight: 400;
`;

const Bottom = styled.div`
    border-radius: 10px;
    border: 3px solid #FFF;
    background: #F2F5F9;

    & .focus {
        border-radius: 10px;
    }
`;

type MessagesList = {
    [key: string]: UserMessages[];
}

type MessagesProps = {
    referenceElement?: Element | null;
    onClose?: (e: React.MouseEvent) => void;
    onRemove?: () => void;
    username: string;
    photo: string;
    userPhoto?: string;
    title: string;
    price: string;
    onSendMessage: (message: string) => void;
    messages: UserMessages[];
    messageOwnerType: MessageSenders;
    isPopup?: boolean;
    isTab?: boolean;
}

const Messages: FC<MessagesProps> = ({
    referenceElement,
    onClose,
    onRemove,
    username,
    userPhoto,
    photo,
    title,
    price,
    onSendMessage,
    messages,
    messageOwnerType,
    isPopup,
    isTab
}) => {
    const [message, setMessage] = useState<string>('');
    const messagesRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        if (messagesRef.current) {
            messagesRef.current?.scrollTo(0, messagesRef.current?.scrollHeight);
        }
    };

    const sortByHours = (messages: UserMessages[]) => {
        return messages.sort((a, b) => new Date(a.sendDate).getTime() - new Date(b.sendDate).getTime());
    };

    const sortMessages = useCallback((messages?: UserMessages[]) => {
        const sortedMessages: MessagesList = [] as unknown as MessagesList;
        messages?.forEach((message) => {
            const date = message.sendDate.split('T');

            sortedMessages[date[0]] = [...sortedMessages[date[0]] || [], message];
        });

        return sortedMessages;
    }, [messages]);

    const [messagesList, setMessagesList] = useState<MessagesList>(sortMessages(messages));

    useEffect(() => {
        setMessagesList(sortMessages(messages));
    }, [messages]);

    useEffect(() => {
        scrollToBottom();
    }, [messagesList]);

    const onChangeMessage = (message: string) => {
        setMessage(message);
    };

    const handleSendMessage = () => {
        setMessage('');
        onSendMessage(message);
    };

    const formatToHour = useCallback((date?: string) => {
        return date && dayjs(date).format('HH:mm');
    }, [messagesList]);

    return (
        <ChatWindow referenceElement={referenceElement} isPopup={isPopup} isTab={isTab}>
            <Top>
                <Header>
                    <User>
                        <UserPhotoIcon img={userPhoto} />
                        <p>{username}</p>
                    </User>
                    {onClose && <Icon icon={IconEnum.CROSS} onClick={onClose}/>}
                    {onRemove &&
                        <TrashWithModal
                            onChange={onRemove}
                            title="Usuwanie wiadomości"
                            description="Czy na pewno chcesz usunąć tę wiadomość?"
                            filter=""
                        />
                    }
                </Header>
                {isTab ? null : (
                    <>
                        <Line/><OfferInfo>
                            <img src={photo.replace('PHOTO_SIZE', ImageSize.NORMAL_PHOTO)} alt={'offer'}/>
                            <Description>
                                <p>{title}</p>
                                <Price>{price} zł</Price>
                            </Description>
                        </OfferInfo>
                    </>
                )}
            </Top>
            <Body ref={messagesRef} isPopup={isPopup}>
                {messagesList && Object.keys(messagesList).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map((date) => (
                    <React.Fragment key={date}>
                        <DateSection>
                            <Line/>
                            <p>{dayjs(date).locale('pl').format('D MMM').toUpperCase()}</p>
                            <Line/>
                        </DateSection>
                        {sortByHours(messagesList[date]).map((message, index) => (
                            messageOwnerType === message.messageSender ?
                                <SenderMessage key={message.messageId}>
                                    <Message>
                                        <p>{message.content}</p>
                                    </Message>
                                    {formatToHour(messagesList[date][index + 1]?.sendDate) !== formatToHour(message.sendDate) &&
                                        <Hour>{formatToHour(message.sendDate)}</Hour>}
                                </SenderMessage>
                                :
                                <ReceiverMessage key={message.messageId}>
                                    <Message>
                                        <p>{message.content}</p>
                                    </Message>
                                    {formatToHour(messagesList[date][index + 1]?.sendDate) !== formatToHour(message.sendDate) &&
                                        <Hour>{formatToHour(message.sendDate)}</Hour>}
                                </ReceiverMessage>
                        ))}
                    </React.Fragment>
                ))}
            </Body>
            <Bottom>
                <TextInput
                    value={message}
                    onChange={(e) => onChangeMessage(e.target.value)}
                    placeholder={'Napisz wiadomość...'}
                    onAction={handleSendMessage}
                    actionIcon={IconEnum.SEND}
                />
            </Bottom>
        </ChatWindow>
    );
};

export default Messages;
