import { FC } from 'react';
import React from 'react';
import styled from 'styled-components';

import Icon, { IconEnum } from './Icon';

const StyledImg = styled.img`
    width: 30px;
    height: 30px;
    border-radius: var(--border-radius);
    object-fit: cover;
`;

type UserPhotoIconPropsType = {
    img?: string;
};

const UserPhotoIcon: FC<UserPhotoIconPropsType> = ({ img }) => {
    return img ? <StyledImg src={img} alt={'user-photo'} className="user-photo-icon-img" /> : <Icon icon={IconEnum.USER} aria-label="User icon"/>;
};

export default UserPhotoIcon;