import dayjs from 'dayjs';
import { getArrayFilters } from 'utils/generateActiveFiltersContent';
import { BailiffFilterReturnData, BailiffFiltersStateType } from 'utils/types/Bailiff';
import { BailiffNoticeSearchingRequestSortTypeEnum, FilterLocation } from 'utils/types/BailiffModels';
import { CitiesType, DistrictType } from 'utils/types/Locations';

import { FilterItem } from 'components/common/V2ActiveFilters';
import { dateFormat, dateFromFunc, dateToFunc } from 'components/FilterFunctions';

export const setBailiffStateValues = (state: BailiffFilterReturnData): BailiffFiltersStateType => {
    const dateFromWithDefault = state.dateAddedFrom || dayjs().subtract(3, 'day').toISOString();

    return {
        filterId: state.filterId === 0 ? null : state.filterId,
        wantedKeywords: state.wantedKeywords ?? [],
        unwantedKeywords: state.unwantedKeywords ?? [],
        locations: state.locations ?? [],
        officeLocations: state.officeLocations ?? [],
        offerAdded: {
            from: dateFromWithDefault && dateFromWithDefault.includes('T') ? dateFromWithDefault.split('T')[0] : dateFromWithDefault,
            to: state.dateAddedTo && state.dateAddedTo.includes('T') ? state.dateAddedTo.split('T')[0] : state.dateAddedTo || '',
        },
        types: state.types ?? undefined,
        savedFilter: state.savedFilter ?? [],
        daysAmount: state.daysAmount ?? 3,
        dateRangeFilter: !!state.dateAddedTo,
        includeZeroArea: state.includeZeroArea ?? true,
        notificationsDelay: state.notificationsDelay?.toString() ?? '',
        alertSms: state.alertSms ?? false,
        alertEmail: state.alertEmail ?? false,
        alertPush: state.alertPush ?? false,
        name: state.name ?? '',
        module: state.module ?? 'BAILIFF_NOTICE',
        city: state.city ?? [],
        officeCity: state.officeCity ?? [],
        searchLocationResult: [],
        searchOfficeLocationResult: [],
        savedLocations: state.savedLocations ?? {},
        savedOfficeLocations: state.savedOfficeLocations ?? {},
        sortType: state.sortType,
    };
};

export const getBailiffValueFromState = (state: BailiffFiltersStateType ): BailiffFilterReturnData => {
    return {
        filterId: state.filterId!,
        name: state.name || '',
        notificationsDelay: Number(state.notificationsDelay),
        alertSms: state.alertSms,
        alertEmail: state.alertEmail,
        alertPush: state.alertPush,
        locations: state.locations,
        officeLocations: state.officeLocations,
        wantedKeywords: state.wantedKeywords,
        unwantedKeywords: state.unwantedKeywords,
        types: state.types,
        daysAmount: state.dateRangeFilter ? undefined : state.daysAmount,
        dateAddedFrom: state.dateRangeFilter ? dateFormat(dateToFunc(state.offerAdded.from)) : undefined,
        dateAddedTo: state.dateRangeFilter && state.offerAdded.to ? dateFormat(dateFromFunc(state.offerAdded.to)) : undefined,
        includeZeroArea: state.includeZeroArea,
        module: state.module,
        savedLocations: state.savedLocations,
        savedOfficeLocations: state.savedOfficeLocations,
        searchLocationResult: state.searchLocationResult,
        searchOfficeLocationResult: state.searchOfficeLocationResult,
        sortType: BailiffNoticeSearchingRequestSortTypeEnum.DATE_DESC,
        city: state.city,
        officeCity: state.officeCity,
    };
};

const bailiffFilterInitialState = setBailiffStateValues({
    filterId: 0,
    name: '',
    unwantedKeywords: [],
    wantedKeywords: [],
    locations: [],
    city: [],
    officeCity: [],
    officeLocations: [],
    types: [],
    alertSms: false,
    alertEmail: false,
    alertPush: false,
    savedFilter: [],
    notificationsDelay: 0,
    includeZeroArea: true,
    dateAddedFrom: '',
    dateAddedTo: '',
    daysAmount: 3,
    module: 'BAILIFF_NOTICE',
    savedLocations: {},
    savedOfficeLocations: {},
    searchLocationResult: [],
    searchOfficeLocationResult: [],
    sortType: BailiffNoticeSearchingRequestSortTypeEnum.DATE_DESC
});

export const initialState = {
    bailiffFilterInitialState: () => {
        return bailiffFilterInitialState;
    }
};

export const getBailiffFiltersFromState = (state: BailiffFiltersStateType): FilterItem[] => {
    return [
        ...getArrayFilters('Lokalizacja', Object.values(state.savedLocations).map((x: CitiesType | DistrictType | FilterLocation) => x.name ?? '').filter(Boolean), 'savedLocations', true),
        ...getArrayFilters('Lokalizacja sądu', Object.values(state.savedOfficeLocations).map((x: CitiesType | DistrictType | FilterLocation) => x.name ?? '').filter(Boolean), 'savedOfficeLocations', true),
        ...getArrayFilters('Typ nieruchomości', state.types || [], 'types'),
        ...getArrayFilters('Słowa pożądane', state.wantedKeywords, 'wantedKeywords'),
        ...getArrayFilters('Słowa niepożądane', state.unwantedKeywords, 'unwantedKeywords'),
        ...(state.daysAmount && !state.dateRangeFilter ? [{ label: 'Ogłoszenia od', value: state.daysAmount === -1 ? 'wszystkie dni' : `${state.daysAmount} dni`, noDeleteButton: true }] : []),
        ...(state.dateRangeFilter ? [
            { label: 'Data od', value: state.offerAdded.from, noDeleteButton: true },
            { label: 'Data do', value: state.offerAdded.to, filterName: 'offerAdded' }
        ] : []),
    ];
};

