import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ReactionType } from 'store/Offers/State';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import {
    MessageSenders,
    UserMessages,
    UserProfileOffersResponseContent,
} from 'store/User/State';
import styled from 'styled-components';
import { userApiRequests } from 'utils/api-requests/user';
import { getEnumPropertyByTypeUser } from 'utils/formatters/getPropertyByType';
import { getMainValue } from 'utils/getMainValue';
import { getSubmainValues } from 'utils/getSubmainValues';
import { useProfileStatus } from 'utils/hooks/useProfileStatus';
import { V2OfferType } from 'utils/types/UsersAdvert';

import Indicator from 'components/atom/Indicator';
import AccordionCard from 'components/common/AccordionCard';
import {
    InteractiveElements
} from 'components/common/Card/common/V2InteractiveIconsWrapper/V2InteractiveIconsWrapper';
import { CardData } from 'components/common/Card/V2BaseCard';
import FiltersContentWrapper from 'components/common/FiltersContentWrapper';
import Icon, { IconEnum } from 'components/common/Icon';
import { Note } from 'components/common/NoteBox';
import { SliderImages } from 'components/common/Slider/Slider';
import { V2DetailsTabsProps } from 'components/common/Tabs/tabs/V2DetailsTab';
import { activeNotification } from 'components/functions/activeNotification';
import { convertToThumbnailPath, createImagesForSlider } from 'components/functions/imagesFunctions';
import {
    userOfferListFunctions
} from 'components/functions/offerListFunctions/userOfferListFunctions/userOfferListFunctions';
import HeaderSection from 'components/HeaderSection';
import V2OfferList, { RenderList } from 'components/offerList/V2OfferList';

const Wrapper = styled.div`
    .active .accordion-card-content {
        row-gap: 10px;
    }
`;

const SideContainer = styled.div`
    padding-left: 20px;

    @media (max-width: 1100px) {
        padding-right: 20px;
    }
`;

const InfoContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
        margin-top: 15px;
        @media (max-width: 1100px) {
            margin-top: 0;
        }
    }
`;

const ImageContainer = styled.div`
    background: var(--color-alt-second);
    border-radius: var(--box-border-radius);
    overflow: hidden;
    position: relative;
    width: 100%;
    max-width: 315px;
 `;

const ProfileImg = styled.img`
    object-fit: cover;
    width: 100%;
    height: 300px;
    border-radius: var(--box-border-radius);
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: var(--font-weight);
  font-size: 20px;
  line-height: 30px;

    color: var(--color-primary);
    padding: 20px 30px;

    @media (max-width: 1100px) {
        padding: 20px 15px;
    }

    &:first-child {
        align-self: flex-start;
    }
`;

const StyledLink = styled.a`
    color: var(--color-primary);
    text-decoration: none;

        &:hover {
            color: white;
        }
`;

const DetailsInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: auto;
    align-items: center;
    border-radius: var(--box-border-radius);
    color: var(--color-primary);
    background: var(--color-white);
    word-break: break-all;    
`;

const DetailsElement = styled.div`
    display: flex;
    padding: 5px 20px;
    align-items: center;
    width: 100%;
    min-height: 50px;
    
    &:not(:last-child) {
      border-bottom: 2px solid var( --color-alt-second); 
    }
        
    &:last-child {
        padding-bottom: 10px;
    }
        
    p {
        padding: 15px;
        text-align: justify;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    margin-left: auto;
    align-items: center;
    padding: 20px;
    border-radius: 50px;
    color: var(--color-primary);
    gap: 10px;
    background-color: var(--color-alt-second);
    min-width: 40px;
    height: 50px;
    
    &:hover a {
        color: white;
    }  

  p {
    padding: 15px;
  }
  
  &:hover {
    transition: background-color ease-in .2s;
    background-color: var(--color-alt);
    cursor: pointer;

        p {
            color: var(--color-white);
        }

        img {
            filter: ${(props) => props.darkMode ? 'unset' : 'invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%)'};
        }
    }
`;

const OwnerProfilePage = () => {
    const { isProfileActive } = useProfileStatus();
    const { user, dispatch } = useUser();
    const { id } = useParams();
    const [renderList, setRenderList] = useState<RenderList[]>([]);
    const [isVisiblePhoneNumber, setIsVisiblePhoneNumber] = useState(false);
    const [activeMessagesBoxId, setActiveMessagesBoxId] = useState('');

    const [activeAdvertPhoneNumberId, setActiveAdvertPhoneNumberId] = useState<null|string>(null);

    const handleSetActiveNumber = (id: string) => {
        setActiveAdvertPhoneNumberId(id);
    };

    useEffect(() => {
        UserActions.getOwnerProfile(dispatch, id, 1, 25);
    }, [id]);

    const handleChangePage = useCallback((currentPage: number, rows: number = 2) => {
        UserActions.getOwnerProfile(dispatch, id, currentPage, rows);
    }, [dispatch]);

    const onNoteAdd = () => {
        // if (!showNote && showLastNote) {D
        //     showLastNote();
        // }
        //
        // if (!data.note?.noteId) {
        //     openOfferTab('note');
        // }
    };

    const onMessageClick = () => {
        onNoteAdd();
    };

    const handleDelete = (id: string, offerType: V2OfferType, noteId: string) => {
        UserActions.deleteProfileOffersNote(dispatch, id, offerType, noteId);
    };

    const handleSaveNote = async (value: string, id: string, offerType: V2OfferType, noteId?: string) => {
        await UserActions.updateProfileOffersNote(dispatch, id, offerType, value, noteId);
    };

    const handleNotes = async (offer: UserProfileOffersResponseContent) => {
        UserActions.loadNotesAsync(dispatch, getEnumPropertyByTypeUser(offer.module), offer.advertisementId.toString());
    };

    const handleSendMessages = (message: string, offer: UserProfileOffersResponseContent) => {
        if (!isProfileActive) {
            activeNotification('Twój profil nie został aktywowany', 'Przejdź do ustawień profilu i wypełnij wymagane pola.', 'warning');

            return;
        }

        const messageSender = offer.messageDetail?.adOwner ? MessageSenders.AD_OWNER : MessageSenders.INTERESTED;
        const messageId = offer.messageDetail?.messageDetails ? offer.messageDetail.messageDetails[0].messageId! : undefined;

        userApiRequests.sendMessage(offer.module as string, messageSender, message, offer.advertisementId, messageId).then((response) => {
            dispatch({
                type: 'SET_ADVERT_MESSAGES',
                payload: {
                    id: offer.advertisementId,
                    messages: response
                }
            });
        });
    };

    const toggleContainerDetails = (detailsOpen: boolean, offer: UserProfileOffersResponseContent) => {
        const offerType = getEnumPropertyByTypeUser(offer.module);

        if (!detailsOpen) {
            offer.reactionDto && !offer.reactionDto.viewedStatus ? UserActions.reactionUpdate(dispatch, offer.advertisementId, offer.location!, {
                ...offer.reactionDto,
                openedStatus: true,
                viewedStatus: true
            } as ReactionType, offerType) : null;
            UserActions.loadProfileOfferDetailsAsync(dispatch, offerType, offer.advertisementId);
        }
    };

    const handleChangeReaction = (name: keyof ReactionType, value: boolean, offer: UserProfileOffersResponseContent) => {
        const userReaction = Object.assign({}, offer.reactionDto);

        if (name === 'likeStatus' && userReaction.unlikeStatus && value) {
            userReaction.unlikeStatus = false;
        } else if (name === 'unlikeStatus' && userReaction.likeStatus && value) {
            userReaction.likeStatus = false;
        }

        (userReaction[name] as boolean) = value;
        const city = offer.location!;
        UserActions.reactionUpdate(dispatch, offer.advertisementId, city, userReaction as ReactionType, getEnumPropertyByTypeUser(offer.module));
    };

    const generateInteractiveElements = (offer: UserProfileOffersResponseContent): InteractiveElements[] => {
        return [
            {
                tooltipText: 'Wyświetlono',
                icon:  IconEnum.EYE,
                active: offer.reactionDto?.viewedStatus,
                disabled: offer.reactionDto?.viewedStatus === undefined,
                onClick: () => handleChangeReaction('viewedStatus', !offer.reactionDto?.viewedStatus, offer)
            },
            {
                tooltipText: 'Lubię to',
                icon: IconEnum.LIKE,
                active: offer.reactionDto?.likeStatus,
                onClick: () => handleChangeReaction('likeStatus', !offer.reactionDto?.likeStatus, offer),
            },
            {
                tooltipText: 'Nie lubię tego',
                icon: IconEnum.DISLIKE,
                active: offer.reactionDto?.unlikeStatus,
                disabled: offer.reactionDto?.unlikeStatus === undefined,
                onClick: () => handleChangeReaction('unlikeStatus', !offer.reactionDto?.unlikeStatus, offer)
            },
            {
                tooltipText: 'Ogłoszenie nieaktualne',
                icon: IconEnum.BLOCKED,
                active: offer.reactionDto?.hideStatus,
                disabled: offer.reactionDto?.hideStatus === undefined,
                onClick: () => handleChangeReaction('hideStatus', !offer.reactionDto?.hideStatus, offer)
            },
            {
                tooltipText: 'Umówione spotkanie',
                icon: IconEnum.PEOPLES_3,
                active: offer.reactionDto?.arrangedStatus,
                disabled: offer.reactionDto?.arrangedStatus === undefined,
                onClick: () => handleChangeReaction('arrangedStatus', !offer.reactionDto?.arrangedStatus, offer)
            },
            {
                tooltipText: 'Przeprowadzona rozmowa',
                icon: IconEnum.PHONE,
                active: offer.reactionDto?.phoneStatus,
                disabled: offer.reactionDto?.phoneStatus === undefined,
                onClick: () => handleChangeReaction('phoneStatus', !offer.reactionDto?.phoneStatus, offer)
            },
            {
                tooltipText: 'Notatki',
                icon: IconEnum.MESSAGE,
                active: offer.numberOfNotes ? offer.numberOfNotes > 0 : false,
                counter: offer.numberOfNotes ? offer.numberOfNotes : undefined,
                onClick: onMessageClick,
            },
            ...(offer.phoneNumber ? [{
                icon: IconEnum.PHONE,
                isLink: true,
                active: false,
                isPhoneBtn: true,
                text: offer.advertisementId === activeAdvertPhoneNumberId ? <p><a href={`tel:${offer.phoneNumber}`}>{offer.phoneNumber}</a></p> : <p>Pokaż numer</p>,
                onClick: () => handleSetActiveNumber(offer.advertisementId)
            }] : []),
            ...(offer.photos && offer.photos.length > 0 ? [{
                icon: IconEnum.PHOTOS,
                isModalOpen: true,
                isLink: true,
                isPhotoBtn: true,
                text: <p>Zobacz zdjęcia</p>,
                visibleDuringModal: true,
                sliderElements: {
                    photos: createImagesForSlider(offer.photos, offer.module) as SliderImages[],
                    title: offer.title
                },
            }] : []),
            {
                icon: IconEnum.PEOPLE,
                isModalOpen: true,
                isLink: true,
                visibleDuringModal: true,
                isUserNameBtn: true,
                text: <p>{offer.username}</p>,
                active: false,
                userPhoto: offer.userPhoto ? `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${offer.userPhoto}&photoSize=MINI_PHOTO` : undefined
            }
        ];
    };

    useEffect(() => {
        const offersList = user.profileOfferList.content.map((offer): RenderList => {
            const interactiveElements = generateInteractiveElements(offer);
            const detailsTabContent: V2DetailsTabsProps = offer.detailedContent ? {
                title: offer.title,
                address: offer.location,
                detailsElements: userOfferListFunctions.generateDetailsTabElements(offer),
                description: offer.detailedContent.description ? offer.detailedContent.description : '',
                documents: offer.detailedContent.documents,
                handleDocumentClick: async (doc: string) => {
                    await userApiRequests.downloadFile(doc, getEnumPropertyByTypeUser(offer.module));
                }
            } : { detailsElements: [] };

            const thumbnailedUserPhoto = (userPhoto: string) => {
                return userPhoto !== '' ? `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${userPhoto}&photoSize=MINI_PHOTO` : undefined;
            };

            const generateUsersDetails = (offer: UserProfileOffersResponseContent) => {
                return {
                    profileId: offer.profileId,
                    userName: offer.username,
                    title: offer.title,
                    userPhoto: thumbnailedUserPhoto(offer.userPhoto!),
                    offerPhoto:  offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                    price: getMainValue(offer),
                    showMessageButton: !offer.messageDetail?.adOwner,
                    userMessages: offer.messageDetail?.messageDetails?.map<UserMessages>((message) => ({
                        content: message.content!,
                        messageId: message.messageId!,
                        messageSender: MessageSenders[message.messageSender],
                        readTime: message.readTime!,
                        sendDate: message.sendDate!
                    })) || [],
                    handleSendMessages: (message: string) => handleSendMessages(message, offer)
                };
            };

            const generateCardData = (offer: UserProfileOffersResponseContent): CardData => {
                return {
                    preTitle: offer.location,
                    date: offer.dateAdded,
                    title: offer.title,
                    infoElements: userOfferListFunctions.generateInfoElements(offer),
                    interactiveElements: generateInteractiveElements(offer),
                    note: offer.note as Note,
                    thumbnailPath:  offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : undefined,
                    ratingScore: null,
                    images: offer.photos && offer.photos.length > 0 ? createImagesForSlider(offer.photos, offer.module) : undefined,
                    showUserOptions: false,
                    userDetails: generateUsersDetails(offer),
                    mainValue: `${getMainValue(offer)} zł`,
                    subMainValues: getSubmainValues(offer),
                    showMessages: activeMessagesBoxId === offer.advertisementId,
                    setShowMessages: (active) => setActiveMessagesBoxId(active ? offer.advertisementId : ''),
                };
            };

            return {
                id: offer.advertisementId!,
                onSingleOfferClick: (_: React.MouseEvent, detailsOpen: boolean) => toggleContainerDetails(detailsOpen, offer),
                baseCardContent: {
                    bigImage: true,
                    cardData: generateCardData(offer),
                    openOfferTab: () => {
                    }, // openoffertab
                },
                detailsCardData: {
                    interactiveElements,
                    thumbnailPath:  offer.photos && offer.photos.length > 0 ? convertToThumbnailPath(offer.photos[0], offer.module) : './defaultImg.png',
                    date: offer.dateAdded,
                    onToggleContainerDetails: () => {
                    },
                    tabsContent: {
                        toggleContainerDetails: () => {
                        },
                        interactiveElements,
                        detailsTabContent,
                        notesTabContent: {
                            notes: user.notes,
                            handleDelete: (noteId: string) => handleDelete(offer.advertisementId.toString(), getEnumPropertyByTypeUser(offer.module), noteId),
                            handleSaveNote: (value: string, id?: string) => handleSaveNote(value, offer.advertisementId.toString(), getEnumPropertyByTypeUser(offer.module), id),
                        },
                        userDetails: generateUsersDetails(offer),
                        handleLoadNotes: () => handleNotes(offer),
                        numberOfNotes: offer.numberOfNotes || 0,
                        messagesTabContent: true
                    },
                    userDetails: generateUsersDetails(offer)
                }
            };
        });

        setRenderList(offersList);
    }, [user.profileOfferList?.content, isProfileActive, activeAdvertPhoneNumberId, activeMessagesBoxId, user.notes]);

    const convertProfilePhotoToThumbnailPath = useMemo(() => {
        if (user.profileOfferList.content.length > 0 && user.profileOfferList.content[0].userPhoto) {
            const photo = user.profileOfferList.content[0].userPhoto;

            return `${process.env.REACT_APP_API_URL_2}image/find?module=PROFILE&fileName=${photo}&photoSize=NORMAL_PHOTO`;
        }

        return '/defaultImg.png';
    }, [user.profileOfferList.content, user.notes]);

    return (
        <>
            <HeaderSection type={'profile'}/>
            <Wrapper id={'offer-list'}>
                <FiltersContentWrapper className={'active'}>
                    <SideContainer>
                        <InfoContainer>
                            <Title>Profil ogłoszeniodawcy </Title>
                            <ImageContainer>
                                <ProfileImg src={convertProfilePhotoToThumbnailPath}/>
                            </ImageContainer>
                            <Title>{user.profileOfferList?.content?.[0]?.username || ''}</Title>
                        </InfoContainer>
                        <DetailsInfoContainer>
                            {user.profileOfferList.content.length > 0 && user.profileOfferList.content[0].description ? ( <DetailsElement>
                                <p>{user.profileOfferList.content[0].description}</p>
                            </DetailsElement>) : null}
                        </DetailsInfoContainer>
                        <AccordionCard type={'favorites'} title={'Kontakt'}>
                            {user.profileOfferList.content.length > 0 && user.profileOfferList.content[0].phoneNumber && (
                                isVisiblePhoneNumber ? <StyledLink href={`tel:${user.profileOfferList.content[0].phoneNumber}`}>
                                    <ButtonContainer darkMode={user.isDarkMode}>
                                        <Icon icon={IconEnum.PHONE}/>
                                        <p>{user.profileOfferList.content[0].phoneNumber}</p>
                                    </ButtonContainer>
                                </StyledLink> : <ButtonContainer darkMode={user.isDarkMode} onClick={()=> setIsVisiblePhoneNumber(true)}>
                                    <Icon icon={IconEnum.PHONE}/>
                                    <p>Pokaż numer</p>
                                </ButtonContainer>
                            )}
                        </AccordionCard>
                    </SideContainer>
                    <V2OfferList
                        type={'profile'}
                        totalPages={user.profileOfferList?.totalPages}
                        pageNumber={user.profileOfferList?.pageable?.pageNumber || 1}
                        lastPage={false}
                        showList={!user.profileOfferList.empty && user.profileOfferList.content?.length > 0}
                        renderList={renderList}
                        handleChangePage={handleChangePage}
                    />
                </FiltersContentWrapper>
                {user.isLoading && <Indicator/>}
            </Wrapper>
        </>
    );
};

export default OwnerProfilePage;
