import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import { OffersActions } from 'store/Offers/Actions';
import { useOffers } from 'store/Offers/Context';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { getNormalizedPropertyType } from 'utils/formatters/getNormalizedPropertyType';
import { ModulesType } from 'utils/types/ModulesType';
import { OfferData } from 'utils/types/OfferData';
import { RentModuleType } from 'utils/types/Rent';

import Icon, { IconEnum } from 'components/common/Icon';
import { BoxContainer } from 'components/sales/SingleAdvertisement';

const Title = styled.h2`
    width: 100%;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
`;

const Actions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    gap: 10px;

    div {
        background: var(--color-alt-second);
    }
`;

const Input = styled.textarea`
    background: transparent;
    min-height: 60px;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--color-primary);
    resize: vertical;
`;

const InputIcon = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(color-white);
    
    &.icon-in-editor {
        background-color: ${(props) => props.darkMode ? 'var(--color-white)' : 'inherit'};
    }

    &:hover {
        background: var(--color-alt);
        cursor: pointer;

        span {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: var(--color-alt-second);
    border-radius: var(--box-border-radius);
`;

const NoteContent = styled.p`
    --font-weight: 400;
`;

type NoteTabProps = {
    data: OfferData;
    type: ModulesType
}

// TODO: create data type as component defined types, not types from api
const NoteTab: FC<NoteTabProps> = ({ data, type }) => {
    const { user, dispatch: dispatchUser } = useUser();
    const { offers, dispatch } = useOffers();
    const [value, setValue] = useState('');
    const [editedValue, setEditedValue] = useState('');
    const [isEditMode, setEditMode] = useState(false);
    const [editModeId, setEditModeId] = useState<number | string>(0);
    const offer = offers.list.find((item) => item.id === data.id);

    const handleSaveNote = async () => {
        if (isEditMode) {
            if (type === 'users' || type === 'my_offer' || type === 'profile') {
                // @ts-expect-error TODO: we need to adapt this component to new modules
                await UserActions.updateNote(dispatchUser, data.advertisementId, (data.adType || getNormalizedPropertyType(data.module)), editedValue, editModeId);
            } else {
                if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                    await OffersActions.updateNote(dispatch, data.advertisementId, data.city || offer!.reaction.city || data.note.city || '', type, editedValue, editModeId as number, data.module as RentModuleType);
                } else {
                    await OffersActions.updateNote(dispatch, data.id, data.city || offer!.reaction.city || data.note.city || '', type, editedValue, editModeId as number);
                }
            }

            setEditMode(false);
        } else {
            if (type === 'users' || type === 'my_offer' || type === 'profile') {
                // @ts-expect-error TODO: we need to adapt this component to new modules
                await UserActions.updateNote(dispatchUser, data.advertisementId, (data.adType || getNormalizedPropertyType(data.module)), value);
            } else {
                if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                    await OffersActions.updateNote(dispatch, data.advertisementId, data.city || offer!.reaction.city || data.note.city || '', type, value, editModeId as number, data.module as RentModuleType);
                } else {
                    await OffersActions.setNewNote(dispatch, data.id, data.city || offer!.reaction.city || data.note.city || '', type, value);
                }
            }
        }

        if (type === 'users' || type === 'my_offer' || type === 'profile') {
            // @ts-expect-error TODO: we need to adapt this component to new modules
            await UserActions.loadNotesAsync(dispatchUser, (data.adType || getNormalizedPropertyType(data.module)), data.advertisementId!);
        } else {
            if (type === 'rent') {
                await OffersActions.loadOldNotesAsync(dispatch, type, data.advertisementId, data.city || offer!.reaction.city || data.note.city || '', data.module as RentModuleType);
            } else if ( type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                await OffersActions.loadOldNotesAsync(dispatch, type, data.advertisementId, data.city || offer!.reaction.city || data.note.city || '', type.replaceAll("'",'') as RentModuleType);
            } else {
                await OffersActions.loadOldNotesAsync(dispatch, type, data.id, data.city || offer!.reaction.city || data.note.city || '');
            }
        }

        setValue('');
    };

    const handleEdit = (noteId: number | string) => {
        if (type === 'users' || type === 'my_offer' || type === 'profile') {
            user.notes?.find((item) => item.noteId === noteId && setEditedValue(item.content));
        } else {
            if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                offers.notes?.find((item) => item.noteId === noteId && setEditedValue(item.content));
            } else {
                offers.oldNotes.find((item) => item.noteId === noteId && setEditedValue(item.content!));
            }
        }

        setEditModeId(noteId);
        setEditMode(true);
    };

    const handleDelete = (noteId: number | string) => {
        if (type === 'users' || type === 'my_offer' || type === 'profile') {
            // @ts-expect-error TODO: we need to adapt this component to new modules
            UserActions.deleteNote(dispatchUser, data.advertisementId, data.adType, noteId);
        } else {
            if (type === 'rent' || type === 'RENT_FLAT' || type === 'RENT_PLOT' || type === 'RENT_HOUSE' || type === 'RENT_OTHER') {
                OffersActions.deleteNote(dispatch, data.id, type, noteId as number, data.module as RentModuleType);
            } else {
                OffersActions.deleteNote(dispatch, data.id, type, noteId as number);
            }
        }
    };

    return (
        <>
            {
                offers.oldNotes.map((item) => typeof item.content === 'string' && (
                    <BoxContainer key={item.noteId} data-notclickable={true}>
                        <Title>
                            {dayjs(item.date).format('DD.MM.YYYY, HH:mm')} • Notatka
                            <Actions>
                                <InputIcon onClick={() => handleEdit(item.noteId!)}>
                                    <Icon icon={IconEnum.EDIT}/>
                                </InputIcon>
                                <InputIcon onClick={() => handleDelete(item.noteId!)}>
                                    <Icon icon={IconEnum.DELETE}/>
                                </InputIcon>
                            </Actions>
                        </Title>
                        {isEditMode && editModeId === item.noteId ?
                            <InputContainer>
                                <Input
                                    rows={1}
                                    placeholder={'Wpisz treść notatki....'}
                                    value={editedValue}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEditedValue(e.target.value)}
                                />
                                <InputIcon onClick={handleSaveNote}>
                                    <Icon icon={IconEnum.SEND}/>
                                </InputIcon>
                            </InputContainer> :
                            <NoteContent>
                                {item.content}
                            </NoteContent>}
                    </BoxContainer>
                ))
            }
            {
                user.notes?.map((item) => (
                    <BoxContainer key={item.noteId} data-notclickable={true}>
                        <Title>
                            {dayjs(item.date).format('DD.MM.YYYY, HH:mm')} • Notatka
                            <Actions>
                                <InputIcon onClick={() => handleEdit(item.noteId!)}>
                                    <Icon icon={IconEnum.EDIT}/>
                                </InputIcon>
                                <InputIcon onClick={() => handleDelete(item.noteId!)}>
                                    <Icon icon={IconEnum.DELETE}/>
                                </InputIcon>
                            </Actions>
                        </Title>
                        {isEditMode && editModeId === item.noteId ?
                            <InputContainer>
                                <Input
                                    rows={1}
                                    placeholder={'Wpisz treść notatki....'}
                                    value={editedValue}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEditedValue(e.target.value)}
                                />
                                <InputIcon onClick={handleSaveNote}>
                                    <Icon icon={IconEnum.SEND}/>
                                </InputIcon>
                            </InputContainer> :
                            <NoteContent>
                                {item.content}
                            </NoteContent>}
                    </BoxContainer>
                ))
            }
            {
                offers.notes?.map((item) => (
                    <BoxContainer key={item.noteId} data-notclickable={true}>
                        <Title>
                            {dayjs(item.date).format('DD.MM.YYYY, HH:mm')} • Notatka
                            <Actions>
                                {item.originalNote ? <InputIcon onClick={() => handleEdit(item.noteId!)}>
                                    <Icon icon={IconEnum.EDIT}/>
                                </InputIcon> : null}
                                <InputIcon onClick={() => handleDelete(item.noteId!)}>
                                    <Icon icon={IconEnum.DELETE}/>
                                </InputIcon>
                            </Actions>
                        </Title>
                        {isEditMode && editModeId === item.noteId ?
                            <InputContainer>
                                <Input
                                    rows={1}
                                    placeholder={'Wpisz treść notatki....'}
                                    value={editedValue}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setEditedValue(e.target.value)}
                                />
                                <InputIcon onClick={handleSaveNote}>
                                    <Icon icon={IconEnum.SEND}/>
                                </InputIcon>
                            </InputContainer> :
                            <NoteContent>
                                {item.content}
                            </NoteContent>}
                    </BoxContainer>
                ))
            }
            <BoxContainer data-notclickable={true}>
                <Title>Dodaj nową notatkę</Title>
                <InputContainer>
                    <Input
                        rows={1}
                        placeholder={'Wpisz treść notatki....'}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setValue(e.target?.value)}
                    />
                    <InputIcon darkMode={user.isDarkMode} className={'icon-in-editor'} onClick={handleSaveNote}>
                        <Icon icon={IconEnum.SEND}/>
                    </InputIcon>
                </InputContainer>
            </BoxContainer>
        </>
    );
};

export default NoteTab;
