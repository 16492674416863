/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface LocationDto {
  /**
   * @format int32
   * @example 14
   */
  voivodeshipNumber?: number;
  /**
   * @format int32
   * @example 19
   */
  countyNumber?: number;
  /**
   * @format int32
   * @example 15
   */
  communityNumber?: number;
  /**
   * @format int32
   * @example 4
   */
  kindNumber?: number;
  /**
   * @format int32
   * @example 1
   */
  subRegionNumber?: number;
  /**
   * @format int32
   * @example 969379
   */
  regionNumber?: number;
  /**
   * @format int32
   * @example 12548
   */
  streetNumber?: number;
  /**
   * Pełna nazwa
   * @example "Rybaki"
   */
  fullName?: string;
}

export enum OfferFromEnum {
  OsobaPrywatna = "Osoba prywatna",
  Agencja = "Agencja",
}

export enum PlotBuildingTypeEnum {
  Budowlana = "Budowlana",
  Rolna = "Rolna",
  Lesna = "Leśna",
  Pozostale = "Pozostałe",
}

/**
 * Rodzaj raportu
 * @example "["Przedział czasu"]"
 */
export enum ReportType {
  PrzedzialPowierzchni = "Przedział powierzchni",
  PrzedzialCzasu = "Przedział czasu",
}

export enum SaleModule {
  SALE_FLAT = "SALE_FLAT",
  SALE_HOUSE = "SALE_HOUSE",
  SALE_PLOT = "SALE_PLOT",
  SALE_OTHER = "SALE_OTHER",
}

export interface SalePlotFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  buildingType?: PlotBuildingTypeEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

/**
 * Przedział czasowy
 * @example "Kwartał"
 */
export enum TimeRange {
  Rok = "Rok",
  Polrocze = "Półrocze",
  Kwartal = "Kwartał",
  Miesiac = "Miesiąc",
  Tydzien = "Tydzień",
}

export enum OtherBuildingTypeEnum {
  Garaze = "Garaże",
  Hale = "Hale",
  Pozostale = "Pozostałe",
}

export interface SaleOtherFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  /**
   * Piętro od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: OtherBuildingTypeEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export enum TypeOfMarketEnum {
  Pierwotny = "Pierwotny",
  Wtorny = "Wtórny",
}

export enum HouseBuildingTypeEnum {
  Wolnostojacy = "Wolnostojący",
  Blizniak = "Bliźniak",
  Szeregowiec = "Szeregowiec",
  Pozostale = "Pozostałe",
}

export interface SaleHouseFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  /**
   * Liczba pokoi w domu od
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w domu do
   * @format int32
   * @min 0
   * @example 5
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: HouseBuildingTypeEnum[];
  /**
   * Liczba pieter od
   * Pojedynczy wybór
   * @format int32
   * @example 0
   */
  floorFrom?: null;
  /**
   * Liczba pieter do
   * Pojedynczy wybór
   * @format int32
   * @example 2
   */
  floorTo?: null;
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export enum FlatBuildingTypeEnum {
  Blok = "Blok",
  Apartamentowiec = "Apartamentowiec",
  Kamienica = "Kamienica",
  Pozostale = "Pozostałe",
}

export interface SaleFlatFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  /**
   * Liczba pokoi w mieszkaniu od
   * @format int32
   * @min 0
   * @example 1
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w mieszkaniu do
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: FlatBuildingTypeEnum[];
  /**
   * Piętro na którym jest mieszkanie od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro na którym jest mieszkanie do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  /**
   * Piętra w budynku od
   * @format int32
   * @example 4
   */
  floorsFrom?: number;
  /**
   * Piętra w budynku do
   * @format int32
   * @example 4
   */
  floorsTo?: number;
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export enum RentModule {
  RENT_FLAT = "RENT_FLAT",
  RENT_HOUSE = "RENT_HOUSE",
  RENT_PLOT = "RENT_PLOT",
  RENT_OTHER = "RENT_OTHER",
}

export interface RentPlotFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  buildingType?: PlotBuildingTypeEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export interface RentOtherFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  /**
   * Piętro od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: OtherBuildingTypeEnum[];
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export interface RentHouseFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  /**
   * Liczba pokoi w domu od
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w domu do
   * @format int32
   * @min 0
   * @example 5
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: HouseBuildingTypeEnum[];
  /**
   * Liczba pieter od
   * Pojedynczy wybór
   * @format int32
   * @example 0
   */
  floorFrom?: null;
  /**
   * Liczba pieter do
   * Pojedynczy wybór
   * @format int32
   * @example 2
   */
  floorTo?: null;
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export interface RentFlatFilterDetailDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  /**
   * Liczba pokoi w mieszkaniu od
   * @format int32
   * @min 0
   * @example 1
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w mieszkaniu do
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: FlatBuildingTypeEnum[];
  /**
   * Piętro na którym jest mieszkanie od
   * @format int32
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro na którym jest mieszkanie do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  /**
   * Piętra w budynku od
   * @format int32
   * @example 1
   */
  floorsFrom?: number;
  /**
   * Piętra w budynku do
   * @format int32
   * @example 5
   */
  floorsTo?: number;
  /**
   * Id filtra
   * @format int64
   */
  id: number;
}

export interface SalePlotFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  buildingType?: PlotBuildingTypeEnum[];
}

export interface AdvertisementAnalysis {
  trendResult?: string[];
  analysisAreaRows?: AnalysisAreaRow[];
  analysisTimeRows?: AnalysisTimeRow[];
}

export interface AnalysisAreaRow {
  /**
   * Powierzchnia tytuł
   * @example "Powierzchnia"
   */
  areaLabel: string;
  /**
   * Liczba transakcji
   * @format int32
   * @example 100
   */
  transactionAmount: number;
  /**
   * Średnia cena za m2
   * @format int32
   * @example 1000
   */
  avgPricePerSquareMeter: number;
  /**
   * Minimalna cena za m2
   * @format int32
   * @example 100
   */
  minPricePerSquareMeter: number;
  /**
   * Maksymalna cena za m2
   * @format int32
   * @example 10000
   */
  maxPricePerSquareMeter: number;
  /**
   * Mediana cena za m2
   * @format int32
   * @example 1000
   */
  medianPricePerSquareMeter: number;
  /**
   * Odchylenie standardowe cena za m2
   * @format int32
   * @example 1000
   */
  sdPricePerSquareMeter: number;
  /**
   * Średnia cena
   * @format int32
   * @example 1000
   */
  avgPrice: number;
  /**
   * Minimalna cena
   * @format int32
   * @example 100
   */
  minPrice: number;
  /**
   * Maksymalna cena
   * @format int32
   * @example 10000
   */
  maxPrice: number;
  /**
   * Mediana cena
   * @format int32
   * @example 1000
   */
  medianPrice: number;
  /**
   * Odchylenie standardowe cena
   * @format int32
   * @example 1000
   */
  sdPrice: number;
}

export interface AnalysisTimeRow {
  /**
   * Okres czasowy tytuł
   * @example "Tytuł czasowy"
   */
  timeRangeLabel: string;
  /**
   * Liczba transakcji
   * @format int32
   * @example 100
   */
  transactionAmount: number;
  /**
   * ��rednia powierzchnia
   * @format int32
   * @example 100
   */
  avgArea: number;
  /**
   * Minimalna powierzchnia
   * @format int32
   * @example 10
   */
  minArea: number;
  /**
   * Maksymalna powierzchnia
   * @format int32
   * @example 1000
   */
  maxArea: number;
  /**
   * Odchylenie standardowe powierzchnia
   * @format int32
   * @example 100
   */
  sdArea: number;
  /**
   * Średnia cena za m2
   * @format int32
   * @example 1000
   */
  avgPricePerSquareMeter: number;
  /**
   * Minimalna cena za m2
   * @format int32
   * @example 100
   */
  minPricePerSquareMeter: number;
  /**
   * Maksymalna cena za m2
   * @format int32
   * @example 10000
   */
  maxPricePerSquareMeter: number;
  /**
   * Mediana cena za m2
   * @format int32
   * @example 1000
   */
  medianPricePerSquareMeter: number;
  /**
   * Odchylenie standardowe cena za m2
   * @format int32
   * @example 1000
   */
  sdPricePerSquareMeter: number;
  /**
   * Średnia cena
   * @format int32
   * @example 1000
   */
  avgPrice: number;
  /**
   * Minimalna cena
   * @format int32
   * @example 100
   */
  minPrice: number;
  /**
   * Maksymalna cena
   * @format int32
   * @example 10000
   */
  maxPrice: number;
  /**
   * Mediana cena
   * @format int32
   * @example 1000
   */
  medianPrice: number;
  /**
   * Odchylenie standardowe cena
   * @format int32
   * @example 1000
   */
  sdPrice: number;
  /** Lista wzrostu */
  growthList: PairStringBoolean[];
}

/** Lista wzrostu */
export interface PairStringBoolean {
  first?: string;
  second?: boolean;
}

export interface SaleOtherFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  /**
   * Piętro od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: OtherBuildingTypeEnum[];
}

export interface SaleHouseFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  /**
   * Liczba pokoi w domu od
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w domu do
   * @format int32
   * @min 0
   * @example 5
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: HouseBuildingTypeEnum[];
  /**
   * Liczba pieter od
   * Pojedynczy wybór
   * @format int32
   * @example 0
   */
  floorFrom?: null;
  /**
   * Liczba pieter do
   * Pojedynczy wybór
   * @format int32
   * @example 2
   */
  floorTo?: null;
}

export interface SaleFlatFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  module: SaleModule;
  /**
   * Liczba pokoi w mieszkaniu od
   * @format int32
   * @min 0
   * @example 1
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w mieszkaniu do
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: FlatBuildingTypeEnum[];
  /**
   * Piętro na którym jest mieszkanie od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro na którym jest mieszkanie do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  /**
   * Piętra w budynku od
   * @format int32
   * @example 4
   */
  floorsFrom?: number;
  /**
   * Piętra w budynku do
   * @format int32
   * @example 4
   */
  floorsTo?: number;
}

export interface FilterIdDto {
  /**
   * Id filtra
   * @format int64
   * @example 1
   */
  id: number;
}

export interface RentPlotFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  buildingType?: PlotBuildingTypeEnum[];
}

export interface RentOtherFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  /**
   * Piętro od
   * @format int32
   * @example 4
   */
  floorFrom?: number;
  /**
   * Piętro do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: OtherBuildingTypeEnum[];
}

export interface RentHouseFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  /**
   * Liczba pokoi w domu od
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w domu do
   * @format int32
   * @min 0
   * @example 5
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: HouseBuildingTypeEnum[];
  /**
   * Liczba pieter od
   * Pojedynczy wybór
   * @format int32
   * @example 0
   */
  floorFrom?: null;
  /**
   * Liczba pieter do
   * Pojedynczy wybór
   * @format int32
   * @example 2
   */
  floorTo?: null;
}

export interface RentFlatFilterDto {
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name?: string;
  city: LocationDto[];
  /**
   * Powierzchnia nieruchomości od
   * @format double
   * @min 0
   * @example 100
   */
  areaFrom?: number;
  /**
   * Powierzchnia nieruchomości do
   * @format double
   * @min 0
   * @example 200
   */
  areaTo?: number;
  /**
   * Cena za metr kwadratowy od
   * @format double
   * @min 0
   * @example 100
   */
  pricePerSquareMeterFrom?: number;
  /**
   * Cena za metr kwadratowy do
   * @format double
   * @min 0
   * @example 200
   */
  pricePerSquareMeterTo?: number;
  /**
   * Data dodania od
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedFrom?: string;
  /**
   * Data dodania do
   * Czas w formacie LocalDate
   * @format date-time
   */
  offerAddedTo?: string;
  /**
   * Ogłoszenia z liczby dni
   * @format int32
   * @example 7
   */
  daysAmount?: number;
  /**
   * Wartości zerowe
   * @example true
   */
  includeZeroArea: boolean;
  /**
   * Grupowanie duplikatów
   * @example true
   */
  groupDuplicate: boolean;
  /**
   * Pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  wantedKeywords?: string[];
  /**
   * Nie pożądane słowa kluczowe
   * Słowa kluczowa brane przy przeszukiwaniu opisu i tytułu
   * @example ["Mieszkanie"]
   */
  unwantedKeywords?: string[];
  /**
   * Pożądane numery telefonów
   * @example ["123467890"]
   */
  wantedPhoneNumbers?: string[];
  /**
   * Nie pożądane numery telefonów
   * @example ["123467890"]
   */
  unwantedPhoneNumbers?: string[];
  offerFrom?: OfferFromEnum[];
  timeRange: TimeRange;
  /**
   * Rodzaj raportu
   * @example ["Przedział czasu"]
   */
  reportType: ReportType[];
  /**
   * Granice przedziałów [m2]
   * @example "20,50,100,#"
   */
  boundaries: string;
  /**
   * Cena nieruchomości od, Cena wynajmu nieruchomości od
   * @format double
   * @min 0
   * @example 1000000
   */
  priceFrom?: number;
  /**
   * Cena nieruchomości do, Cena wynajmu nieruchomości do
   * @format double
   * @min 0
   * @example 2000000
   */
  priceTo?: number;
  /**
   * Cena kaucji nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  rentPriceFrom?: number;
  /**
   * Cena kaucji nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  rentPriceTo?: number;
  /**
   * Cena czynszu nieruchomości od
   * @format double
   * @min 0
   * @example 3000
   */
  depositPriceFrom?: number;
  /**
   * Cena czynszu nieruchomości do
   * @format double
   * @min 0
   * @example 5000
   */
  depositPriceTo?: number;
  module: RentModule;
  /**
   * Liczba pokoi w mieszkaniu od
   * @format int32
   * @min 0
   * @example 1
   */
  numberOfRoomsFrom?: number;
  /**
   * Liczba pokoi w mieszkaniu do
   * @format int32
   * @min 0
   * @example 3
   */
  numberOfRoomsTo?: number;
  /**
   * Rok budowy nieruchomości od
   * @format int32
   * @min 1800
   * @example 2012
   */
  builtYearFrom?: number;
  /**
   * Rok budowy nieruchomości do
   * @format int32
   * @max 2030
   * @example 2018
   */
  builtYearTo?: number;
  typeOfMarket?: TypeOfMarketEnum[];
  buildingType?: FlatBuildingTypeEnum[];
  /**
   * Piętro na którym jest mieszkanie od
   * @format int32
   * @example 2
   */
  floorFrom?: number;
  /**
   * Piętro na którym jest mieszkanie do
   * @format int32
   * @example 4
   */
  floorTo?: number;
  /**
   * Piętra w budynku od
   * @format int32
   * @example 1
   */
  floorsFrom?: number;
  /**
   * Piętra w budynku do
   * @format int32
   * @example 5
   */
  floorsTo?: number;
}

export interface SavedSaleFiltersDto {
  /**
   * Id filtra
   * @format int64
   * @example 1
   */
  id: number;
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name: string;
  module: SaleModule;
}

export interface SavedRentFiltersDto {
  /**
   * Id filtra
   * @format int64
   * @example 1
   */
  id: number;
  /**
   * Nazwa filtra
   * @example "Nazwa testowa"
   */
  name: string;
  module: RentModule;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "http://192.168.31.12:8080";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title OpenAPI definition
 * @version v0
 * @baseUrl http://192.168.31.12:8080
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  analysis = {
    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name PlotsFilterEdit
     * @summary Edycja filtra działki
     * @request PUT:/analysis/sale/filter/edit/plot
     * @secure
     */
    plotsFilterEdit: (data: SalePlotFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/sale/filter/edit/plot`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name OtherFilterEdit
     * @summary Edycja filtra pozostałych typów ogłoszeń
     * @request PUT:/analysis/sale/filter/edit/other
     * @secure
     */
    otherFilterEdit: (data: SaleOtherFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/sale/filter/edit/other`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name HousesFilterEdit
     * @summary Edycja filtra domu
     * @request PUT:/analysis/sale/filter/edit/house
     * @secure
     */
    housesFilterEdit: (data: SaleHouseFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/sale/filter/edit/house`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name FlatsFilterEdit
     * @summary Edycja filtra mieszkania
     * @request PUT:/analysis/sale/filter/edit/flat
     * @secure
     */
    flatsFilterEdit: (data: SaleFlatFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/sale/filter/edit/flat`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name PlotsFilterUpdate
     * @summary Edycja filtra działki
     * @request PUT:/analysis/rent/filter/edit/plot
     * @secure
     */
    plotsFilterUpdate: (data: RentPlotFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/rent/filter/edit/plot`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name OtherFilterUpdate
     * @summary Edycja filtra pozostałych typów ogłoszeń
     * @request PUT:/analysis/rent/filter/edit/other
     * @secure
     */
    otherFilterUpdate: (data: RentOtherFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/rent/filter/edit/other`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name HousesFilterUpdate
     * @summary Edycja filtra domu
     * @request PUT:/analysis/rent/filter/edit/house
     * @secure
     */
    housesFilterUpdate: (data: RentHouseFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/rent/filter/edit/house`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name FlatsFilterUpdate
     * @summary Edycja filtra mieszkania
     * @request PUT:/analysis/rent/filter/edit/flat
     * @secure
     */
    flatsFilterUpdate: (data: RentFlatFilterDetailDto, params: RequestParams = {}) =>
      this.http.request<string, string | object>({
        path: `/analysis/rent/filter/edit/flat`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza sprzedaży
     * @name CreatePlotAnalysis
     * @summary Tworzenie analizy rynku dla sprzedaży działek
     * @request POST:/analysis/sale/plot
     * @secure
     */
    createPlotAnalysis: (data: SalePlotFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/sale/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza sprzedaży
     * @name CreateOtherAnalysis
     * @summary Tworzenie analizy rynku dla sprzedaży pozostałych typów
     * @request POST:/analysis/sale/other
     * @secure
     */
    createOtherAnalysis: (data: SaleOtherFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/sale/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza sprzedaży
     * @name CreateHouseAnalysis
     * @summary Tworzenie analizy rynku dla sprzedaży domów
     * @request POST:/analysis/sale/house
     * @secure
     */
    createHouseAnalysis: (data: SaleHouseFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/sale/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza sprzedaży
     * @name CreateFlatAnalysis
     * @summary Tworzenie analizy rynku dla sprzedaży mieszkań
     * @request POST:/analysis/sale/flat
     * @secure
     */
    createFlatAnalysis: (data: SaleFlatFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/sale/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name CreateNewFilter
     * @summary Dodanie filtra działki
     * @request POST:/analysis/sale/filter/add/plot
     * @secure
     */
    createNewFilter: (data: SalePlotFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/sale/filter/add/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name CreateNewFilter1
     * @summary Dodanie filtra pozostałych typów ogłoszenia
     * @request POST:/analysis/sale/filter/add/other
     * @secure
     */
    createNewFilter1: (data: SaleOtherFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/sale/filter/add/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name CreateNewFilter2
     * @summary Dodanie filtra domu
     * @request POST:/analysis/sale/filter/add/house
     * @secure
     */
    createNewFilter2: (data: SaleHouseFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/sale/filter/add/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name CreateNewFilter3
     * @summary Dodanie filtra mieszkania
     * @request POST:/analysis/sale/filter/add/flat
     * @secure
     */
    createNewFilter3: (data: SaleFlatFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/sale/filter/add/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza wynajmu
     * @name CreatePlotAnalysis1
     * @summary Tworzenie analizy rynku dla wynajmu działek
     * @request POST:/analysis/rent/plot
     * @secure
     */
    createPlotAnalysis1: (data: RentPlotFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/rent/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza wynajmu
     * @name CreateOtherAnalysis1
     * @summary Tworzenie analizy rynku dla wynajmu pozostałych typów
     * @request POST:/analysis/rent/other
     * @secure
     */
    createOtherAnalysis1: (data: RentOtherFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/rent/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza wynajmu
     * @name CreateHouseAnalysis1
     * @summary Tworzenie analizy rynku dla wynajmu domów
     * @request POST:/analysis/rent/house
     * @secure
     */
    createHouseAnalysis1: (data: RentHouseFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/rent/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Analiza wynajmu
     * @name CreateFlatAnalysis1
     * @summary Tworzenie analizy rynku dla wynajmu mieszkań
     * @request POST:/analysis/rent/flat
     * @secure
     */
    createFlatAnalysis1: (data: RentFlatFilterDto, params: RequestParams = {}) =>
      this.http.request<AdvertisementAnalysis[], string | object>({
        path: `/analysis/rent/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name CreateNewFilter4
     * @summary Dodanie filtra działki
     * @request POST:/analysis/rent/filter/add/plot
     * @secure
     */
    createNewFilter4: (data: RentPlotFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/rent/filter/add/plot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name CreateNewFilter5
     * @summary Dodanie filtra pozostałych typów ogłoszenia
     * @request POST:/analysis/rent/filter/add/other
     * @secure
     */
    createNewFilter5: (data: RentOtherFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/rent/filter/add/other`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name CreateNewFilter6
     * @summary Dodanie filtra domu
     * @request POST:/analysis/rent/filter/add/house
     * @secure
     */
    createNewFilter6: (data: RentHouseFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/rent/filter/add/house`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name CreateNewFilter7
     * @summary Dodanie filtra mieszkania
     * @request POST:/analysis/rent/filter/add/flat
     * @secure
     */
    createNewFilter7: (data: RentFlatFilterDto, params: RequestParams = {}) =>
      this.http.request<FilterIdDto, string | object>({
        path: `/analysis/rent/filter/add/flat`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name FindUserFilters
     * @summary Lista wszystkich filtrów użytkownika
     * @request GET:/analysis/sale/filter/list
     * @secure
     */
    findUserFilters: (
      query?: {
        module?: SaleModule;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SavedSaleFiltersDto[], string | object>({
        path: `/analysis/sale/filter/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name PlotsFilterDetail
     * @summary Szczegóły filtra działki
     * @request GET:/analysis/sale/filter/detail/plot
     * @secure
     */
    plotsFilterDetail: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SalePlotFilterDetailDto, string | object>({
        path: `/analysis/sale/filter/detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name OtherFilterDetail
     * @summary Szczegóły filtra pozostałych
     * @request GET:/analysis/sale/filter/detail/other
     * @secure
     */
    otherFilterDetail: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleOtherFilterDetailDto, string | object>({
        path: `/analysis/sale/filter/detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name HouseFilterDetail
     * @summary Szczegóły filtra domu
     * @request GET:/analysis/sale/filter/detail/house
     * @secure
     */
    houseFilterDetail: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleHouseFilterDetailDto, string | object>({
        path: `/analysis/sale/filter/detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name FlatsFilterDetail
     * @summary Szczegóły filtra mieszkań
     * @request GET:/analysis/sale/filter/detail/flat
     * @secure
     */
    flatsFilterDetail: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SaleFlatFilterDetailDto, string | object>({
        path: `/analysis/sale/filter/detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name FindUserFilters1
     * @summary Lista wszystkich filtrów użytkownika
     * @request GET:/analysis/rent/filter/list
     * @secure
     */
    findUserFilters1: (
      query?: {
        module?: RentModule;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<SavedRentFiltersDto[], string | object>({
        path: `/analysis/rent/filter/list`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name PlotsFilterDetail1
     * @summary Szczegóły filtra działki
     * @request GET:/analysis/rent/filter/detail/plot
     * @secure
     */
    plotsFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentPlotFilterDetailDto, string | object>({
        path: `/analysis/rent/filter/detail/plot`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name OtherFilterDetail1
     * @summary Szczegóły filtra pozostałych
     * @request GET:/analysis/rent/filter/detail/other
     * @secure
     */
    otherFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentOtherFilterDetailDto, string | object>({
        path: `/analysis/rent/filter/detail/other`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name HouseFilterDetail1
     * @summary Szczegóły filtra domu
     * @request GET:/analysis/rent/filter/detail/house
     * @secure
     */
    houseFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentHouseFilterDetailDto, string | object>({
        path: `/analysis/rent/filter/detail/house`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name FlatsFilterDetail1
     * @summary Szczegóły filtra mieszkań
     * @request GET:/analysis/rent/filter/detail/flat
     * @secure
     */
    flatsFilterDetail1: (
      query: {
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<RentFlatFilterDetailDto, string | object>({
        path: `/analysis/rent/filter/detail/flat`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ID - filter id
     *
     * @tags Zarządzanie filtrami od sprzedaży
     * @name DeleteFilter
     * @summary Usuwanie filtra
     * @request DELETE:/analysis/sale/filter/delete
     * @secure
     */
    deleteFilter: (
      query: {
        module: SaleModule;
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<string, string | object>({
        path: `/analysis/sale/filter/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description ID - filter id
     *
     * @tags Zarządzanie filtrami od wynajmu
     * @name DeleteFilter1
     * @summary Usuwanie filtra
     * @request DELETE:/analysis/rent/filter/delete
     * @secure
     */
    deleteFilter1: (
      query: {
        module: RentModule;
        /** @format int64 */
        id: number;
      },
      params: RequestParams = {},
    ) =>
      this.http.request<string, string | object>({
        path: `/analysis/rent/filter/delete`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
