// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapListElements = (list: any[]) => {
    return list.map((el) => {
        return {
            advertisementId: el.advertisementId || '',
            area: el.area || '',
            buildingType: el.buildingType || '',
            builtYear: el.builtYear || '',
            city: el.city || '',
            dateAdded: el.dateAdded || '',
            description: el.description || '',
            floor: el.floor || '',
            keywords: el.keywords || '',
            link: el.link || '',
            numberOfRooms: el.numberOfRooms || '',
            offerFrom: el.offerFrom || '',
            ownerType: el.ownerType || '',
            phone: el.phone || '',
            price: el.originalPrice || '',
            portal: el.portal.name || '',
            pricePerSquareMeter: el.pricePerSquareMeter || '',
            thumbnailPath: el.thumbnailPath || '',
            title: el.title || '',
            type: el.type || '',
            typeOfMarket: el.typeOfMarket || '',
            webId: el.webId || '',
        };
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const convertOffersListToCSV = (list: any[]) => {
    const JSONData = mapListElements(list);
    const ShowLabel = true;
    const ReportTitle = 'export';

    //current separator
    const separator = ';';
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    const arrData =
        typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData;

    let CSV = '\ufeff';

    //This condition will generate the Label/Header
    if (ShowLabel) {
        let row = '';

        //This loop will extract the label from 1st index of on array
        for (const index in arrData[0]) {
            //Now convert each value to string and seprated
            row += index + `${separator}`;
        }

        row = row.slice(0, -1);

        //append Label row with line break
        CSV += row + '\r\n';
    }

    //1st loop is to extract each row
    for (let i = 0; i < arrData.length; i++) {
        let rowIn = '';

        //2nd loop will extract each column and convert it in string seprated
        for (const indexIn in arrData[i]) {
            rowIn +=
                '"' +
                (typeof arrData[i][indexIn] === 'string'
                    ? arrData[i][indexIn]?.replace(/;/g, ',')
                    : arrData[i][indexIn]) +
                `"${separator}`;
        }

        rowIn.slice(0, rowIn.length - 1);

        //add a line break after each row
        CSV += rowIn + '\r\n';
    }

    if (CSV === '') {
        alert('Invalid data');

        return;
    }

    const uri = 'data:text/csv;charset=windows-1250,' + encodeURIComponent(CSV);
    const link = document.createElement('a');
    link.href = uri;
    link.style.visibility = 'hidden';
    link.download = ReportTitle + '.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default convertOffersListToCSV;
