import React, { FC, useEffect, useRef, useState } from 'react';
import { Timeout } from 'react-number-format/types/types';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { UserActions } from 'store/User/Actions';
import { useUser } from 'store/User/Context';
import styled from 'styled-components';
import { isTouchDevice } from 'utils/checkIsTouchDevice';
import { Packages } from 'utils/enums/packages';
import { useWindowWidth } from 'utils/useWindowWidth';

import Icon, { IconEnum } from './common/Icon';
import NavElement from './common/NavElement';

type SidebarProperties = {
    darkMode: boolean,
    sidebarHeight: number | null,
}

const Sidebar = styled.nav<SidebarProperties>`
      background-color: ${(props) => props.darkMode ? 'var(--color-white)' : 'var(--color-alt)'};
      border-bottom-right-radius: var(--box-border-radius);
      border-top-right-radius: var(--box-border-radius);
      height: 100%;
      width: 80px;
      position: fixed;
      margin-left: 15px;
      display: none;

    @media (min-width: 1100px) {
        @media not all and (pointer: coarse) {
            border-radius: var(--box-border-radius);
            margin-top: 15px;
            height: 750px;
            display: block;
        }
    }

    &.mobile-sidebar {
        background-color: unset;
        width: 100%;
        overflow-y: scroll;

        .menu-categories {
            position: relative;

            &:before {
                content: '';
                position: absolute;
                height: ${(props) => props.sidebarHeight ? `${props.sidebarHeight}px` : 'inherit'};
                width: 80px;
                background-color: ${(props) => props.darkMode ? 'var(--color-white)' : 'var(--color-alt)'};
            }
        }
    }

    .sub-menu:has(.active) .nav-element{
        background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt-second)'} !important;
        border-radius: var(--border-radius);

        img {
            filter: ${(props) => props.darkMode ? 'sepia(100%) saturate(10%) brightness(10%) contrast(90%)' : 'invert(84%) sepia(58%) saturate(3356%) hue-rotate(198deg) brightness(91%) contrast(98%)'};
        }
    }
    
    .menu {
        &:first-child {
            margin-top: 30px;
        }
        
        .active {
            position: relative; 
            background-color: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt-second)'};
          
            .nav-element {
                img {
                    filter: ${(props) => props.darkMode ? 'sepia(100%) saturate(10%) brightness(10%) contrast(90%)' : 'invert(84%) sepia(58%) saturate(3356%) hue-rotate(198deg) brightness(91%) contrast(98%)'};
                }
            }

            .nav-title {
                color: var(--color-alt-second);
                background: var(--color-alt);
            }
        }

        &:last-child {
            margin-bottom: 30px;
        }
    }

    .menu-border {
        width: 20px;
        border-bottom: 1px solid ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt-second)'};
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 30px;
        opacity: .3;
    }

    .more-button,
    a {
        display: block;
        margin-right: 20px;
        margin-left: 20px;
        border-radius: 20px;
        transition: ease-in .2s;
        width: fit-content;
    }

    .more-button:hover,
    a:focus,
    a:hover {
        position: relative;
        background-color: var(--color-alt-hover);
        cursor: pointer;
        text-decoration: none;

        .nav-element .nav-title {
            color: var(--color-alt-second);
            background: ${(props) => props.darkMode ? 'var(--color-primary)' : 'var(--color-alt)'};
        }
    }

    a:visited {
        text-decoration-color: var(--color-alt-hover)
    }

    .nav-element {
        display: flex;
        padding: 10px;
        position: relative;

        span {
            --font-weight: 500;
            --font-size-body: var(--font-size-body-2);

            color: var(--color-alt-second);
        }

        .nav-title {
            line-height: 20px;
            display: none;
        }
    }


    .sub-menu{
        & > div {
            display: block;
            margin-right: 20px;
            margin-left: 20px;
            border-radius: 20px;
            transition-duration: 0.2s;
            transition-timing-function: ease-in;
            transition-delay: 0s;
            transition-property: all;
            width: fit-content;
        }
        & > ul {
            flex-direction: column;
            display: none;
            position: absolute;
            gap: 10px;
            top: 50%;
            transform: translateY(-50%);
            white-space: nowrap;
            left: 50px;
            color: var(--color-primary);
            padding: 10px 20px;
            border-radius: 6px;
        }
    }

    &.opened-sidebar {
        z-index: 900;
        animation: fadeIn 1s;
        animation-fill-mode: forwards;
        display: block;
        
        .sub-menu{
            position: relative;
            display: flex;
            align-items: center;

            & > div {
                &:focus,
                &:hover {
                    position: relative;
                    background-color: var(--color-alt-hover);
                    cursor: pointer;
                    text-decoration: none;
                }
            }

            &:focus ul,
            &:hover ul {
                display: flex;
            }
        }

        & + .sidebar-overlay {
            content: '';
            position: fixed;
            top: 0;
            background-color: var(--color-alt-second);
            opacity: .7;
            width: 100%;
            height: 100%;
            z-index: 899;
        }

        .sub-menu .nav-element:hover{
            .nav-title{
                display: none;
            }
        }

        .nav-element {
            .nav-title {
                position: absolute;
                top: 0;
                display: block;
                white-space: nowrap;
                left: 75px;
                color: var(--color-primary);
                padding: 10px 20px;
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
                background: var(--color-white);
                border-radius: 6px;
            }
        }
    }
    
    .nav-title.hovered {
        display: none !important;
    }
`;

const NavTitle = styled.span`
    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    color: #FFF;
    border-radius: 40px;
    background: ${(props) => props.darkMode ? '#201f26' : '#0255AA'};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    transition: all ease-in-out .2s;
    
    &.active {
        transition: all ease-in-out .2s;
        background: #FFF;
        color: #0255AA;
    }

    &:hover {
        transition: all ease-in-out .2s;
        cursor: pointer;
        background: #FFF;
        color:${(props) => props.darkMode ? '#000' : '#0255AA'};
    }
`;

const Menu = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: min-content;
`;

const MobileNavigationButton = styled.div`
    @media (min-width: 1100px) {
        @media not all and (pointer: coarse) {
            display: none;
        }
    }

    display: block;
    position: absolute;
    right: 0;
    top: 15px;

    &:hover {
        cursor: pointer;
    }

    &.dark-icon {
        .icon img {
            filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(79deg) brightness(105%) contrast(102%);
        }
    }

    span {
        margin-left: auto;
        margin-right: 16px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--color-alt-second);
    }
`;

type NavigationProperties = {
    activePackages: string[]
}

const Navigation: FC<NavigationProperties> = ({ activePackages }) => {
    const width = useWindowWidth();
    const { user, dispatch } = useUser();
    const isDarkMode = user.isDarkMode;
    const [isBiggerSidebar, setBiggerSidebar] = useState(false);
    const [menuHeight, setMenuHeight] = useState(0);
    const sidebarRef = useRef<HTMLElement>(null);
    const menuRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        if (menuRef.current && isBiggerSidebar) {
            setMenuHeight(menuRef.current.clientHeight);
        }
    }, [isBiggerSidebar]);

    const closeNav = () => {
        if (isBiggerSidebar)
            setBiggerSidebar(false);
    };

    const handleNavigation = () => {
        if (!isBiggerSidebar)
            setBiggerSidebar(true);
    };

    const handleMouseEnter = () => {
        if (isTouchDevice()) {
            document.body.style.overflowY = 'hidden';
        }

        if (!isBiggerSidebar) {
            // @ts-expect-error only timeout here is needed
            sidebarRef.current = setTimeout(() => {
                setBiggerSidebar(true);
            }, 200);
        }
    };

    const handleMouseLeave = () => {
        if (isTouchDevice()) {
            document.body.style.overflowY = 'scroll';
        }

        clearTimeout(sidebarRef.current as unknown as Timeout);

        if (isBiggerSidebar) {
            setBiggerSidebar(false);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (isTouchDevice() && menuRef.current && !(menuRef.current).contains(event.target as Node)) {
                setBiggerSidebar(false);
            }
        };

        const classHovered = () => {
            const titles = document.querySelectorAll('.nav-title');

            titles.forEach((title) => {
                title.classList.add('hovered');
            });
        };

        const classRemoveHovered = () => {
            const titles = document.querySelectorAll('.nav-title');

            titles.forEach((title) => {
                title.classList.remove('hovered');
            });
        };

        document.querySelector('.sub-menu')?.addEventListener('mouseover', classHovered);
        document.querySelector('.sub-menu')?.addEventListener('mouseout', classRemoveHovered);
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.querySelector('.sub-menu')?.removeEventListener('mouseover', classHovered);
            document.querySelector('.sub-menu' )?.removeEventListener('mouseout', classRemoveHovered);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuRef]);

    return (
        <>
            <MobileNavigationButton className={clsx({ 'dark-icon': user.isDarkMode, })} onClick={handleNavigation}>
                <Icon icon={IconEnum.SQUARES}/>
            </MobileNavigationButton>
            <Sidebar ref={sidebarRef} onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className={clsx('sidebar', { 'opened-sidebar': isBiggerSidebar, 'desktop-sidebar': !isTouchDevice() && width > 1100, 'mobile-sidebar': isTouchDevice() })}
                sidebarHeight={menuHeight}
                darkMode={isDarkMode}>
                <Menu ref={menuRef} className="menu-categories">
                    <li className='menu'>
                        <NavLink
                            to='/favorites'
                            aria-expanded='false'
                            onClick={() => closeNav()}
                        >
                            <NavElement title={'Ulubione'} icon={IconEnum.HEART}/>
                        </NavLink>
                    </li>
                    <div className='menu-border'/>
                    <li className='sub-menu'>
                        <NavLink to='/users' onClick={() => closeNav()} aria-expanded='false'>
                            <NavElement title={'Ogłoszenia użytkowników'} icon={IconEnum.WALLET_WHITE} isNew />
                        </NavLink>
                        <ul>
                            <li className="menu">
                                <NavLink
                                    to="/users"
                                    aria-expanded="false"
                                    onClick={() => closeNav()}
                                >
                                    <NavTitle darkMode={isDarkMode}>Ogłoszenia użytkowników</NavTitle>
                                </NavLink>
                            </li>
                            <li className="menu">
                                <NavLink
                                    to="/my-offers"
                                    aria-expanded="false"
                                    onClick={() => closeNav()}
                                >
                                    <NavTitle darkMode={isDarkMode}>Moje ogłoszenia</NavTitle>
                                </NavLink>
                            </li>
                            <li className="menu">
                                <NavLink
                                    to="/messages"
                                    aria-expanded="false"
                                    onClick={() => closeNav()}
                                >
                                    <NavTitle darkMode={isDarkMode}>Wiadomości</NavTitle>
                                </NavLink>
                            </li>
                            <li className="menu">
                                <NavLink
                                    to="/add-offer"
                                    aria-expanded="false"
                                    onClick={() => closeNav()}
                                >
                                    <NavTitle darkMode={isDarkMode}>Dodaj ogłoszenie</NavTitle>
                                </NavLink>
                            </li>
                            <li className='menu'>
                                <NavLink
                                    to='/profile-settings'
                                    aria-expanded='false'
                                    onClick={() => closeNav()}
                                >
                                    <NavTitle darkMode={isDarkMode}>Ustawienia profilu</NavTitle>
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li className="menu">
                        <NavLink
                            to="/"
                            aria-expanded="false"
                            onClick={() => closeNav()}
                        >
                            <NavElement title={'Ogłoszenia sprzedaży'} icon={IconEnum.BAG_WHITE}/>
                        </NavLink>
                    </li>
                    {activePackages.includes(Packages.ROLE_RENT) && (
                        <li className='menu'>
                            <NavLink
                                to='/rent'
                                onClick={() => closeNav()}
                            >
                                <NavElement title={'Ogłoszenia wynajmu'} icon={IconEnum.CALENDAR_WHITE}/>
                            </NavLink>
                        </li>
                    )}
                    {activePackages.includes(Packages.ROLE_AUCTION) && (
                        <li className='menu'>
                            <NavLink
                                to='/tender'
                                aria-expanded='false'
                                onClick={() => closeNav()}
                            >
                                <NavElement title={'Przetargi'} icon={IconEnum.PEOPLES_2_WHITE}/>
                            </NavLink>
                        </li>
                    )}
                    {activePackages.includes(Packages.ROLE_AUCTION) && (
                        <li className='menu'>
                            <NavLink
                                to='/auctions'
                                aria-expanded='false'
                                onClick={() => closeNav()}
                            >
                                <NavElement title={'Licytacje komornicze'} icon={IconEnum.PEOPLES_3_WHITE}/>
                            </NavLink>
                        </li>
                    )}
                    {activePackages.includes(Packages.ROLE_BAILIFFNOTICE) && (
                        <li className='menu'>
                            <NavLink
                                to='/cooperatives'
                                aria-expanded='false'
                                onClick={() => closeNav()}
                            >
                                <NavElement title={'Przetargi lokalne'} icon={IconEnum.SQUARE_WHITE}/>
                            </NavLink>
                        </li>
                    )}

                    {activePackages.includes(Packages.ROLE_COOPERATIVE) && (
                        <li className='menu'>
                            <NavLink
                                to='/bailiff-notices'
                                aria-expanded='false'
                                onClick={() => closeNav()}
                            >
                                <NavElement title={'Informacje o zadłużeniach'} icon={IconEnum.LIST_WHITE}/>
                            </NavLink>
                        </li>
                    )}

                    {activePackages.includes(Packages.ROLE_ANALYSIS) && (
                        <li className='menu'>
                            <NavLink
                                to='/analysis'
                                aria-expanded='false'
                                onClick={() => closeNav()}
                            >
                                <NavElement title={'Analiza cen ofertowych'} icon={IconEnum.ANALYSIS_WHITE}/>
                            </NavLink>
                        </li>
                    )}

                    {/*{activePackages.includes(Packages.ROLE_ANALYSIS) && (*/}
                    {/*    <li className='menu'>*/}
                    {/*        <NavLink*/}
                    {/*            to='/analysis'*/}
                    {/*            aria-expanded='false'*/}
                    {/*            onClick={() => closeNav()}*/}
                    {/*        >*/}
                    {/*            <NavElement title={'Analiza cen ofertowych'} icon={IconEnum.ANALYSIS_WHITE}/>*/}
                    {/*        </NavLink>*/}
                    {/*    </li>*/}
                    {/*)}*/}
                    <div className='menu-border'/>
                    <li className='menu'>
                        <div role={'button'} className='more-button'
                            onClick={() => UserActions.changeTheme(dispatch, !isDarkMode)}>
                            <NavElement title={isDarkMode ? 'Jasny motyw' : 'Ciemny motyw'}
                                icon={isDarkMode ? IconEnum.SUN : IconEnum.MOON}/>
                        </div>
                    </li>
                </Menu>
            </Sidebar>
            <div onClick={() => setBiggerSidebar((prev) => !prev)} className="sidebar-overlay"/>
        </>
    );
};

export default Navigation;
